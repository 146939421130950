import { fetchHelper } from 'src/app/fetch-helper';
import { apiEndPoint } from './api-endpoint';

export enum AgriProductStatus {
  Undefined = -999,
  Active = 0,
  Inactive = 1,
  Deleted = 2
}

interface GetAgriProductsResp extends PageAble {
  items: AgriProductModel[];
}

class AgriProductProvider {
  private cacheAgriProducts?: AgriProductModel[];
  private timeout: NodeJS.Timeout | undefined;

  public async getById(id: number) {
    return await fetchHelper<AgriProductModel>(`${apiEndPoint.agriProduct}/${id}`, 'GET');
  }

  public async getAllAgriProducts(force?: boolean): Promise<AgriProductModel[]> {
    if (!force && this.cacheAgriProducts != null) {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      return this.cacheAgriProducts;
    }

    const { items: agriProducts } = await fetchHelper<GetAgriProductsResp>(apiEndPoint.agriProduct, 'GET', { size: 10_000 });

    this.cacheAgriProducts = agriProducts;
    this.timeout = setTimeout(() => {
      this.cacheAgriProducts = undefined;
    }, 60 * 1000);
    return agriProducts;
  }

  public async editAgriProducts(body: AgriProductModel): Promise<AgriProductModel> {
    let response: AgriProductModel | undefined;
    if (!body.id || body.id === 0) {
      response = await fetchHelper<AgriProductModel>(apiEndPoint.agriProduct, 'POST', body);
    } else {
      response = await fetchHelper<AgriProductModel>(`${apiEndPoint.agriProduct}/${body.id}`, 'PUT', body)
    }
    this.cacheAgriProducts = undefined;
    return response;
  }

  public async deleteAgriProducts(productId: number) {
    await fetchHelper(`${apiEndPoint.agriProduct}/${productId}`, 'DELETE');
    this.cacheAgriProducts = undefined;
  }

  public updateQuantity(productId: number, body: { storeQuantities: Array<{ storeId: number, quantity: number }> }): Promise<any> {
    return fetchHelper<AgriProductModel>(`${apiEndPoint.agriProduct}/${productId}/adjust-quantity`, 'PUT', body);
  }
}
export const agriProductProvider = new AgriProductProvider();