import * as React from 'react';
import { Redirect, Route, RouteComponentProps } from "react-router-dom";
import { userSession } from '../app';
import { route } from 'src/app/route';
import { Icon, Segment, Header } from 'semantic-ui-react';

const NoPermissionsAlert = () => (
    <Segment className='x-container' placeholder={true} style={{margin: 0, border: 0}}>
        <Header icon={true}>
            <Icon name='ban' color='brown' />
            Access Denied
        </Header>
        <Segment.Inline>
            <p>You don't have permissions to access this page.</p>
        </Segment.Inline>
    </Segment>
);

export const PrivateRoute = ({component, ...rest}: any) => {
    const routeComponent = (props: RouteComponentProps) => {
        if (!userSession.isAuthenticated) {
            return <Redirect to={{pathname: route.pathPatterns.login, state: {from: props.location}}} />;
        }

        const isAllowAccess = userSession.isAdmin || userSession.permissions && userSession.permissions.length !== 0;
        if (!isAllowAccess) {
            return <NoPermissionsAlert />;
        }
        return React.createElement(component, props);
    };
    return <Route {...rest} render={routeComponent} />;
};