import React, { Suspense, lazy } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Sidebar, Segment } from 'semantic-ui-react';
import { route } from 'src/app/route';
import MenuBar from './menu-bar';
import { HeaderBar } from 'src/components/header-bar';
const CustomerManager = lazy(() => import('src/components/customer/customer-manager'));

export class CustomerPage extends React.PureComponent<RouteComponentProps<{}>> {
  public componentDidMount() {
    document.title = route.getPageTitle(this.props.location.pathname);
  }

  public render() {
    const title = route.getPageTitle(this.props.location.pathname);
    return (
      <Sidebar.Pushable as={Segment} className='x-page'>
        <MenuBar />

        <Sidebar.Pusher>
          <Segment basic={true}>
            <HeaderBar title={title} />
            <Suspense fallback={<div className='x-loading' />}>
              <CustomerManager />
            </Suspense>
          </Segment>
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    );
  }
}
