import * as React from 'react';
import * as Chart from 'chart.js';
import ChartComponent from 'react-chartjs-2';
import { i18n, appConfig } from 'src/app';
import { IWfChartSetting, IWfChartData } from './common';
import { wfChartManager } from './wf-chart-manager';
import { defineMessages } from 'react-intl';

const messages = defineMessages({
  thresholdMarkLabel: {
    id: 'thresholdMarkLabel',
    defaultMessage: 'Threshold'
  }
});
const TOOLTIP_ELEMENT_ID = 'x-chartjs-tooltip';

let canvasRender: CanvasRenderingContext2D | null | undefined;
function getCanvasRendering() {
  if (canvasRender === undefined) {
    const canvas = document.createElement('canvas');
    canvasRender = canvas.getContext('2d');
    const fontSize = Chart.defaults.global.defaultFontSize || 0;
    if (canvasRender) {
      canvasRender.font = `${fontSize}px ${Chart.defaults.global.defaultFontFamily}`;
    }
  }

  return canvasRender;
}

function parseNumber(strValue: any): number | undefined {
  if (strValue == null || strValue === '') {
    return undefined;
  }

  const n = parseInt(strValue, 10);
  if (isNaN(n)) {
    return undefined;
  }

  return n;
}

function getLabelsMaxWidthHeight(labels: string[] | undefined) {
  const result = { maxWidth: 0, maxHeight: 0 };
  if (labels == null || labels.length === 0) {
    return result;
  }

  const ctx = getCanvasRendering();
  if (ctx == null) {
    return result;
  }

  result.maxWidth = Math.max(...labels.map(x => ctx.measureText(x).width));
  result.maxHeight = Chart.defaults.global.defaultFontSize || 0;
  return result;
}

const BarChartUtils = {
  drawThreshHoldMark: (chartInstance: IChart) => {
    const ctx = chartInstance.chart.ctx;
    if (ctx == null) {
      return;
    }

    const chartData = chartInstance.chart.data;
    if (chartData == null || chartData.datasets == null || !BarChartUtils.isAlert(chartData)) {
      return
    }

    chartData.datasets.forEach((dataset: IChartDatasetEx, datasetIndex: number) => {
      const meta = chartInstance.chart.getDatasetMeta(datasetIndex) as IMeta;
      meta.data.forEach((bar: IMetaData, dataIndex: number) => {
        if (dataset.data == null || dataset.alertValues == null) {
          return;
        }
        const alertValue = dataset.alertValues[dataIndex];
        if (alertValue <= 0) {
          return;
        }

        const pixelFromAlertValue = (bar._xScale as IChartScales).getPixelForValue(alertValue);
        // const positionBarLeft = (bar._xScale as IChartScales).left;
        // const maxBarValue = (bar._xScale as IChartScales).max;
        // const maxBarWidth = (bar._xScale as IChartScales).maxWidth;
        // const ratioBarWidth = alertValue / maxBarValue;
        // const x = (maxBarWidth * ratioBarWidth) + positionBarLeft;
        const x = pixelFromAlertValue;
        const y = bar._model.y;
        ctx.beginPath();

        let dynamicHeight = bar._model.height;
        if (bar._model.height * 2 >= 12) {
          dynamicHeight = 12;
        } else {
          dynamicHeight = bar._model.height * 2;
        }

        ctx.moveTo(x - (dynamicHeight / 2), y - dynamicHeight);
        ctx.lineTo(x + (dynamicHeight / 2), y - dynamicHeight);
        ctx.lineTo(x, y + dynamicHeight / 2);
        ctx.lineTo(x - (dynamicHeight / 2), y - dynamicHeight);

        ctx.fillStyle = '#ff0000'; // red
        ctx.fill();
        ctx.strokeStyle = '#ffffff'; // white
        ctx.stroke();
      });
    });
  },

  customLegends: (chart: Chart): Chart.ChartLegendLabelItem[] => {
    const result: Chart.ChartLegendLabelItem[] = [];
    if (Chart.defaults.global.legend &&
      Chart.defaults.global.legend.labels &&
      Chart.defaults.global.legend.labels.generateLabels) {
      const labels = Chart.defaults.global.legend.labels.generateLabels(chart);
      if (labels instanceof Array) {
        labels.forEach((label: Chart.ChartLegendLabelItem) => {
          label.lineWidth = 0;
          result.push(label);
        })
      }
    }
    const chartData = chart.data as Chart.ChartData;
    if (chartData.datasets) {
      chartData.datasets.forEach((dataset: IChartDatasetEx, i: number) => {
        result[i].fillStyle = dataset.originalBarColor;
      });
    }
    return result;
  },

  customTooltips(this: IMetaData, tooltipModel: IChartTooltipModel) {
    // Tooltip Element
    let tooltipEl = document.getElementById(TOOLTIP_ELEMENT_ID);

    // Create element on first render
    if (!tooltipEl) {
      tooltipEl = document.createElement('div');
      tooltipEl.id = TOOLTIP_ELEMENT_ID;
      document.body.appendChild(tooltipEl);
    }
    tooltipEl.style.opacity = '0';

    if (this._chart == null || this._chart.canvas == null || tooltipModel.opacity === 0 || !this._chart.data.datasets || tooltipModel.dataPoints.length === 0) {
      return;
    }

    const dataPoint = tooltipModel.dataPoints[0];
    if (dataPoint.datasetIndex == null || dataPoint.index == null || dataPoint.yLabel == null) {
      return;
    }
    const dataset = this._chart.data.datasets[dataPoint.datasetIndex] as IChartDatasetEx;
    if (dataset.data == null) {
      return;
    }

    let alertValue = 0;
    if (dataset.alertValues != null) {
      alertValue = dataset.alertValues[dataPoint.index];
    }

    const value = dataset.data[dataPoint.index] as number;
    if (value === 0 && alertValue === 0) {
      return;
    }

    // Set Text
    const numberFormat = Intl.NumberFormat(i18n.locale);
    const label = dataset.compareDatasetName != null ? `${dataPoint.yLabel} (${dataset.compareDatasetName})` : dataPoint.yLabel;
    let tooltipText = `${label}: ${numberFormat.format(value)}`;
    if (typeof alertValue === 'number' && alertValue > 0) {
      tooltipText += ` - ${i18n.formatMessage(messages.thresholdMarkLabel)}: ${numberFormat.format(alertValue)}`;
    }
    tooltipEl.innerText = tooltipText;

    // Set left and top
    const chartCanvasRect = this._chart.canvas.getBoundingClientRect();
    const left = chartCanvasRect.left + window.pageXOffset + tooltipModel.caretX * 0.5;
    const top = chartCanvasRect.top + window.pageYOffset + tooltipModel.caretY + 10;
    tooltipEl.style.left = `${left}px`;
    tooltipEl.style.top = `${top}px`;
    tooltipEl.style.opacity = '1';
  },

  isAlert: (chartData: Chart.ChartData): boolean => {
    if (chartData.datasets) {
      return chartData.datasets.findIndex((ds: IChartDatasetEx) => ds.alertValues != null && Math.max(...ds.alertValues) > 0) > -1;
    }
    return false;
  },

  getLayoutPadding: (chartData: Chart.ChartData): Chart.ChartLayoutPaddingObject => {
    let labels: string[] | undefined;
    const numberFormat = Intl.NumberFormat(i18n.locale);
    if (Array.isArray(chartData.labels)) {
      labels = chartData.labels.map((x, i) => {
        if (chartData.datasets && chartData.datasets.length > 0 && chartData.datasets[0].data) {
          const value = parseNumber(chartData.datasets[0].data[i]);
          return value == null ? '' : `${numberFormat.format(value)}`;
        }
        return '';
      });
    }

    const { maxWidth } = getLabelsMaxWidthHeight(labels);
    return {
      left: 0,
      right: maxWidth + 10,
      top: 20,
      bottom: 0
    }
  }
};

function buildBarChartOptions(options: Chart.ChartOptions, settings: IWfChartSetting, chartData: Chart.ChartData, isStack: boolean) {
  if (options.scales == null) {
    options.scales = {};
  }
  if (options.scales.xAxes == null || options.scales.xAxes.length === 0) {
    options.scales.xAxes = [{}];
  }
  if (options.scales.xAxes[0].ticks == null) {
    options.scales.xAxes[0].ticks = {};
  }

  options.scales.xAxes[0].ticks.beginAtZero = true;

  // set Suggest max value
  let maxValue = 0;
  if (chartData.datasets) {
    chartData.datasets.forEach((dataset: IChartDatasetEx) => {
      if (dataset.alertValues) {
        maxValue = Math.max(maxValue, ...dataset.alertValues);
      }
    });
  }

  if (maxValue === 0) {
    options.scales.xAxes[0].ticks.suggestedMax = 10;
  }

  // Format xAxes
  options.scales.xAxes[0].ticks.callback = (value: any): string => {
    if (typeof (value) === 'number') {
      return Intl.NumberFormat(i18n.locale).format(value);
    }
    return value;
  };

  if (options.scales.yAxes == null || options.scales.yAxes.length === 0) {
    options.scales.yAxes = [{}];
  }


  // Set bar size
  ((options.scales.yAxes[0] as Chart.ChartXAxe) as any).categoryPercentage = 0.95;
  if (isStack || chartData.datasets && chartData.datasets.length === 1) {
    ((options.scales.yAxes[0] as Chart.ChartXAxe) as any).barPercentage = 0.45;
  }
  else if (chartData.datasets && chartData.datasets.length === 2) {
    ((options.scales.yAxes[0] as Chart.ChartXAxe) as any).barPercentage = 0.9;
  }

  // Settings 'stacked' on the X and Y axes to enable stacking
  if (isStack) {
    options.scales.xAxes[0].stacked = true;
    options.scales.yAxes[0].stacked = true;
  }

  // label setting
  if (settings.showLabel === true && options.plugins && options.plugins.datalabels) {
    if (!isStack) {
      options.plugins.datalabels.backgroundColor = (context: IContext) => {
        const index = context.dataIndex;
        if (context.dataset.backgroundColor
          && Array.isArray(context.dataset.backgroundColor)
          && context.dataset.backgroundColor[index]) {
          return context.dataset.backgroundColor[index] as string;
        }
        return context.dataset.backgroundColor as string;
      };
    }
    else {
      options.plugins.datalabels.align = 'center';
      options.plugins.datalabels.anchor = 'center';
    }

    options.plugins.datalabels.formatter = (value: number, context: IContext) => {
      return Intl.NumberFormat(i18n.locale).format(value);
    }

    options.plugins.datalabels.display = (context: IContext): boolean => {
      const index = context.dataIndex;
      if (context.dataset.data) {
        const value = context.dataset.data[index];
        return value != null && (value as number) > 0;
      }
      return false;
    };

    options.layout = { padding: BarChartUtils.getLayoutPadding(chartData) };
  }
  else {
    options.layout = { padding: { top: 20, bottom: 0, left: 0, right: 0 } };
  }

  (options as any).tooltips = {
    enabled: false,
    mode: 'y',
    intersect: false,
    custom: BarChartUtils.customTooltips,
  }

  if (!isStack && BarChartUtils.isAlert(chartData)) {
    options.animation = {
      onComplete: BarChartUtils.drawThreshHoldMark,
      onProgress: BarChartUtils.drawThreshHoldMark
    };
  }

  // custom legend because Threshold bar have different color
  if (options.legend && options.legend.labels) {
    options.legend.labels.generateLabels = BarChartUtils.customLegends;
  }
  return options;
}

const PieCharUtils = {
  customLegends: (chart: Chart): Chart.ChartLegendLabelItem[] => {
    let result: Chart.ChartLegendLabelItem[] = [];
    const { labels, datasets } = chart.data;
    if (labels && labels.length > 0 && datasets && datasets.length > 0) {
      const [firstDataset] = datasets; // Pie chart only render first dataset, ignore others
      const firstData = firstDataset.data;
      const numberFormat = Intl.NumberFormat(i18n.locale);
      if (firstData) {
        result = labels.filter((x, i) => x && typeof (firstData[i]) === 'number')
          .map((x, i) => ({
            text: `${x}: ${numberFormat.format(firstData[i] as number)}`,
            fillStyle: firstDataset.backgroundColor
              && Array.isArray(firstDataset.backgroundColor)
              ? firstDataset.backgroundColor[i] : firstDataset.backgroundColor as any,
            datasetIndex: 0
          }));
      }
    }
    return result;
  },

  getLayoutPadding: (chartData: Chart.ChartData): Chart.ChartLayoutPaddingObject => {
    let labels: string[] | undefined;
    const numberFormat = Intl.NumberFormat(i18n.locale);
    if (Array.isArray(chartData.labels)) {
      labels = chartData.labels.map((x, i) => {
        if (chartData.datasets && chartData.datasets.length > 0 && chartData.datasets[0].data) {
          return `${x}: ${numberFormat.format(chartData.datasets[0].data[i] as number)}`;
        }
        return '';
      });
    }

    const { maxWidth, maxHeight } = getLabelsMaxWidthHeight(labels);
    return {
      left: maxWidth + 10,
      right: maxWidth + 10,
      top: maxHeight + 20,
      bottom: maxHeight + 10
    }
  },
}

function buildPieChartOptions(options: Chart.ChartOptions, settings: IWfChartSetting, chartData: Chart.ChartData) {
  options.rotation = 0;

  let labels: string[];
  if (chartData.labels) {
    labels = chartData.labels as string[];
  }

  const numberFormat = Intl.NumberFormat(i18n.locale);
  if (settings.showLabel === true && options.plugins && options.plugins.datalabels) {
    options.plugins.datalabels.formatter = (value: number, context: IContext) => {
      return `${labels[context.dataIndex]}: ${numberFormat.format(value)}`;
    }
    if (chartData.datasets && chartData.datasets.length > 0) {
      options.plugins.datalabels.backgroundColor = chartData.datasets[0].backgroundColor as string[];
    }
    if (options.legend) {
      options.legend.display = false;
    }

    options.layout = { padding: PieCharUtils.getLayoutPadding(chartData) };
  }
  else {
    if (options.legend && options.legend.labels) {
      options.legend.display = true;
      options.legend.labels.generateLabels = PieCharUtils.customLegends;
    }
    options.layout = { padding: { top: 20, bottom: 0, left: 0, right: 0 } };
  }
  options.tooltips = {
    callbacks: {
      label: (tooltipItem: Chart.ChartTooltipItem, data: Chart.ChartData): string | string[] => {
        if (tooltipItem.index != null && tooltipItem.datasetIndex != null && data.labels && data.datasets) {
          const dataset = data.datasets[tooltipItem.datasetIndex];
          if (dataset.data) {
            return `${data.labels[tooltipItem.index]}: ${numberFormat.format(dataset.data[tooltipItem.index] as number)}`;
          }
        }
        return '';
      }
    }
  }
  return options;
}

interface IChartjsWrapperProps {
  chartType: 'horizontalBar' | 'pie';
  chartData?: IWfChartData;
  settings: IWfChartSetting;
  defaultOptions: Chart.ChartOptions;
  chartHeight?: number;

  chartTitle?: string;
  isLoading?: boolean;

  onChartElementDoubleClick?: (elementIndex: number | undefined) => void;
  onChartElementClick?: (elementIndex: number) => void;
}

export class ChartjsWrapper extends React.PureComponent<IChartjsWrapperProps> {
  private lastClick?: {
    time: number,
    x: number,
    y: number,
  };

  constructor(props: IChartjsWrapperProps) {
    super(props);
    this.handleChartClick = this.handleChartClick.bind(this);
  }

  public render() {
    const { chartType, defaultOptions, settings, chartData, onChartElementDoubleClick, chartHeight, chartTitle, isLoading } = this.props;
    let options: Chart.ChartOptions = defaultOptions;
    let isStack = false;

    if (isLoading) {
      return null;
    }

    if (chartData) {
      if (chartData.chartJsData) {
        if (chartType === 'horizontalBar') {
          const chartJsData = chartData!.chartJsData!;
          const dataProvider = wfChartManager.getDataProvider(settings.dataProviderId)!;
          isStack = dataProvider.metadata.isStack === true;
          options = buildBarChartOptions(defaultOptions, settings, chartJsData, isStack);

          if (chartJsData.labels) {
            // Add empty data
            chartJsData.labels = chartJsData.labels.filter(x => x !== '');
            if (chartHeight) {
              while (chartJsData.labels.length === 0 || chartHeight / chartJsData.labels.length > 80) {
                chartJsData.labels.push('');
              }
            } else {
              if (chartJsData.labels.length < 4) {
                for (let i = chartJsData.labels.length; i < 4; i++) {
                  chartJsData.labels.push('');
                }
              }
            }
          }
        }
        else {
          options = buildPieChartOptions(defaultOptions, settings, chartData.chartJsData);
        }
      }

      const getElementAtEvent = onChartElementDoubleClick != null ? (this.handleChartClick as any) : undefined;
      return <ChartComponent
        key={`${chartType}${isStack}`}
        type={chartType}
        data={chartData.chartJsData!}
        options={options}
        getElementAtEvent={getElementAtEvent} />;

    } else {
      // const plugins = [
      //   {
      //     afterDraw: (chart: any, easing: any) => {
      //       const ctx = chart.chart.ctx;
      //       const width = chart.chart.width;
      //       const height = chart.chart.height;
      //       chart.clear();

      //       ctx.save();
      //       ctx.textAlign = 'center';
      //       ctx.textBaseline = 'middle';
      //       ctx.fillText(`Can\'t load ${chartTitle} data`, width / 2, height / 2);
      //     }
      //   }
      // ];
      // return <ChartComponent
      //   key={`${chartType}_${settings.dataProviderId}_emptyData`}
      //   type={chartType}
      //   data={{}}
      //   options={options}
      //   plugins={plugins}
      // />
      return <div className='x-no-content'>{`Can\'t load ${chartTitle} data`}</div>
    }
  }

  public componentWillUnmount() {
    // Remove Tooltip Element (if any)
    const tooltipEl = document.getElementById(TOOLTIP_ELEMENT_ID);
    if (tooltipEl != null) {
      tooltipEl.remove();
    }
  }

  private handleChartClick(elements: ChartElement[], mouseEvent: MouseEvent) {
    if (mouseEvent == null) {
      this.lastClick = undefined;
      return;
    }
    let element: ChartElement | undefined;
    if (elements != null && elements.length > 0) {
      element = elements[0];
    }

    if (element != null && element._index != null && this.props.onChartElementClick != null) {
      this.props.onChartElementClick(element._index);
    }

    const thisClick = {
      time: Date.now(),
      x: mouseEvent.clientX,
      y: mouseEvent.clientY,
    };

    if (isNaN(thisClick.x) || isNaN(thisClick.y)) {
      this.lastClick = undefined;
      return;
    }

    if (this.lastClick == null || this.lastClick.x !== thisClick.x || this.lastClick.y !== thisClick.y
      || thisClick.time - this.lastClick.time > appConfig.doubleClickSpeed) {
      this.lastClick = thisClick;
      return;
    }

    this.lastClick = undefined;

    if (this.props.onChartElementDoubleClick != null) {
      let elementIndex: number | undefined;
      if (element != null) {
        elementIndex = element._index;
      }
      this.props.onChartElementDoubleClick(elementIndex);
    }
  }
}