import React, { Suspense, lazy } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Segment, Sidebar } from 'semantic-ui-react';
import { CustomerLevel } from 'src/api';
import { appConfig } from 'src/app';
import { route } from 'src/app/route';
import { HeaderBar } from 'src/components/header-bar';
import { parseNumber } from 'src/utils';
import MenuBar from './menu-bar';
const BuyAgriProductManager = lazy(() => import('src/components/buy-agri-product/buy-agri-product-manager'));
const BuyAgriProductManagerMobile = lazy(() => import('src/components/buy-agri-product/buy-agri-product-manager-mobile'));

interface IBuyAgriProductParams {
  customerId?: string;
}

export class BuyAgriProductPage extends React.PureComponent<
  RouteComponentProps<IBuyAgriProductParams>
> {
  public componentDidMount() {
    document.title = route.getPageTitle(this.props.location.pathname);
  }

  public render() {
    const title = route.getPageTitle(this.props.location.pathname);
    const customerId = parseNumber(this.props.match.params.customerId);
    return (
      <Sidebar.Pushable as={Segment} className='x-page'>
        <MenuBar />
        <Sidebar.Pusher>
          <Segment basic={true}>
            <HeaderBar title={title} />
            <Suspense fallback={<div className='x-loading' />}>
              {appConfig.isMobile ?
                <BuyAgriProductManagerMobile
                  customerLevel={CustomerLevel.Personal}
                  customerId={customerId}
                />
                :
                <BuyAgriProductManager
                  customerLevel={CustomerLevel.Personal}
                  customerId={customerId}
                />
              }
            </Suspense>
          </Segment>
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    );
  }
}
