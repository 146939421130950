import React, { Suspense, lazy } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Segment, Sidebar } from 'semantic-ui-react';
import MenuBar from './menu-bar';
import { route } from 'src/app/route';
import { parseNumber } from 'src/utils';
import { HeaderBar } from 'src/components/header-bar';
import { appConfig } from 'src/app';
const EditImport = lazy(() => import('src/components/import/edit-import'));
const EditImportMobile = lazy(() => import('src/components/import/edit-import-mobile'));

interface IEditImportParams {
  importId?: string;
}

export class EditImportPage extends React.PureComponent<
  RouteComponentProps<IEditImportParams, {}>
> {
  public componentDidMount() {
    document.title = route.getPageTitle(this.props.location.pathname);
  }

  public render() {
    const importId = parseNumber(this.props.match.params.importId);
    const title = route.getPageTitle(this.props.location.pathname);

    return (
      <Sidebar.Pushable as={Segment} className='x-page'>
        <MenuBar />

        <Sidebar.Pusher>
          <Segment basic={true}>
            <HeaderBar title={title} />

            <Suspense fallback={<div className='x-loading' />}>
            {appConfig.isMobile ?
             <EditImportMobile importId={importId} />
             :
             <EditImport importId={importId} />
            }
            </Suspense>
          </Segment>
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    );
  }
}
