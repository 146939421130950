import * as React from 'react';
import { FormikProps } from 'formik';
import NumberFormat, { NumberFormatValues } from 'react-number-format';

interface ITextNumberProps {
  /** Form field name. Required if use for connect with Formik. */
  name: string;

  value?: number;

  /** Pass Formik props into this attribute to connect the component with formik events. */
  formikProps?: FormikProps<any>;

  onChange?: (name: string, value?: number, currentState?: any) => void;

  [key: string]: any;
}

export class TextNumber extends React.PureComponent<ITextNumberProps>{
  public render() {
    const { placeholder, name, value, maxLength, onChange, formikProps, ...props } = this.props;

    return (
      <div className='ui input'>
        <NumberFormat
          placeholder={placeholder}
          isNumericString={true}
          thousandSeparator=' '
          decimalSeparator=','
          value={value}
          maxLength={maxLength || 25}
          onValueChange={this.handleOnChange}
          onBlur={this.handleOnBlur}
          name={name}
          {...props}
        />
      </div>
    )
  }

  private handleOnChange = (values: NumberFormatValues) => {
    const { name, onChange, formikProps } = this.props;
    if (name && formikProps) {
      formikProps.setFieldValue(name, values.floatValue, true);
    }
    if (onChange && name) {
      onChange(name, values.floatValue, { ...formikProps?.values, [name]: values.floatValue });
    }
  }

  private handleOnBlur = () => {
    const { name, formikProps } = this.props;
    if (name && formikProps) {
      formikProps.setFieldTouched(name);
    }
  }
}