import { downloadPdfFile, fetchHelper } from 'src/app/fetch-helper';
import { apiEndPoint } from './api-endpoint';

class SaleAgriProductProvider {
  public async getById(id: number) {
    return await fetchHelper<SaleAgriProductModel>(`${apiEndPoint.saleAgriProduct}/${id}`, 'GET');
  }

  public async searchSaleAgriProducts(params: SaleAgriProductCriteria) {
    const cloneParams = { ...params };

    const response = await fetchHelper<GetSaleAgriProductsResp>(apiEndPoint.saleAgriProduct, 'GET', cloneParams);
    return response.items;
  }

  public async editSaleAgriProduct(body: SaleAgriProductModel): Promise<SaleAgriProductModel> {
    let response;
    if (!body.id) {
      response = await fetchHelper<SaleAgriProductModel>(apiEndPoint.saleAgriProduct, 'POST', body);
    } else {
      response = await fetchHelper<SaleAgriProductModel>(`${apiEndPoint.saleAgriProduct}/${body.id}`, 'PUT', body);
    }
    return response;
  }

  public async deleteSaleAgriProduct(id: number) {
    return await fetchHelper(`${apiEndPoint.saleAgriProduct}/${id}`, 'DELETE');
  }

  public async printBuyInvoice(id: number) {
    await downloadPdfFile(`${apiEndPoint.saleAgriProduct}/${id}/printf`);
  }
}

export const saleAgriProductProvider = new SaleAgriProductProvider();
