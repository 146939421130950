

class DomHelper {
    public readonly rootElement = document.getElementById('root') as HTMLElement;
    private scrollBarWidth: number | undefined;

    public getScrollbarWidth(): number {
        try {
            if (this.scrollBarWidth != null) {
                return this.scrollBarWidth;
            }
            const outer = document.createElement("div");
            outer.style.visibility = "hidden";
            outer.style.width = "100px";
            // outer.style.msOverflowStyle = "scrollbar"; // needed for WinJS apps

            document.body.appendChild(outer);

            const widthNoScroll = outer.offsetWidth;
            // force scrollbars
            outer.style.overflow = "scroll";

            // add innerdiv
            const inner = document.createElement("div");
            inner.style.width = "100%";
            outer.appendChild(inner);

            const widthWithScroll = inner.offsetWidth;

            // remove divs
            if (outer.parentNode) {
                outer.parentNode.removeChild(outer);
            }

            this.scrollBarWidth = widthNoScroll - widthWithScroll;
            if (isNaN(this.scrollBarWidth) || this.scrollBarWidth > 50) {
                this.scrollBarWidth = 20;
            }

            return this.scrollBarWidth;
        }
        catch{
            return 20;
        }
    }
}

export const domHelper = new DomHelper();