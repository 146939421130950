import * as React from 'react';
import * as Yup from 'yup';
import {
  Formik,
  FormikProps,
  ErrorMessage,
  Field,
  FormikHelpers,
} from 'formik';
import {
  Modal,
  Form,
  Label,
  Button,
} from 'semantic-ui-react';
import { userProvider } from 'src/api';
import { userSession } from 'src/app';

const validationSchema = Yup.object<IChangePasswordDialogState>().shape({
  currentPassword: Yup.string().required('Nhập mật khẩu cũ!'),
  password: Yup.string().required('Nhập mật khẩu mới!'),
  rePassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Nhập lại mật khẩu không khớp!'),
});

interface IChangePasswordDialogProps {
  onClose: () => void;
}

interface IChangePasswordDialogState {
  currentPassword?: string,
  password?: string,
  rePassword?: string,
  error?: string,
}

export class ChangePasswordDialog extends React.Component<
  IChangePasswordDialogProps,
  IChangePasswordDialogState
> {
  constructor(props: IChangePasswordDialogProps) {
    super(props);
    this.state = {};
  }

  public render() {
    return (
      <Formik
        initialValues={this.state}
        enableReinitialize={true}
        render={this.renderForm}
        validationSchema={validationSchema}
        onSubmit={this.handleSubmit}
      />
    );
  }

  private renderForm = (formikProps: FormikProps<IChangePasswordDialogState>) => {
    const {
      currentPassword,
      password,
      rePassword,
      error,
    } = formikProps.values;
    const isSubmitting = formikProps.isSubmitting;
    const onSubmit = formikProps.submitForm;
    const onClose = () => this.props.onClose();
    return (
      <Modal
        open={true}
        centered={false}
        onClose={onClose}
        closeIcon={true}
        closeOnDimmerClick={false}
        size='tiny'
      >
        <Modal.Header content='Thay Đổi Mật Khẩu' />
        <Modal.Content>
          <Form onSubmit={onSubmit}>
            <Form.Field>
              <label>Mật Khẩu Cũ *</label>
              <Field
                name='currentPassword'
                value={currentPassword}
                disabled={isSubmitting}
                maxLength={250}
                type='Password'
              />
              <Label basic={true} pointing={true} color='red'>
                <ErrorMessage name='currentPassword' />
              </Label>
            </Form.Field>
            <Form.Field>
              <label>Mật Khẩu Mới *</label>
              <Field
                name='password'
                value={password}
                disabled={isSubmitting}
                maxLength={250}
                type='Password'
              />
              <Label basic={true} pointing={true} color='red'>
                <ErrorMessage name='password' />
              </Label>
            </Form.Field>
            <Form.Field>
              <label>Nhập Lại Mật Khẩu *</label>
              <Field
                name='rePassword'
                value={rePassword}
                disabled={isSubmitting}
                maxLength={250}
                type='Password'
              />
              <Label basic={true} pointing={true} color='red'>
                <ErrorMessage name='rePassword' />
              </Label>
            </Form.Field>

            {formikProps.status && formikProps.status.error && (
              <Label basic={true} color='red'>
                {formikProps.status.error}
              </Label>
            )}

            {error && (
              <Label basic={true} color='red'>
                {error}
              </Label>
            )}

            <button
              className='hidden-btn'
              type='submit'
              disabled={isSubmitting}
            />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            primary={true}
            onClick={onSubmit}
            disabled={isSubmitting}
            loading={isSubmitting}
            content='OK'
          />
          <Button onClick={onClose} disabled={isSubmitting} content='Cancel' />
        </Modal.Actions>
      </Modal >
    );
  };

  private handleSubmit = async (
    values: IChangePasswordDialogState,
    { setSubmitting }: FormikHelpers<any>
  ) => {
    setSubmitting(true);
    const {
      currentPassword,
      password,
      rePassword,
    } = values;
    const body = {
      currentPassword,
      password,
      rePassword,
    };
    try {
      await userProvider.changePassword(userSession.userId, body);
      this.props.onClose();
    } catch (error) {
      this.setState({
        error: `${error}`,
        currentPassword,
        password,
        rePassword,
      });
    }
    setSubmitting(false);
  };
}
