import { userSession } from 'src/app';
import { fetchHelper, uploadFile } from 'src/app/fetch-helper';
import { apiEndPoint } from './api-endpoint';

class TemplateProvider {
  public async getList() {
    const response = await fetchHelper<GetTemplateResp>(apiEndPoint.template, 'GET');
    return response.items;
  }

  public editTemplate(id: number, file: File): Promise<void> {
    return uploadFile(`${apiEndPoint.template}/${id}/upload`, file);
  }

  public getPdfLink(id: number, updatedAt: Date|string) {
    return `${apiEndPoint.template}/${id}/preview?&t=${(new Date(updatedAt)).getTime().toString(36)}&p=${userSession.userIdentityToken}`;
  }

  public getDowloadLink(id: number, updatedAt: Date|string) {
    return `${apiEndPoint.template}/${id}/download?&t=${(new Date(updatedAt)).getTime().toString(36)}&p=${userSession.userIdentityToken}`;
  }

  public async data(id: number) {
    return fetchHelper<{}>(`${apiEndPoint.template}/${id}/preview-data`, 'GET');
  }
}
export const templateProvider = new TemplateProvider();