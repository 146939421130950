import * as React from 'react';
import { CheckboxProps, Form, FormCheckboxProps } from 'semantic-ui-react';
import { FormikProps } from 'formik';

interface ICheckboxProps extends FormCheckboxProps {
  /** Pass Formik props into this attribute to connect the component with formik events. */
  formikProps?: FormikProps<any>;
}

export class Checkbox extends React.PureComponent<ICheckboxProps>{
  public render() {
    const { formikProps, ...newProps } = this.props;
    newProps.onChange = this.handleOnChange;

    return <Form.Checkbox {...newProps} />;
  }

  private handleOnChange = (event: any, data: CheckboxProps) => {
    const { onChange, formikProps, radio } = this.props;
    if (onChange != null) {
      onChange(event, data);
    }

    if (formikProps && data.name) {
      if (radio) { // is Radio
        formikProps.setFieldValue(data.name, data.value);
      }
      else {
        formikProps.setFieldValue(data.name, data.checked);
      }
      formikProps.setFieldTouched(data.name);
    }
  }
}