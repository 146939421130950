import * as React from 'react';
import { IWfChartData, IGroup, IField, IWfChartSetting, IRecord } from './common';
import { TimeSpan } from 'src/utils/timespan';
import { i18n } from 'src/app';
import { List } from 'semantic-ui-react';
import DataTable, { IDataTableColumn, IDataTableGroup } from '../data-table';
import { injectIntl, InjectedIntlProps } from 'react-intl';

const GroupHeader = (props: IGroup) => (
  <List horizontal={true} className='x-datatable__groupHeaderInfo'>
    <List.Item>{props.name}: <strong>{props.value}</strong></List.Item>
    <List.Item>{props.info}</List.Item>
  </List>
);

function displayTimeSpan(millisecond: number) {
  const timeSpan = TimeSpan.fromTicks(millisecond);
  const hours = timeSpan.hours > 9 ? timeSpan.hours : `0${timeSpan.hours}`;
  const minutes = timeSpan.minutes > 9 ? timeSpan.minutes : `0${timeSpan.minutes}`;
  const Seconds = timeSpan.seconds > 9 ? timeSpan.seconds : `0${timeSpan.seconds}`;
  return `${timeSpan.days}d ${hours}:${minutes}:${Seconds}`;
}

function buildColumns(data: IWfChartData, intl: ReactIntl.InjectedIntl, dataProviderId: string): IDataTableColumn[] {
  const columns: IDataTableColumn[] = [];

  data.fields.forEach((field, index) => {
    const align = field.dataType === 'string' ? 'left' : 'right';
    columns.push({
      defaultWidth: field.width,
      text: field.name,
      cellClass: field.dataType === 'string' ? '' : 'x-cell-right',
      headerAlign: field.dataType === 'string' ? 'left' : 'right',
      getCellData: (rowData: RowData, columnIndex: number) =>
        (
          <div style={{ whiteSpace: 'nowrap', textAlign: align }}>
            {rowData.values[columnIndex]}
          </div>
        )
    });
  });

  return columns;
}

interface RowData {
  groupValue?: string;
  className?: string;
  values: string[],
}

function buildRows(data: IWfChartData) {
  const rows: RowData[] = [];
  const numberFormat = Intl.NumberFormat(i18n.locale);
  data.records.forEach(record => {
    const row: RowData = {
      groupValue: record.groupValue,
      className: record.backgroundColor,
      values: []
    };
    data.fields.forEach((field: IField, colIndex: number) => {
      let cellValue = record.values[colIndex];

      if (field.dataType === 'tick') {
        cellValue = displayTimeSpan(cellValue as number);
      }
      else if (field.dataType === 'numeric') {
        cellValue = numberFormat.format(cellValue as number);
      }

      row.values.push(cellValue as string);
    });
    rows.push(row);
  });
  return rows;
}

function buildRowGroups(data: IWfChartData) {
  if (!data.groups || data.groups.length === 0) {
    return undefined;
  }
  const rowGroups: IDataTableGroup[] = [];
  data.groups.forEach(group => {
    rowGroups.push({
      groupId: group.value,
      groupHeader: <GroupHeader {...group} />
    });
  });
  return rowGroups;
}

const getRowGroup = (rowData: IRecord) => {
  return rowData.groupValue || '';
}

function buildFooterRow(data: IWfChartData) {
  const firstSummaryField = data.fields.find(x => x.summary !== 'none');
  if (firstSummaryField == null) {
    return;
  }

  const footerRow: any[] = [];
  const numberFormat = Intl.NumberFormat(i18n.locale);
  data.fields.forEach((field: IField, colIndex: number) => {
    if (field.dataType === 'string' || field.summary === 'none') {
      footerRow.push('');
      return;
    }

    if (field.summary === 'sum') {
      const columnValues = data.records.map(record => record.values[colIndex] as number);
      const totalColValue = columnValues.length === 0 ? 0 : columnValues.reduce((a, b) => a + b);
      const displayValue = field.dataType === 'numeric' ? numberFormat.format(totalColValue) : displayTimeSpan(totalColValue);
      footerRow.push(<div style={{ whiteSpace: 'nowrap', textAlign: 'right' }}>{displayValue}</div>);
    }
  });

  return footerRow;
}

interface IGridChartProps extends InjectedIntlProps {
  chartData?: IWfChartData;
  settings: IWfChartSetting;

  gridTitle?: string;
  isLoading?: boolean;

  onDataTableColumnResize: (columnWidths: number[]) => void;
}

interface IGridChartState {
  selectedRowIndexes?: number[];
}

class GridChart extends React.PureComponent<IGridChartProps, IGridChartState>{
  private currentFields?: IField[];
  private columns?: IDataTableColumn[];
  private rows?: RowData[];

  public constructor(props: IGridChartProps) {
    super(props);
    this.state = {};
  }

  public render() {
    const { chartData, settings, gridTitle, isLoading } = this.props;
    if (isLoading) {
      return null;
    }

    if (chartData === null || typeof chartData === 'undefined') {
      return <div className='x-no-content'>{`Can\'t load ${gridTitle} data`}</div>

    } else {
      if (this.currentFields !== chartData.fields) {
        this.currentFields = chartData.fields;
        this.columns = buildColumns(chartData, this.props.intl, settings.dataProviderId);
      }
      const rowGroups = buildRowGroups(chartData);
      const footerRow = buildFooterRow(chartData);
      this.rows = buildRows(chartData);
      if (settings.gridColumnWidths != null && settings.gridColumnWidths.length === this.columns?.length) {
        for (let i = 0; i < this.columns.length; i++) {
          this.columns[i].width = settings.gridColumnWidths[i];
        }
      }

      return (
        <DataTable
          className='x-grid-chart'
          columns={this.columns || []}
          rows={this.rows}
          rowGroups={rowGroups}
          getRowGroup={getRowGroup}
          footerRow={footerRow}
          onColumnResize={this.props.onDataTableColumnResize}
          onRowSelect={this.handleRowSelected}
          selectedRowIndexes={this.state.selectedRowIndexes}
          rowHeight={30}
        />
      );
    }
  }

  private handleRowSelected = (rowIndexes: number[]) => {
    this.setState({ selectedRowIndexes: rowIndexes });
  }
}

export default injectIntl(GridChart);