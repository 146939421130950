import { route } from './route';
import { userLogout } from './auth';
import EventEmitter from 'eventemitter3';

export interface TaskbarButton {
  id: string;
  title: string;
  icon: string;
  group: string;
  ordinal?: number;
  disabled?: boolean;
  disableMessage?: string;
  toggleIfActive?: boolean;
  checked?: boolean;
  active?: boolean;
  hidden?: boolean;

  urlPath?: string;
  urlPathPattern?: RegExp | string;
  href?: string;
  onClick?: () => void;
}

const BUTTONS_CHANGE_EVENT = 'ButtonsChange';

class TaskbarManager {
  public readonly buttons: TaskbarButton[] = [];
  private eventEmitter: EventEmitter;

  constructor() {
    this.eventEmitter = new EventEmitter();
  }

  public load(autoRefresh: () => void, refresh: () => void) {
    const refreshButton: TaskbarButton = { id: 'refresh', group: 'actions', onClick: refresh, title: 'Refresh', icon: 'refresh' };

    const listButtons: TaskbarButton[] = [
      { id: 'home', group: '0', urlPath: route.home(), title: 'Home', icon: 'home' },

      { id: 'export', group: '1', urlPath: '/export', title: 'Quản Lý Hóa Đơn Bán Hàng', icon: 'bill', toggleIfActive: true, urlPathPattern: /^\/export/ },
      { id: 'create-export', group: '1', urlPath: '/edit-export/new', title: 'Bán Hàng', icon: 'delivery-box', toggleIfActive: true, urlPathPattern: /^\/edit-export/ },
      { id: 'product', group: '1', urlPath: '/product', title: 'Quản Lý Sản Phẩm', icon: 'product', toggleIfActive: true, urlPathPattern: /^\/product/ },
      { id: 'customer', group: '1', urlPath: '/customer', title: 'Quản Lý Khách Hàng', icon: 'teamwork', toggleIfActive: true, urlPathPattern: /^\/customer/ },
      { id: 'import', group: '1', urlPath: '/import', title: 'Quản Lý Phiếu Nhập', icon: 'import', toggleIfActive: true, urlPathPattern: /^\/import/ },
      { id: 'supplier', group: '1', urlPath: '/supplier', title: 'Quản Lý Đại Lý Nhập', icon: 'supplier', toggleIfActive: true, urlPathPattern: /^\/supplier/ },
      
      { id: 'auto-refresh', group: 'actions', onClick: autoRefresh, title: 'Toggle Auto Refresh', icon: 'auto-refresh' },
      refreshButton,

      { id: 'logout', group: 'last', onClick: userLogout, ordinal: 1, title: 'Logout', icon: 'logout' },
    ];

    // todo: check permission for enable button
    // if (permissions) {
    //     listButtons.forEach(btn => {
    //         if (btn.urlPath && !route.checkAccess(btn.urlPath, permissions)) {
    //             btn.disabled = true;
    //             btn.disableMessage = `You don't have permission to access this page.`;
    //         }
    //     });
    // }


    this.buttons.push(...listButtons);
    this.eventEmitter.emit(BUTTONS_CHANGE_EVENT);
  }

  public subscribeButtonsChangeEvent(handler: () => void) {
    this.eventEmitter.on(BUTTONS_CHANGE_EVENT, handler);
  }

  public unsubscribeButtonsChangeEvent(handler: () => void) {
    this.eventEmitter.off(BUTTONS_CHANGE_EVENT, handler);
  }

  public changeLayout(layoutKey: string, locationPath: string) {
    this.buttons.forEach(btn => {
      if (btn.urlPath && btn.urlPathPattern) {
        let active = false;
        if ('string' === typeof btn.urlPathPattern) {
          if (btn.urlPathPattern.endsWith('*')) {
            active = locationPath.startsWith(btn.urlPathPattern.slice(0, btn.urlPathPattern.length - 2));
          }
          else {
            active = locationPath === btn.urlPathPattern;
          }
        }
        else {
          active = btn.urlPathPattern.test(locationPath);
        }

        btn.active = active;
        btn.checked = active;
      }
    });

    this.eventEmitter.emit(BUTTONS_CHANGE_EVENT);
  }
}

export const taskbarManager = new TaskbarManager();