import { securityClient } from "src/api";

interface ProfileData {
  layoutModel?: any;
  chartSettings?: any;
}

class UserProfile {
  private data: ProfileData;

  constructor() {
    this.data = {};
  }

  public loadProfile(profileStr?: string) {
    if (!profileStr) {
      return;
    }

    this.data = JSON.parse(profileStr);
  }

  public async save() {
    await securityClient.updateProfile(JSON.stringify(this.data));
  }

  public get layoutModel() {
    return this.data?.layoutModel;
  }

  public set layoutModel(value) {
    this.data.layoutModel = value;
  }

  public get chartSettings() {
    return this.data?.chartSettings;
  }

  public set chartSettings(value) {
    this.data.chartSettings = value;
  }
};

export const userProfile = new UserProfile();