import * as React from 'react';
import * as Yup from 'yup';
import {
  Formik,
  FormikProps,
  ErrorMessage,
  Field,
  FormikHelpers,
} from 'formik';
import {
  Modal,
  Form,
  Label,
  Button,
  DropdownItemProps,
  Icon,
} from 'semantic-ui-react';
import { customerProvider, CustomerLevel } from 'src/api';
import { DateTimePicker } from '../datetime-picker';
import { Select } from '../select';

const validationSchema = Yup.object<INewCustomerDialogState>().shape({
  name: Yup.string().required('Nhập tên khách hàng.'),
  phone: Yup.string()
    .required('Vui lòng nhập số điện thoại.')
    .matches(/^0[1-9][0-9]{8,9}$/, 'Số điện thoại không đúng.'),
  email: Yup.string().nullable().email('Email không đúng.')
});

export const reviewOptions = () => {
  const stars = [1, 2, 3, 4, 5, 6];
  const options: DropdownItemProps[] = [
    {
      text: '',
      value: 0,
    },
  ];
  stars.forEach(s => {
    const text = [];
    for (let i = 0; i < s; i++) {
      text.push(<Icon name='star' style={{ marginRight: 2 }} />);
    }

    options.push(
      {
        text,
        value: s,
      },
    );
  });
  return options;
}

interface INewCustomerDialogProps {
  customerId?: number;
  customerLevel?: CustomerLevel;
  onClose: () => void;
  onReload?: () => Promise<void>;
  onReturn?: (customer: CustomerModel) => void;
}

interface INewCustomerDialogState extends CustomerModel {
  error?: string;
  genderOptions?: DropdownItemProps[];
}

export class NewCustomerDialog extends React.Component<
  INewCustomerDialogProps,
  INewCustomerDialogState
> {
  private isWillUnmount: boolean = false;

  constructor(props: INewCustomerDialogProps) {
    super(props);
    this.state = {
      id: 0,
      name: '',
      phone: '',
      email: '',
      address: '',
      dateOfBirth: new Date(1990, 1, 1),
      status: 0,
      gender: 0,
      debt: 0,
      cifNo: '',
      level: props.customerLevel ?? CustomerLevel.Personal,
      review: 0,
    };
  }

  public componentDidMount() {
    this.handleGetCustomerInfo();
  }

  public render() {
    return (
      <Formik
        initialValues={this.state}
        enableReinitialize={true}
        render={this.renderForm}
        validationSchema={validationSchema}
        onSubmit={this.handleSubmit}
      />
    );
  }

  public componentWillUnmount() {
    this.isWillUnmount = true;
  }

  private customerLevelOptions = () => {
    if (this.props.customerLevel === CustomerLevel.Agency) {
      return [{
        text: 'Đại Lý Cấp 2',
        value: CustomerLevel.Agency,
      }];
    }

    return [
      {
        text: 'Khách Lẻ',
        value: CustomerLevel.Personal,
      },
      {
        text: 'Đại Lý Cấp 2',
        value: CustomerLevel.Agency,
      },
    ];
  }

  private renderForm = (formikProps: FormikProps<INewCustomerDialogState>) => {
    const {
      id,
      name,
      phone,
      email,
      address,
      shipAddress,
      dateOfBirth,
      cifNo,
      level,
      spouse,
      spousePhone,
      debtBookNo,
      review,
      notes,
    } = formikProps.values;
    const isSubmitting = formikProps.isSubmitting;
    const onSubmit = formikProps.submitForm;
    const onClose = this.props.onClose;

    return (
      <Modal
        open={true}
        centered={false}
        onClose={onClose}
        closeIcon={true}
        closeOnDimmerClick={false}
      >
        <Modal.Header
          content={
            id === 0 ? 'Thêm Mới Khách Hàng' : `Cập Nhật Thông Tin Khách Hàng`
          }
        />
        <Modal.Content>
          <Form onSubmit={onSubmit}>
            <Form.Group widths='equal'>
              <Form.Field>
                <label>Tên Khách Hàng*</label>
                <Field
                  name='name'
                  value={name}
                  disabled={isSubmitting}
                  maxLength={250}
                />
                <Label basic={true} pointing={true} color='red'>
                  <ErrorMessage name='name' />
                </Label>
              </Form.Field>

              <Form.Field>
                <label>Số Điện Thoại*</label>
                <Field
                  name='phone'
                  value={phone}
                  disabled={isSubmitting}
                  maxLength={20}
                />
                <Label basic={true} pointing={true} color='red'>
                  <ErrorMessage name='phone' />
                </Label>
              </Form.Field>
            </Form.Group>

            <Form.Group widths='equal'>
              <Form.Field>
                <label>Số CMND/CCCD</label>
                <Field
                  name='cifNo'
                  value={cifNo}
                  disabled={isSubmitting}
                  maxLength={11}
                />
                <Label basic={true} pointing={true} color='red'>
                  <ErrorMessage name='cifNo' />
                </Label>
              </Form.Field>

              <Form.Field>
                <label>Ngày Sinh</label>
                <DateTimePicker
                  formikProps={formikProps}
                  name='dateOfBirth'
                  value={dateOfBirth}
                  display='date'
                  disabled={isSubmitting}
                />
              </Form.Field>
            </Form.Group>

            <Form.Group widths='equal'>
              <Form.Field>
                <label>Email</label>
                <Field
                  name='email'
                  value={email}
                  disabled={isSubmitting}
                  maxLength={250}
                />
                <Label basic={true} pointing={true} color='red'>
                  <ErrorMessage name='email' />
                </Label>
              </Form.Field>
              <Form.Field>
                <label>Loại</label>
                <Select
                  formikProps={formikProps}
                  name='level'
                  value={level}
                  options={this.customerLevelOptions()}
                  disabled={isSubmitting}
                />
              </Form.Field>
              <Form.Field>
                <label>Số Sổ Nợ</label>
                <Field
                  name='debtBookNo'
                  value={debtBookNo}
                  disabled={isSubmitting}
                  maxLength={50}
                />
              </Form.Field>
            </Form.Group>

            <Form.Group widths='equal'>
              <Form.Field>
                <label>Họ Tên Vợ / Chồng</label>
                <Field
                  name='spouse'
                  value={spouse}
                  disabled={isSubmitting}
                  maxLength={250}
                />

              </Form.Field>
              <Form.Field>
                <label>Số Điện Thoại Vợ / Chồng</label>
                <Field
                  name='spousePhone'
                  value={spousePhone}
                  disabled={isSubmitting}
                  maxLength={20}
                />
              </Form.Field>

              <Form.Field>
                <label>Đánh Giá</label>
                <Select
                  formikProps={formikProps}
                  name='review'
                  value={review}
                  options={reviewOptions()}
                  disabled={isSubmitting}
                />
              </Form.Field>
            </Form.Group>

            <Form.Field>
              <label>Địa Chỉ</label>
              <Field
                name='address'
                value={address}
                disabled={isSubmitting}
                maxLength={250}
              />
              <Label basic={true} pointing={true} color='red'>
                <ErrorMessage name='address' />
              </Label>
            </Form.Field>

            <Form.Field>
              <label>Địa Chỉ Giao Hàng</label>
              <Field
                name='shipAddress'
                value={shipAddress}
                disabled={isSubmitting}
                maxLength={250}
              />
              <Label basic={true} pointing={true} color='red'>
                <ErrorMessage name='shipAddress' />
              </Label>
            </Form.Field>

            <Form.Field>
              <label>Ghi Chú</label>
              <Field
                name='notes'
                value={notes}
                disabled={isSubmitting}
                maxLength={1000}
              />
            </Form.Field>

            {formikProps.status && formikProps.status.error && (
              <Label basic={true} color='red'>
                {formikProps.status.error}
              </Label>
            )}

            <button
              className='hidden-btn'
              type='submit'
              disabled={isSubmitting}
            />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            primary={true}
            onClick={onSubmit}
            disabled={isSubmitting}
            loading={isSubmitting}
            content='OK'
          />
          <Button onClick={onClose} disabled={isSubmitting} content='Cancel' />
        </Modal.Actions>
      </Modal>
    );
  };
  private handleGetCustomerInfo = async () => {
    const customerId = this.props.customerId;
    if (customerId === null || customerId === 0 || customerId === undefined) {
      return;
    }
    const customerInfo = await customerProvider.getById(customerId);
    this.setState({ ...customerInfo });
  };

  private handleSubmit = async (
    values: INewCustomerDialogState,
    { setSubmitting, setStatus }: FormikHelpers<any>
  ) => {
    setStatus({});
    try {
      const customer: CustomerModel = {
        id: values.id,
        name: values.name,
        cifNo: values.cifNo,
        phone: values.phone,
        email: values.email,
        address: values.address,
        shipAddress: values.shipAddress,
        dateOfBirth: values.dateOfBirth,
        status: values.status,
        level: values.level,
        spouse: values.spouse,
        spousePhone: values.spousePhone,
        debtBookNo: values.debtBookNo,
        review: values.review,
        notes: values.notes,
      };
      const response = await customerProvider.editCustomer(customer);
      const { onReturn, onReload, onClose } = this.props;
      if (onReturn != null) {
        onReturn(response);
      }
      if (onReload != null) {
        await onReload();
      }
      onClose();
    } catch (error) {
      if (this.isWillUnmount) {
        return;
      }
      setStatus({ error: `${error}` });
    }
    setSubmitting(false);
  };
}
