import { productProvider, statisticProvider, storeProvider } from 'src/api';
import { IWfChartInstance, IWfChartData, IField, IRecord } from '../common';
import { IChartDataProvider, ChartDataBuilder } from './chart-data-provider';

const chartName = 'Số lượng phân bón tồn kho';

let fields: IField[] = [
  { name: 'Tên', dataType: 'string', summary: 'none' },
  // { name: 'Số Lượng Kho 1', dataType: 'numeric', summary: 'none' },
  // { name: 'Số Lượng Kho 2', dataType: 'numeric', summary: 'none' },
];

async function loadData(instance: IWfChartInstance) {
  const [remains, products, stores] = await Promise.all([
    statisticProvider.getProductRemaining(),
    productProvider.getAllProducts(true),
    storeProvider.getStores(),
  ]);

  fields = [
    { name: 'Tên', dataType: 'string', summary: 'none' },
  ];
  stores.forEach(s => {
    fields.push({
      name: s.name || '',
      dataType: 'numeric',
      summary: 'sum',
    });
  });
  fields.push({
    name: 'Tổng',
    dataType: 'numeric',
    summary: 'sum',
  });

  const records: IRecord[] = [];
  const productIds = new Set(remains.map(x => x.productId));
  productIds.forEach(pId => {
    const prod = products.find(x => x.id === pId);

    const rdVals: any = [prod?.name || ''];
    stores.forEach(s => {
      const remainObj = remains.find(x => x.productId === pId && x.storeId === s.id);
      rdVals.push(remainObj?.quantity || 0);
    });
    rdVals.push(prod?.quantity); // add 'Tồn Kho'
    records.push({
      values: rdVals
    });
  });

  const wfChartData: IWfChartData = { fields, records };
  ChartDataBuilder.sortIWfChartData(wfChartData, instance.settings.sortBy, instance.settings.sortDirection);

  if (instance.settings.chartType === 'horizontalBar') {
    wfChartData.chartJsData = ChartDataBuilder.decorBarChartData({
      chartData: wfChartData,
    });
  }
  else if (instance.settings.chartType === 'pie') {
    wfChartData.chartJsData = ChartDataBuilder.decorPieChartData(wfChartData);
  }
  return wfChartData;
}

export const ProductRemainingDataProvider: IChartDataProvider = {
  id: chartName,
  metadata: {
    name: chartName,
    category: 'Phân Bón',
    fieldNames: () => fields.map(x => x.name),
    unitList: ['Bao'],
    supportedChartTypes: ['grid']
  },
  defaultSettings: {
    chartType: 'grid',
    showLabel: true,
    sortBy: 3,
    sortDirection: 'desc',
    dataProviderId: chartName,
    unit: 'Bao',
    gridColumnWidths: [3, 2, 2, 2],
  },
  loadData,
};