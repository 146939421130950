import * as React from 'react';
import {
  Table,
  Form,
  Button,
  Icon,
  Segment,
  Label,
  Dimmer,
  Loader,
  Select,
  DropdownItemProps,
  Header,
  TextArea,
  DropdownProps,
  ButtonProps,
  CheckboxProps,
} from 'semantic-ui-react';
import { RouteComponentProps, withRouter } from 'react-router';
import { route } from 'src/app/route';
import { showAlert } from 'src/app';
import { exportProvider, productProvider } from 'src/api';
import { customSearch, formatNumber, toVND } from 'src/utils';
import { SearchCustomer } from '../search-customer';
import { Checkbox } from '../checkbox';
import { ContactInfo } from '../contact-info';
import { NewCustomerDialog } from '../customer/new-customer-dialog';
import { DateTimePicker } from '../datetime-picker';
import { TextNumber } from '../text-number';
import PdfModal from '../pdf-model';
import { detailViewDefault } from './export-helper';

class EditExportComponent extends React.Component<IExportProps & RouteComponentProps, IExportState> {
  constructor(props: IExportProps & RouteComponentProps) {
    super(props);
    this.state = {
      records: [
        {
          ...detailViewDefault,
        },
      ],
      exportId: props.exportId,
      customerId: 0,
      customerName: '',
      customerPhone: '',
      phone: '',
      email: '',
      address: '',
      notes: '',
      isFullPayment: false,
      isLoading: true,
      isOpenNewCustomer: false,
      dueDate: new Date(),
      createdDate: new Date(),
    };
  }

  public componentDidMount() {
    this.loadData();
  };

  public render() {
    const {
      records,
      isLoading,
      productOptions,
      exportId,
      phone,
      email,
      address,
      totalAmount,
      pdfUrl,
      customerId,
      customerName,
      notes,
      isFullPayment,
      paidAmount,
      isSubmitting,
      isOpenNewCustomer,
      createdDate,
      dueDate,
    } = this.state;
    const lastIndex = records.length - 1;
    return (
      <div className='x-details'>
        {isLoading && (
          <Dimmer active={true}>
            <Loader>Loading</Loader>
          </Dimmer>
        )}
        <Form className='x-table-wrapper' onSubmit={undefined}>
          <Table celled={true} color='olive'>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell textAlign='center'>#</Table.HeaderCell>
                <Table.HeaderCell width={6}>Tên Phân Bón</Table.HeaderCell>
                <Table.HeaderCell width={4}>Đơn Giá / Đơn Vị</Table.HeaderCell>
                <Table.HeaderCell width={2}>Số Lượng</Table.HeaderCell>
                <Table.HeaderCell width={3} textAlign='center'>Thành Tiền</Table.HeaderCell>
                <Table.HeaderCell />
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {records.map((rd, index) => (
                <Table.Row key={index}>
                  <Table.Cell textAlign='center'>{index + 1}</Table.Cell>
                  <Table.Cell className='cell-input'>
                    <Form.Field>
                      <Select
                        value={rd.productId}
                        name={`product-${index}`}
                        options={productOptions || []}
                        search={customSearch}
                        onChange={this.handleProductChange}
                      />
                    </Form.Field>
                  </Table.Cell>

                  <Table.Cell className='cell-input'>
                    <TextNumber
                      style={{ maxWidth: '120px' }}
                      value={rd.unitAmount}
                      name={`unitAmount-${index}`}
                      onChange={this.handleUnitAmountChange}
                    /> / {rd.productUnit}
                  </Table.Cell>
                  <Table.Cell className='cell-input'>
                    <TextNumber
                      value={rd.quantity}
                      name={`quantity-${index}`}
                      onChange={this.handleQuantityChange}
                      onKeyDown={this.onKeyDown}
                    />
                  </Table.Cell>

                  <Table.Cell textAlign='right'>
                    {formatNumber(
                      (rd.unitAmount || 0) *
                      (rd.quantity || 0)
                    )}
                  </Table.Cell>
                  <Table.Cell className='action-cell'>
                    {index === lastIndex && (
                      <Button
                        type='button'
                        icon={true}
                        style={{ background: 'transparent', color: '#198f35' }}
                        tabIndex={-1}
                        onClick={this.handleAddClick}
                        title='Thêm hàng mới'
                        size='big'
                      >
                        <Icon name='plus square' />
                      </Button>
                    )}
                    <Button
                      type='button'
                      icon={true}
                      style={{ background: 'transparent', color: '#db2828' }}
                      tabIndex={-1}
                      name={`quantity-${index}`}
                      onClick={this.handleDeleteClick}
                      title='Xóa hàng này'
                      size='big'
                    >
                      <Icon name='trash alternate' />
                    </Button>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Form>

        <Segment className='x-info-wrapper'>
          <Header as='h3' dividing={true}>
            Thông Tin Khách Hàng
          </Header>

          <Form>
            <Form.Field>
              <label>Khách Hàng</label>
              <SearchCustomer
                name='customerName'
                value={customerName}
                onSelectCustomer={this.handleCustomerSelected}
                onChange={this.handleCustomerChange}
              />
            </Form.Field>

            <div className='x-edit-contact-bl'>
              <Button
                className='x-new-edit-contact-btn'
                type='button'
                basic={true}
                color='blue'
                compact={true}
                floated='left'
                onClick={this.handleAddNewCustomer}
              >
                <Icon name='add' />
                Khách Hàng Mới
              </Button>
              <Button
                className='x-new-edit-contact-btn'
                type='button'
                basic={true}
                color='blue'
                disabled={!customerId}
                compact={true}
                floated='right'
                onClick={this.handleEditCustomer}
              >
                <Icon name='edit' />
                Chỉnh Sửa
              </Button>
            </div>

            <div style={{ paddingBlock: '5px' }}>
              <ContactInfo phone={phone} address={address} email={email} />
            </div>

            <Form.Group widths='sixteen'>
              <Form.Field width='eight'>
                <label>Ngày Bán</label>
                <DateTimePicker
                  name='createdDate'
                  value={createdDate}
                  display='date'
                  disabled={isSubmitting}
                  onChange={this.handleDateChange}
                />
              </Form.Field>

              <Form.Field width='eight'>
                <label>Hạn Thanh Toán</label>
                <DateTimePicker
                  className='pos-right'
                  name='dueDate'
                  value={dueDate}
                  display='date'
                  disabled={isSubmitting}
                  onChange={this.handleDateChange}
                />
              </Form.Field>
            </Form.Group>

            <Form.Field>
              <label>Ghi Chú</label>
              <Form.Field
                control={TextArea}
                name='notes'
                value={notes}
                maxLength='500'
                onChange={this.handleNoteChange}
              />
            </Form.Field>

            <div style={{ textAlign: 'center' }}>
              <Label.Group size='large' tag={true}>
                <Label>{toVND(totalAmount || 0)}</Label>
              </Label.Group>
            </div>

            <Form.Field>
              <label>&nbsp;</label>
              <Checkbox
                name='isFullPayment'
                checked={isFullPayment}
                disabled={isSubmitting}
                label='Thanh toán toàn bộ'
                onChange={this.handleIsFullPaymentChange}
              />
            </Form.Field>

            <Form.Field>
              <label>Số Tiền Khách Trả</label>
              <TextNumber
                name='paidAmount'
                value={paidAmount}
                disabled={isFullPayment || isSubmitting}
                onChange={this.handlePaymentChange}
              />
            </Form.Field>

            <div style={{ textAlign: 'center' }}>
              <Button
                type='button'
                content={exportId ? 'Cập Nhật' : 'Tạo Mới'}
                primary={true}
                onClick={this.handleSubmitForm}
                disabled={isSubmitting}
                loading={isSubmitting}
              />
              <Button
                type='button'
                content='Hủy'
                onClick={this.handleCancelClick}
                disabled={isSubmitting}
              />
              {exportId && (
                <Button
                  type='button'
                  color='grey'
                  content='IN'
                  onClick={this.handlePrintClick}
                />
              )}
            </div>
          </Form>
        </Segment>

        {isOpenNewCustomer && (
          <NewCustomerDialog
            customerId={customerId}
            onClose={this.handleNewCustomersDialogClose}
            onReturn={this.handleCustomerDlgReturn}
          />
        )}

        {pdfUrl &&
          <PdfModal
            pdfUrl={pdfUrl}
            onClose={this.handlePdfModelClose}
            title='In Hóa Đơn Bán Hàng'
          />}
      </div>
    );
  }

  private loadData = async () => {
    try {
      const { exportId } = this.state;
      const [exportModel, products] = await Promise.all([
        !exportId ? null : exportProvider.getById(exportId),
        productProvider.getAllProducts(true),
      ]);
      const productOptions: DropdownItemProps[] = products.map((x) => ({
        key: x.id,
        value: x.id,
        text: `${x.code} - ${x.name} - ${x.quantity || 0} ${x.unit}`,
      }));
      const state: any = {
        productOptions,
        isLoading: false,
      };
      if (exportModel) {
        const records = exportModel.exportDetails.map((info) => {
          const product = products.find((x) => x.id === info.productId);
          return {
            unitAmount: info.unitAmount,
            quantity: info.quantity,
            productId: info.productId,
            productSellAmount: product?.sellAmount,
            productName: product?.name,
            productUnit: product?.unit,
          };
        });

        state.records = records;
        state.notes = exportModel.notes || '';
        state.isLoading = false;
        state.customerId = exportModel.customerId;
        state.customerName = exportModel.customer?.name || '';
        state.phone = exportModel.customer?.phone || '';
        state.email = exportModel.customer?.email || '';
        state.level = exportModel.customer?.level;
        state.address = exportModel.customer?.address || '';
        state.paidAmount = exportModel.paidAmount;
        state.totalAmount = exportModel.totalAmount;
        state.isFullPayment = exportModel.paidAmount === exportModel.totalAmount;
        state.createdDate = new Date(exportModel.createdDate || '');
        state.dueDate = new Date(exportModel.dueDate);
      }

      this.setState(state);
    } catch (error) {
      showAlert(`Tải thông tin hóa đơn thất bại. ${error}`, 'MessageBox');
      this.setState({ isLoading: false });
    }
  };

  private onKeyDown = (e: any) => {
    if (!e.currentTarget) {
      return;
    }

    const inputName = (e.currentTarget as HTMLInputElement).name;
    const rowIndex = parseInt(inputName.split('-')[1], 10);

    const { records } = this.state;
    if (rowIndex !== records.length - 1) {
      return;
    }

    if (e.key === 'Enter' || e.key === 'Tab') {
      records.push({ ...detailViewDefault });
      this.setState({ records });
      if (e.key === 'Enter') {
        e.preventDefault();
      }
    }
  };

  private handleAddClick = () => {
    const { records } = this.state;
    records.push({ ...detailViewDefault });
    this.forceUpdate();
  };

  private handleDeleteClick = (_: {}, data: ButtonProps) => {
    const rowIndex = parseInt(data.name.split('-')[1], 10);
    let { records } = this.state;
    records.splice(rowIndex, 1);
    if (records.length === 0) {
      records = [{ ...detailViewDefault }];
    }
    this.setState({ records }, () => this.reCalcTotalAmount());
  };

  private handleUnitAmountChange = (name: string, value?: number) => {
    const { records } = this.state;
    const rowIndex = parseInt(name.split('-')[1], 10);
    records[rowIndex].unitAmount = value;
    this.setState({ records }, () => this.reCalcTotalAmount());
  };

  private handleQuantityChange = (name: string, value?: number) => {
    const { records } = this.state;
    const rowIndex = parseInt(name.split('-')[1], 10);
    records[rowIndex].quantity = value;
    this.setState({ records }, () => this.reCalcTotalAmount());
  };

  private reCalcTotalAmount = () => {
    const { records, paidAmount, isFullPayment } = this.state;
    const totalAmount = records.reduce((t, x) => t + (x.quantity || 0) * (x.unitAmount || 0), 0);
    this.setState({
      totalAmount,
      paidAmount: isFullPayment ? totalAmount : paidAmount,
    })
  };

  private handleProductChange = async (
    _: {},
    data: DropdownProps
  ) => {
    const { records } = this.state;
    const productId = parseInt(data.value as string, 10);
    if (isNaN(productId) || productId <= 0) {
      return;
    }

    const allProducts = await productProvider.getAllProducts();
    const product = allProducts.find((x) => x.id === productId);
    if (product == null) {
      return;
    }

    const rowIndex = parseInt(data.name.split('-')[1], 10);
    records[rowIndex] = {
      id: productId,
      unitAmount: product.sellAmount || 0,
      quantity: records[rowIndex].quantity,
      productId: product.id,
      productName: product.name,
      productUnit: product.unit || '',
      productSellAmount: product.sellAmount || 0,
    };
    this.setState({ records }, () => this.reCalcTotalAmount());
  };

  private handleNoteChange = (_: {}, data: { name: string; value: string }) => {
    this.setState({ notes: data.value });
  };

  private handleDateChange = (value?: Date, name?: string) => {
    const state: any = {};
    if (name === 'dueDate') {
      state.dueDate = value;
    } else if (name === 'createdDate') {
      state.createdDate = value;
    }

    this.setState(state);
  };

  private handlePaymentChange = (name: string, value?: number) => {
    this.setState({ paidAmount: value || 0 })
  };

  private handleIsFullPaymentChange = (
    _: {},
    data: CheckboxProps // { name: string; checked: boolean }
  ) => {
    this.setState({ isFullPayment: data.checked || false, paidAmount: this.state.totalAmount });
  };

  private handleCustomerChange = (customerName: string) => {
    this.setState({ customerName });
  };

  private handleCustomerSelected = (model: CustomerModel | null) => {
    this.setState({
      customerId: model?.id || 0,
      customerName: model?.name || '',
      phone: model?.phone || '',
      email: model?.email || '',
      address: model?.address || '',
      level: model?.level
    });
  };

  private handleCancelClick = () => {
    this.props.history.push(route.export());
  };

  private handleSubmitForm = async () => {
    this.setState({ isSubmitting: true });
    try {
      const {
        exportId,
        records,
        customerId,
        totalAmount,
        notes,
        paidAmount,
        dueDate,
        createdDate,
      } = this.state;

      if (!customerId) {
        throw new Error(`Vui lòng nhập thông tin Khách Hàng.`);
      }

      let errorIndex = records.findIndex((x) => !x.productId);
      if (errorIndex > -1) {
        throw new Error(`Vui lòng chọn Sản Phẩm cho dòng: ${errorIndex + 1}.`);
      }

      errorIndex = records.findIndex((x) => !x.unitAmount || x.unitAmount < 1);
      if (errorIndex > -1) {
        throw new Error(`Vui lòng nhập Đơn Giá tại dòng: ${errorIndex + 1}.`);
      }

      errorIndex = records.findIndex((x) => !x.quantity || x.quantity < 1);
      if (errorIndex > -1) {
        throw new Error(`Vui lòng nhập Số Lượng tại dòng: ${errorIndex + 1}.`);
      }

      if (paidAmount == null || paidAmount.toString() === '') {
        throw new Error(`Vui lòng nhập Số Tiền Khách Trả.`);
      }

      const exportDetails: ExportDetail[] = records.map((rd) => ({
        unitAmount: rd.unitAmount,
        quantity: rd.quantity,
        productId: rd.productId,
      }));

      const exportModel: ExportModel = {
        id: exportId || 0,
        customerId,
        totalAmount: totalAmount || 0,
        paidAmount: paidAmount || 0,
        notes,
        exportDetails,
        status: 0,
        dueDate,
        createdDate,
      };

      const response = await exportProvider.editExport(exportModel);

      let pdfUrl;
      if (this.state.exportId) {
        showAlert('Cập nhật hóa đơn thành công!', 'MessageBox', 'green');
      } else {
        pdfUrl = exportProvider.getExportPdfLink(response.id);
      }
      this.setState({ exportId: response.id, isSubmitting: false, pdfUrl });
    } catch (error) {
      showAlert((error as Error).message, 'MessageBox', 'red');
      this.setState({ isSubmitting: false });
    }
  };

  private handlePrintClick = async () => {
    const { exportId } = this.state;
    if (!exportId) { return; }

    const exportPdfLink = exportProvider.getExportPdfLink(exportId);
    this.setState({ pdfUrl: exportPdfLink });
  };

  private handlePdfModelClose = () => {
    this.setState({ pdfUrl: undefined });
  }

  private handleAddNewCustomer = () => {
    this.setState({ isOpenNewCustomer: true, customerId: 0 });
  };

  private handleEditCustomer = () => {
    this.setState({ isOpenNewCustomer: true, customerId: this.state.customerId });
  };

  private handleNewCustomersDialogClose = () => {
    this.setState({ isOpenNewCustomer: false });
  };

  private handleCustomerDlgReturn = (customer: CustomerModel) => {
    this.setState({
      customerId: customer.id,
      customerName: customer.name || '',
      phone: customer.phone || '',
      email: customer.email || '',
      address: customer.address || '',
      level: customer.level,
      isOpenNewCustomer: false,
    });
  };
}

export const EditExport = withRouter(EditExportComponent);
