import { emitEvent, AppEvent } from "./events";
import { logger } from '.';


let currentStatusMessage: string | null = null;

/**
 * Set app status message.
 * @param statusMessage Status message. Pass null to hide.
 * @param disableInteraction Optional. Default is false. Pass true to disable user interaction.
 */
export function setAppStatus(statusMessage: string | null, disableInteraction = false) {
  if (currentStatusMessage !== statusMessage) {
    currentStatusMessage = statusMessage;
    if (statusMessage) {
      logger.info('[Status]', statusMessage);
    }
    emitEvent(AppEvent.StatusChange, { statusMessage, disableInteraction });
  }
}

/** Show an alert message. The message will be hidden automatically. */
export function showAlert(
  alertMessage: string,
  type: 'MessageBox' | '*' = '*',
  color: 'red' | 'green' | 'blue' | 'default' = 'red',
  title?: string
) {
  emitEvent(AppEvent.AlertSent, { alertMessage, color, type, title });
}