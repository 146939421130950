import { downloadPdfFile, fetchHelper } from 'src/app/fetch-helper';
import { apiEndPoint } from './api-endpoint';

class SaleFinalProductProvider {
  public async getById(id: number) {
    return await fetchHelper<SaleFinalProductModel>(`${apiEndPoint.saleFinalProduct}/${id}`, 'GET');
  }

  public async searchSaleFinalProducts(params: SaleFinalProductCriteria) {
    const cloneParams = { ...params };

    const response = await fetchHelper<GetSaleFinalProductsResp>(apiEndPoint.saleFinalProduct, 'GET', cloneParams);
    return response.items;
  }

  public async editSaleFinalProduct(body: SaleFinalProductModel): Promise<SaleFinalProductModel> {
    let response;
    if (!body.id) {
      response = await fetchHelper<SaleFinalProductModel>(apiEndPoint.saleFinalProduct, 'POST', body);
    } else {
      response = await fetchHelper<SaleFinalProductModel>(`${apiEndPoint.saleFinalProduct}/${body.id}`, 'PUT', body);
    }
    return response;
  }

  public async deleteSaleFinalProduct(id: number) {
    return await fetchHelper(`${apiEndPoint.saleFinalProduct}/${id}`, 'DELETE');
  }

  public async printBuyInvoice(id: number) {
    await downloadPdfFile(`${apiEndPoint.saleFinalProduct}/${id}/printf`);
  }
}

export const saleFinalProductProvider = new SaleFinalProductProvider();
