import { fetchHelper } from 'src/app/fetch-helper';
import { apiEndPoint } from './api-endpoint';

export const permissionProvider = {
  async getList() {
    const response = await fetchHelper<GetPermissionsResp>(apiEndPoint.permission, 'GET');
    return response.items;
  },

  getById(id: number) {
    return fetchHelper<PermissionModel>(`${apiEndPoint.permission}/${id}`, 'GET');
  },

  save(body: PermissionModel): Promise<PermissionModel> {
    if (!body.id || body.id === 0) {
      return fetchHelper<PermissionModel>(apiEndPoint.permission, 'POST', body);
    } else {
      return fetchHelper<PermissionModel>(`${apiEndPoint.permission}/${body.id}`, 'PUT', body)
    }
  },

  delete(id: number) {
    return fetchHelper<PermissionModel>(`${apiEndPoint.permission}/${id}`, 'DELETE');
  }
};
