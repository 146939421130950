import { IWfChartInstance, IWfChartSetting } from './common';
import { logger, emitEvent, AppEvent, i18n } from 'src/app';
import { IChartDataProvider } from './data-provider/chart-data-provider';
import { ProductRemainingDataProvider } from './data-provider/product-remaining';
import { AgriProductRemainingDataProvider } from './data-provider/agri-product-remaining';
import { userProfile } from 'src/app/user-profile';
import { ProductChangingDataProvider } from './data-provider/product-changing-in-month';
import { CustomerDebtDataProvider } from './data-provider/customer-debt';

class WFChartManager {
  private chartInstances: { [key: string]: IWfChartInstance } = {};
  private dataProviders: { [key: string]: IChartDataProvider } = {};
  private isInitialized: boolean = false;
  private currentIndex: number = 0;

  public forceInit() {
    this.isInitialized = false;
    this.init();
  }

  public addDataProvider(provider: IChartDataProvider) {
    this.dataProviders[provider.id] = provider;
  }

  public getDataProvider(dataProviderId: string): IChartDataProvider | undefined {
    const dataProvider = this.dataProviders[dataProviderId];
    if (dataProvider == null) {
      logger.warn(`[WfChartManager] data provider [${dataProviderId}] doesn't exist.`);
    }
    return dataProvider;
  }

  public getDataProviderByNameAndSection(chartName: string): IChartDataProvider | undefined {
    const [dataProvider] = Object.keys(this.dataProviders)
      .map(id => this.dataProviders[id])
      .filter(provider => provider.metadata.name === chartName || provider.id === chartName);

    return dataProvider;
  }

  public removeChartInstance(instanceId: string): void {
    delete this.chartInstances[instanceId];
    this.saveInstances();
  }

  public getChartInstance(instanceId: string): IWfChartInstance {
    this.init();
    let chartInstance = this.chartInstances[instanceId];
    if (chartInstance == null) {
      // if chart instance doesn't exist yet, create the instance of default settings
      // this case happen when new chart created.
      const newProvider = this.nextProvider;
      chartInstance = {
        id: instanceId,
        settings: newProvider.defaultSettings ? newProvider.defaultSettings : {
          chartType: newProvider.metadata.supportedChartTypes ?
            newProvider.metadata.supportedChartTypes[0] : 'horizontalBar',
          dataProviderId: newProvider.id,
          sortBy: 0,
        }
      };
      this.chartInstances[instanceId] = chartInstance;
    }
    return chartInstance;
  }

  public getDataProviders(): IChartDataProvider[] {
    return Object.keys(this.dataProviders).map(id => this.dataProviders[id]);
  }

  /** Save instance settings to userSession storage. */
  public saveInstances(layout?: any) {
    const newPanelData: any = {};
    Object.keys(this.chartInstances).forEach(panelId => {
      newPanelData[panelId] = this.chartInstances[panelId].settings;
    });
    userProfile.chartSettings = newPanelData;
    userProfile.layoutModel = layout;
  }

  /** Create a new chart panel, ready for user to move it across the flexlayout. */
  public createChartPanel() {
    const provider = this.nextProvider;
    const chartName = i18n.formatMessage({ id: `chartName.${provider.id}` });
    const unit = i18n.formatMessage({ id: `chartUnit.${provider.metadata.unitList[0]}` });
    emitEvent(AppEvent.AddPanel, { component: 'ChartPanel', chartName: `${chartName} (${unit})` });
  }

  /** Function add chart instance, return false if Instance is already exists. */
  public addChartInstance(panelId: string, chartSetting: IWfChartSetting): boolean {
    this.init();

    if (panelId in this.chartInstances) {
      return false;
    }

    try {
      this.chartInstances[panelId] = {
        id: panelId,
        settings: chartSetting
      };
      return true;
    }
    catch (error) {
      logger.error(`[wfChartManager] Can't add chart instance '${panelId}': ${logger.dumpObject(chartSetting)}`);
      return false;
    }
  }

  private get nextProvider(): IChartDataProvider {
    const providerKeys = Object.keys(this.dataProviders);
    const provider = this.dataProviders[providerKeys[this.currentIndex]];
    this.currentIndex += 1;
    if (this.currentIndex >= providerKeys.length) {
      this.currentIndex = 0;
    }
    return provider;
  }

  private init() {
    if (!this.isInitialized) {
      this.isInitialized = true;
      for (const panelId in userProfile.chartSettings) {
        if (Object.prototype.hasOwnProperty.call(userProfile.chartSettings, panelId)) {
          const element = userProfile.chartSettings[panelId];

          this.chartInstances[panelId] = {
            id: panelId,
            settings: element,
          };
        }
      }
    }
  }
}

const wfChartManager = new WFChartManager();
wfChartManager.addDataProvider(ProductRemainingDataProvider);
wfChartManager.addDataProvider(CustomerDebtDataProvider);
wfChartManager.addDataProvider(ProductChangingDataProvider);
wfChartManager.addDataProvider(AgriProductRemainingDataProvider);

export { wfChartManager };