import { fetchHelper } from 'src/app/fetch-helper';
import { apiEndPoint } from './api-endpoint';

class FinalProductProvider {
  public getById(id: number): Promise<FinalProductModel> {
    return fetchHelper<FinalProductModel>(`${apiEndPoint.finalProduct}/${id}`, 'GET');
  }

  public async getAllFinalProducts(): Promise<FinalProductModel[]> {
    const res = await fetchHelper<GetFinalProductsResp>(apiEndPoint.finalProduct, 'GET', { size: 10_000 });
    return res.items;
  }

  public async editFinalProducts(body: FinalProductModel): Promise<FinalProductModel> {
    let response: FinalProductModel | undefined;
    if (!body.id) {
      response = await fetchHelper<FinalProductModel>(apiEndPoint.finalProduct, 'POST', body);
    } else {
      response = await fetchHelper<FinalProductModel>(`${apiEndPoint.finalProduct}/${body.id}`, 'PUT', body)
    }
    return response;
  }

  public async deleteFinalProducts(finalProductId: number) {
    await fetchHelper(`${apiEndPoint.finalProduct}/${finalProductId}`, 'DELETE');
  }
}
export const finalProductProvider = new FinalProductProvider();