import * as React from 'react';
import { RouteComponentProps, Redirect } from 'react-router';
import { i18n, userSession, appInfo, logger } from '../app';
import { userLogin } from '../app/auth';
import { InjectedFormikProps, withFormik, ErrorMessage, Field } from 'formik'
import { defineMessages, FormattedMessage } from 'react-intl';
import * as Yup from 'yup';
import classNames from 'classnames';
import { Button, Divider, Label, Form } from 'semantic-ui-react';
import { route } from 'src/app/route';
import { debounce } from 'lodash';
import { AutoFocus } from 'src/components/auto-focus';

const messages = defineMessages({
  title: {
    id: 'loginForm.title',
    defaultMessage: 'Đăng Nhập',
  },
  versionInfo: {
    id: 'loginForm.versionInfo',
    defaultMessage: 'Phiên Bản {version}',
  },
  usernameLabel: {
    id: 'loginForm.usernameLabel',
    defaultMessage: 'Tên Đăng Nhập',
  },
  passwordLabel: {
    id: 'loginForm.passwordLabel',
    defaultMessage: 'Mật Khẩu',
  },
  loginButton: {
    id: 'loginForm.loginButton',
    defaultMessage: 'Đăng Nhập',
  },
  requireUsername: {
    id: 'loginForm.requireUsername',
    defaultMessage: 'Vui lòng nhập tên đăng nhập.',
    description: 'Validation error message for username.'
  },
  requirePassword: {
    id: 'loginForm.requirePassword',
    defaultMessage: 'Vui lòng nhập mật khẩu.',
    description: 'Validation error message for password.'
  },
});

const styles: { [key: string]: React.CSSProperties } = {
  footer: {
    textAlign: 'center',
  },
  version: {
    display: 'block',
    textAlign: 'center',
    opacity: 0.75,
    margin: '1em 0 -1em 0',
    fontStyle: 'normal',
    fontSize: '0.85rem',
  }
};

const LoginForm = (props: InjectedFormikProps<ILoginFormProps, ILoginFormValues>) => (
  <>
    <div className='x-login-form'>
      {/* <img className='x-login-form__Logo' src={`${process.env.PUBLIC_URL}/images/logo.png`} /> */}
      <div className='x-login-form__Content'>
        <Form onSubmit={debounce(() => props.handleSubmit())}>
          {userSession.isAuthenticated && <Redirect to={route.getFirstPage(userSession) || route.home()} />}
          <h2><FormattedMessage {...messages.title} /></h2>

          <Form.Field>
            <label htmlFor='loginForm_username'>
              <FormattedMessage {...messages.usernameLabel} />
            </label>
            <Field name='username' id='loginForm_username' type='text' value={props.values.username} disabled={props.isSubmitting}
              placeholder={i18n.formatMessage(messages.usernameLabel)}
              className={classNames({ error: props.errors.username && props.touched.username })} />
            <AutoFocus elementId='loginForm_username' />

          </Form.Field>

          <Form.Field>
            <label htmlFor='loginForm_password'>
              <FormattedMessage {...messages.passwordLabel} />
            </label>
            <Field name='password' id='loginForm_password' type='password' value={props.values.password}
              placeholder={i18n.formatMessage(messages.passwordLabel)}
              disabled={props.isSubmitting}
              className={classNames({ error: props.errors.password && props.touched.password })} />
          </Form.Field>

          <div style={{ height: 26 }}>
            <Label basic={true} color='red'><ErrorMessage name='username' /></Label>
            <Label basic={true} color='red'><ErrorMessage name='password' /></Label>
            {
              props.status && props.status.error && <Label basic={true} color='red'>{props.status.error}</Label>
            }
          </div>

          <Divider />

          <div style={styles.footer}>
            <Button primary={true} type='submit' disabled={props.isSubmitting} loading={props.isSubmitting}>
              <FormattedMessage {...messages.loginButton} />
            </Button>
          </div>
          <i style={styles.version}><FormattedMessage {...messages.versionInfo} values={{ version: appInfo.version }} /></i>
        </Form>
      </div>
    </div>
  </>
);

type ILoginFormProps = RouteComponentProps;

interface ILoginFormValues {
  username: string;
  password: string;
}

const LoginPage = withFormik<ILoginFormProps, ILoginFormValues>({
  mapPropsToValues: () => {
    try {
      logger.info('Clear session...');
      sessionStorage.clear();
      localStorage.clear();
    } catch (ex) {
      // skip
    }
    userSession.load();
    return { username: '', password: '' };
  },
  validationSchema: (props: ILoginFormProps) => Yup.object<ILoginFormValues>().shape({
    username: Yup.string().required(i18n.formatMessage(messages.requireUsername)),
    password: Yup.string().required(i18n.formatMessage(messages.requirePassword)),
  }),
  handleSubmit: async (values, { setSubmitting, setStatus, props }) => {
    setStatus({});
    const loginResult = await userLogin(values.username, values.password);

    if (!loginResult.isSucceeded) {
      setSubmitting(false);
      setStatus({ error: loginResult.errorMessage });
    } else {
      props.history.push(route.getFirstPage(userSession) || route.home() || '/');
    }
  }
})(LoginForm);

export default LoginPage;