import 'moment/locale/vi';
import * as React from 'react';
import * as ReactDateTime from 'react-datetime';
import * as moment from 'moment';
import { FormikProps } from 'formik';
import { i18n } from 'src/app';
import { Input, Icon } from 'semantic-ui-react';
import 'src/style/react-datetime.scss';
import ReactDatetimeClass from 'react-datetime';

interface IDateTimePickerProps {
  value?: Date;
  defaultValue?: Date;

  width?: number,
  placeholder?: string;

  /** Form field name. Required if use for connect with Formik. */
  name?: string,

  /** Pass Formik props into this attribute to connect the component with formik events. */
  formikProps?: FormikProps<any>;

  /** Set to true to disable DateTimePicker. */
  disabled?: boolean;

  display?: 'date' | 'time' | 'both';

  onChange?: (newValue: Date | undefined, name?: string, currentState?: any) => void;

  /** [Optional] Semantic-ui icon name */
  icon?: string;

  timeFormat?: boolean | string;

  validateDate?: (value: Date) => boolean;

  readOnly?: boolean;

  className?: string;
}

export class DateTimePicker extends React.PureComponent<IDateTimePickerProps> {
  constructor(props: IDateTimePickerProps) {
    super(props);
  }

  public render() {
    const locale = i18n.locale;
    const { value, defaultValue, placeholder, disabled, width, readOnly, className } = this.props;
    let dateFormat: boolean | string = false;
    let timeFormat: boolean | string = false;

    if (this.props.display === 'date') {
      dateFormat = true; // 'DD/MM/YYYY';
    }
    else if (this.props.display === 'time') {
      timeFormat = true;
    }
    else {
      dateFormat = true;
      timeFormat = true;
    }

    if (this.props.timeFormat != null) {
      timeFormat = this.props.timeFormat;
    }

    const props: ReactDateTime.DatetimepickerProps & { renderInput: any } = {
      className,
      locale,
      dateFormat,
      timeFormat,
      value,
      defaultValue,
      onChange: this.handleChange,
      inputProps: { placeholder, disabled, width, readOnly },
      renderInput: this.renderInput,
      closeOnSelect: true,
      renderMonth: this.renderMonth
    };

    if (this.props.validateDate != null) {
      props.isValidDate = this.validateDate;
    }

    return <ReactDatetimeClass {...props} timeConstraints={{ minutes: { step: 5, min: 0, max: 59 } }} />;
  }

  private validateDate = (currentDate: moment.Moment, selectedDate: moment.Moment): boolean => (
    this.props.validateDate!(currentDate.toDate())
  );

  private renderInput = (props: React.HTMLProps<HTMLInputElement>, openCalendar: any, closeCalendar: any) => {
    let iconName: string | null;
    if (this.props.icon == null) {
      iconName = 'calendar alternate';
    }
    else if (this.props.icon === '') {
      iconName = null;
    }
    else {
      iconName = this.props.icon;
    }

    return (
      <Input icon={iconName != null}>
        {iconName != null && <Icon name={iconName as any} />}
        <input {...props} />
      </Input>
    );
  }

  private handleChange = (value: string | moment.Moment) => {
    const { onChange, formikProps, name } = this.props;

    if (onChange == null && formikProps == null) { return; }

    let newValue: Date | undefined;
    if (value != null) {
      if (typeof (value) === 'string') {
        if (value !== '') {
          newValue = new Date(value);
          if (isNaN(newValue.getTime())) {
            newValue = undefined;
          }
        }
      }
      else {
        newValue = value.toDate();
      }
    }

    if (name != null && formikProps != null) {
      formikProps.setFieldValue(name, newValue, true);
    }

    if (onChange != null && name) {
      onChange(newValue, name, { ...formikProps?.values, [name]: newValue });
    }
  }

  // Custom renderMonth because default renderMonth will trim 3 character in MonthShort
  private renderMonth = (monthProps: any, monthIndex: number) => {
    const localeData = moment.localeData();
    const monthStr = localeData.monthsShort()[monthIndex];
    return React.createElement('td', monthProps, monthStr);
  }
}
