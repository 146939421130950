import { fetchHelper } from 'src/app/fetch-helper';
import { apiEndPoint } from './api-endpoint';

export enum DayOffStatus {
  PENDING = 0,
  APPROVED = 1,
  DENIED = 2,
  CANCELLED = 3,
}

class DayOffProvider {
  public getById(id: number): Promise<DayOffModel> {
    return fetchHelper<DayOffModel>(`${apiEndPoint.dayOff}/${id}`, 'GET');
  }

  public async getLists(params: {}): Promise<DayOffModel[]> {
    const resp = await fetchHelper<GetDayOffResp>(apiEndPoint.dayOff, 'GET', params);
    return resp.items;
  }

  public async edit(body: DayOffModel): Promise<DayOffModel> {
    let response: DayOffModel | undefined;
    if (!body.id || body.id === 0) {
      response = await fetchHelper<DayOffModel>(apiEndPoint.dayOff, 'POST', body);
    } else {
      response = await fetchHelper<DayOffModel>(`${apiEndPoint.dayOff}/${body.id}`, 'PUT', body)
    }
    return response;
  }

  public async delete(productId: number) {
    await fetchHelper(`${apiEndPoint.dayOff}/${productId}`, 'DELETE');
  }
}
export const dayOffProvider = new DayOffProvider();