import 'flexlayout-react/style/light.css';
import 'semantic-ui-css/semantic.min.css';
import './semantic-ui.scss';
import './splitter-layout.scss';
import './flexlayout.scss';
import './app.scss';

export default class Style {
    public static readonly SELECTED_TABLE_ROW_BACKGROUND = '#F00';
    public static readonly TABLE_ROW_HEIGHT = 35;
    public static readonly TABLE_CELL_PADDING = '0.2em 0';
}