import React, { Suspense, lazy } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Segment, Sidebar } from 'semantic-ui-react';
import MenuBar from './menu-bar';
import { route } from 'src/app/route';
import { parseNumber } from 'src/utils';
import { HeaderBar } from 'src/components/header-bar';
const EditSaleAgriProduct = lazy(() => import('src/components/sale-agri-product/edit-sale-agri-product'));

interface IEditSaleAgriProductParams {
  saleAgriProductId?: string;
}

export class EditSaleAgriProductPage extends React.PureComponent<
  RouteComponentProps<IEditSaleAgriProductParams, {}>
> {
  public componentDidMount() {
    document.title = route.getPageTitle(this.props.location.pathname);
  }

  public render() {
    const saleAgriProductId = parseNumber(
      this.props.match.params.saleAgriProductId
    );
    const title = route.getPageTitle(this.props.location.pathname);

    return (
      <Sidebar.Pushable as={Segment} className='x-page'>
        <MenuBar />

        <Sidebar.Pusher>
          <Segment basic={true}>
            <HeaderBar title={title} />

            <Suspense fallback={<div className='x-loading' />}>
              <EditSaleAgriProduct saleAgriProductId={saleAgriProductId} />
            </Suspense>
          </Segment>
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    );
  }
}
