import { userSession } from 'src/app';
import { downloadPdfFile, fetchHelper } from 'src/app/fetch-helper';
import { apiEndPoint } from './api-endpoint';

export enum BuyAgriProductStatus {
  IN_PROGRESS = 0,
  DONE = 1,
}

export enum BuyAgriProductType {
  AGENT = 0, // Hợp Đồng
  PERSONAL = 1, // Mua Lẻ
}

class BuyAgriProductProvider {
  public getById(id: number) {
    return fetchHelper<BuyAgriProductModel>(`${apiEndPoint.buyAgriProduct}/${id}`, 'GET');
  }

  public async searchBuyAgriProducts(params: BuyAgriProductCriteria) {
    const cloneParams = { ...params };

    const response = await fetchHelper<GetBuyAgriProductsResp>(apiEndPoint.buyAgriProduct, 'GET', cloneParams);
    return response.items;
  }

  public editBuyAgriProduct(body: BuyAgriProductModel & { payments?: PaymentModel[] }): Promise<BuyAgriProductModel> {
    if (!body.id) {
      return fetchHelper<BuyAgriProductModel>(apiEndPoint.buyAgriProduct, 'POST', body);
    } else {
      return fetchHelper<BuyAgriProductModel>(`${apiEndPoint.buyAgriProduct}/${body.id}`, 'PUT', body);
    }
  }

  public delete(id: number) {
    return fetchHelper(`${apiEndPoint.buyAgriProduct}/${id}`, 'DELETE');
  }

  public printBuyInvoice(id: number) {
    return downloadPdfFile(`${apiEndPoint.buyAgriProduct}/${id}/printf`);
  }

  public getEditBuyAgriPdfLink(id: number) {
    return `${apiEndPoint.exportPdf}/buy-agri-product/${id}?p=${userSession.userIdentityToken}`;
  }

  public createTransaction(buyAgriProductId: number, body: {}) {
    return fetchHelper(`${apiEndPoint.buyAgriProduct}/${buyAgriProductId}/transaction`, 'POST', body);
  }

  public createPayment(buyAgriProductId: number, body: {}) {
    return fetchHelper(`${apiEndPoint.buyAgriProduct}/${buyAgriProductId}/payment`, 'POST', body);
  }

  public complete(buyAgriProductId: number, body: {}) {
    return fetchHelper(`${apiEndPoint.buyAgriProduct}/${buyAgriProductId}/complete`, 'POST', body);
  }

  public acceptPrice(body: any) {
    return fetchHelper(`${apiEndPoint.buyAgriProduct}/accept-price`, 'POST', body);
  }

  public async getBuyHistory(params: any) {
    const { items } = await fetchHelper<GetBuyAgriProductHistoryResp>(`${apiEndPoint.buyAgriProduct}/history`, 'GET', params);
    return items;
  }

  public syncPayment(id: number) {
    return fetchHelper(`${apiEndPoint.buyAgriProduct}/${id}/sync-payment`, 'POST');
  }
}

export const buyAgriProductProvider = new BuyAgriProductProvider();
