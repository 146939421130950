import * as React from "react";
// import * as Autocomplete from "react-autocomplete";
import Autocomplete from "react-autocomplete";
import classNames from 'classnames';
import { FormikProps } from 'formik';
import { Button } from "semantic-ui-react";
import { customerProvider, CustomerLevel } from 'src/api';

interface SearchCustomerProps {
  name: string;
  value?: string | number;
  formikProps?: FormikProps<any>;
  searchParams?: CustomerCriteria | null;
  onSelectCustomer?: (customer: CustomerModel | null, values: any) => void;
  onChange?: (value: string) => void;
}

interface SearchCustomerState {
  items: CustomerModel[];
}

export class SearchCustomer extends React.Component<SearchCustomerProps, SearchCustomerState>{
  constructor(props: SearchCustomerProps) {
    super(props);
    this.state = { items: [] }
  }

  public render() {
    const { items } = this.state;
    const { value } = this.props;
    return (
      <div className='x-search-customer'>
        <Autocomplete
          inputProps={{ placeholder: 'Tìm Khách Hàng' }}
          wrapperStyle={{ display: 'block' }}
          wrapperProps={{ className: 'x-search-customer-wrapper' }}
          items={items}
          value={value}
          getItemValue={this.handleGetItemValue}
          renderItem={this.renderInput}
          onChange={this.handleOnChange}
          onSelect={this.handleOnSelect}
          renderMenu={this.handleRenderMenu}
        />
        {value &&
          <Button
            icon='close'
            className='x-clear-customer'
            size='tiny'
            onClick={this.handleClearClick}
          />
        }
      </div>
    );
  }

  private handleRenderMenu = (
    items: React.ReactNode[],
    value: string,
    styles: React.CSSProperties,
  ) => {
    return <div className='x-search-customer-item-wrapper' style={styles} children={items} />
  };

  private handleGetItemValue = (item: CustomerModel) => `${item.name} - ${item.phone || ''} - ${item.level === CustomerLevel.Agency ? 'Đại Lý' : 'Khách Lẻ'}`;

  private renderInput = (item: CustomerModel, isHighLighted: boolean) => {
    const items = [
      item.name,
      item.phone,
      item.debtBookNo,
    ];
    return (
      <div key={item.id} className={classNames('x-search-customer-item', { 'active': isHighLighted })}>
        <span>{items.filter(x => !!x).join(' - ')}</span><br />
        <span>{item.address}</span>
      </div>
    );
  }

  private handleOnChange = async (e: {}, value: string) => {
    const { name, formikProps, onChange, searchParams, onSelectCustomer } = this.props;
    if (formikProps) {
      formikProps.setFieldValue(name, value);
    }
    if (onChange) {
      onChange(value);
    }

    if (value.length > 1) {
      const customers = await customerProvider.searchTextCustomers({ ...searchParams, search: value });
      this.setState({ items: customers });
    } else {
      if (this.state.items.length > 0) {
        this.setState({ items: [] });
      }
      if (onChange == null && onSelectCustomer != null) {
        onSelectCustomer(null, formikProps?.values);
      }
    }
  }

  private handleClearClick = () => {
    this.handleOnChange({}, '');
  }

  private handleOnSelect = (value: string, item: CustomerModel) => {
    const { name, formikProps, onSelectCustomer } = this.props;

    if (formikProps) {
      formikProps.setFieldValue(name, item.name);
    }

    if (onSelectCustomer) {
      onSelectCustomer(item, formikProps?.values);
    }
  }
}