import { emitEvent, AppEvent } from "./events";
import { cloneDeep } from "lodash";
import { appConfig, UIStates } from "./config";
import {
  setLocalStorageItem,
  getLocalStorageItem,
} from "./session-storage";
import { appInfo } from ".";

interface UserSessionData {
  userIdentityToken: string | null;
  userId?: number | null;
  username: string | null;
  uiStates: UIStates;
  displayName: string | null;
  email: string | null;
  roleName: string | null;
  permissions: Array<{ name: string, path: string }>;
  stores: StoreModel[];
}

const emptyUserSessionData: UserSessionData = {
  userIdentityToken: null,
  username: null,
  email: null,
  displayName: null,
  roleName: null,
  permissions: [],
  stores: [],
  uiStates: {
    locale: "vi",
    autoRefresh: false,
    autoRefreshInterval: 0,
  },
};
export class UserSession {
  private oldData?: UserSessionData;
  private data: UserSessionData = emptyUserSessionData;
  private isInitialized: boolean = false;

  public get userIdentityToken(): string | null {
    return this.data.userIdentityToken;
  }

  public set userIdentityToken(value: string | null) {
    this.data.userIdentityToken = value;
  }

  public get permissions() {
    return this.data.permissions;
  }

  public set permissions(value: Array<{ name: string, path: string }>) {
    this.data.permissions = value.map(x => (
      {
        name: x.name,
        path: x.path.replace('/api', ''),
      }
    ));
  }

  public get userId() {
    return this.data.userId || 0;
  }

  public set userId(value: number) {
    this.data.userId = value;
  }

  public get username() {
    return this.data.username || '';
  }

  public set username(value: string) {
    this.data.username = value;
  }

  public get isSupperAdmin() {
    return this.data.username === 'admin';
  }

  public get isAdmin() {
    return this.data.username === 'admin' || this.data.roleName === 'admin' || this.data.roleName === 'administrator';
  }

  public get displayName() {
    return this.data.displayName || '';
  }

  public set displayName(value: string) {
    this.data.displayName = value;
  }

  public get roleName() {
    return this.data.roleName || '';
  }

  public set roleName(value: string) {
    this.data.roleName = value;
  }

  public get email() {
    return this.data.email;
  }

  public set email(value: string | null) {
    this.data.email = value;
  }

  public get isAuthenticated(): boolean {
    return this.isInitialized && this.userIdentityToken != null;
  }

  public get stores(): StoreModel[] {
    return this.data.stores;
  }

  public set stores(value: StoreModel[]) {
    this.data.stores = value;
  }

  public get pickUpStoreId() {
    const s = this.data.stores.find(x => x.isPriorityPick);
    if (s) {
      return s.id;
    }

    return this.data.stores[0].id
  }

  public get personalStoreId() {
    const s = this.data.stores.find(x => x.isPersonal);
    if (s) {
      return s.id;
    }

    return this.data.stores[0].id
  }

  public hasPermission(pattern: RegExp) {
    return this.isAdmin || this.permissions.some(x => pattern.test(x.path));
  }

  /**
   * Init method will be called each time userSession restored from sessionStorage or new user login.
   */
  public async init() {
    if (!this.data.uiStates) {
      await appConfig.load(true);
      this.data.uiStates = Object.assign({}, appConfig.defaultUiStates);
    }

    if (
      !this.userIdentityToken ||
      this.data == null ||
      this.data.username == null
    ) {
      throw new Error(
        `Application init failure. Missing Access Token or Login User`
      );
    }

    emitEvent(AppEvent.UserLogin);
    this.isInitialized = true;
    return; // init success
  }

  public get uiStates() {
    return this.data.uiStates;
  }

  /**
   * Clear all user session data. NO NEED to call save(), this clear method will save.
   */
  public clear() {
    this.oldData = undefined;
    this.data = cloneDeep(emptyUserSessionData);
    this.save();
  }

  /** Save current user session data to sessionStorage */
  public save() {
    const newData = this.data.userIdentityToken ? this.data : null;
    if (newData != null) {
      this.detectDataChange(this.oldData, newData);
    }

    setLocalStorageItem(`${appInfo.ename}.userSession`, newData);

    this.oldData = cloneDeep(newData) || undefined;
  }

  /** Load userSession from sessionStorage
   * @returns true if there is an session stored in sessionStorage.
   */
  public async load() {
    const data = getLocalStorageItem(
      `${appInfo.ename}.userSession`
    ) as UserSessionData;

    if (data == null || data.userIdentityToken == null || data.username == null) {
      this.data = cloneDeep(emptyUserSessionData);
    } else {
      this.data = data;
      try {
        await this.init();
      } catch (error) {
        this.data = cloneDeep(emptyUserSessionData);
        this.save();
        // todo throw error if init fail.
      }
    }
  }

  // return true if any data change
  private detectDataChange(
    oldData: UserSessionData | undefined,
    newData: UserSessionData
  ) {
    if (
      oldData == null ||
      oldData.uiStates.autoRefresh !== newData.uiStates.autoRefresh
    ) {
      emitEvent(AppEvent.AutoRefreshToggle, newData.uiStates.autoRefresh);
    }
  }
}

export const userSession = new UserSession();
