
import 'fixed-data-table-2/dist/fixed-data-table.css';
import './data-table.scss';
import Style from '../style';
import * as React from 'react';
import { Table, Column, Cell, ColumnCellProps, ColumnHeaderProps } from 'fixed-data-table-2';
import classNames from 'classnames';
import { logger } from 'src/app/logger';
import { debounce, isEqual } from 'lodash';

const COLUMN_MIN_WIDTH = 30; // minimum column width (in pixels)

export interface IDataTableColumn {
  /**
   * Data column default width.
   * Pass number (greater than zero). Eg: `120`
   * or
   * fix width in pixels. Eg: `"80px"`
   */
  defaultWidth?: number | string;

  /**
   * Data column width.
   * Pass number (greater than zero). Eg: `120`
   * or
   * fix width in pixels. Eg: `"80px"`
   */
  width?: number | string;

  /** Max width in pixels. */
  maxWidth?: number;

  /** Min width in pixels */
  minWidth?: number;

  /**
   * Attach the column to `left` or `right`
   */
  attachTo?: 'left' | 'right';

  /** Column header text */
  text: string;

  cellClass?: string;

  headerAlign?: 'left' | 'right' | 'center';
 
  /** Callback method for DataTable to get data of each cell. */
  getCellData?: (rowData: any, columnIndex: number) => React.ReactNode;

  isDesc?: boolean;
}

export interface IDataTableGroup {
  groupId: string;
  groupHeader: React.ReactNode;
}

interface IDataTableProps {
  className?: string;

  width?: number;
  height?: number;

  /** Pass undefined if table data rows not loaded yet.  In this case a loading spinner shown.
   * Pass empty array to show empty data table with column headers only. A message will show if noRowsMessage has a value.
   * Pass none-empty array to show fully functinal data table with rows.
   */
  rows?: any[];

  /** Optional footer row. */
  footerRow?: any;

  /** Specify height of row (in pixel). Default is 30 */
  rowHeight?: number;

  /** Specify height of header row (in pixel). Default is 30. Set to 0 to hide the header */
  headerHeight?: number;

  /** Specify height of footer row (in pixel). Default is 30. Set to 0 to hide the footer */
  footerHeight?: number;

  columns: IDataTableColumn[];

  selectedRowIndexes?: number[];
  /**
   * Allow multi select. Default is `false`
   */
  multiSelect?: boolean;

  rowGroups?: IDataTableGroup[];

  /** Callback method for DataTable to get groupId of each row. */
  getRowGroup?: (rowData: any, rowIndex: number) => string;

  /** Display message when rows.length === 0 */
  noRowsMessage?: string | (() => React.ReactNode);

  /** NOTICE: double-click and click event is not allowed to handle at the same time. */
  onRowClick?: (rowIndex: number) => void;

  /** NOTICE: double-click and click event is not allowed to handle at the same time. */
  onRowDoubleClick?: (rowIndex: number) => void;

  onRowSelect?: (rowIndexes: number[]) => void;

  /** Callback method for Column Resize
   * if this function is exists, parent component have to change width in props.columns and re-render to apply new width.
   */
  onColumnResize?: (columnWidths: number[]) => void;
}

interface IDataTableColumnState {
  width: number;
  dataKey: string;
  fixWidth?: number;
  props: IDataTableColumn;
}

interface IDataTableState {
  width?: number;
  height?: number;
}

function parseColumnWidth(defaultWidth: number | string | undefined, width: number | string | undefined): { width: number, fixWidth?: number } {
  if (width != null) {
    if (typeof (width) === "string") {
      const fixWidth = parseInt(width.replace('px', ''), 10);
      return { width: fixWidth, fixWidth };
    }
    else {
      return { width };
    }
  }

  if (defaultWidth === undefined) {
    return { width: 1 };
  }
  else if (typeof (defaultWidth) === "string") {
    const fixWidth = parseInt(defaultWidth.replace('px', ''), 10);
    return { width: fixWidth, fixWidth };
  }
  else {
    return { width: defaultWidth };
  }
}

function clearTextSelection() {
  if (window.getSelection) {
    const selection = window.getSelection();
    if (selection && selection.empty) {  // Chrome
      selection.empty();
    }
    else if (selection && selection.removeAllRanges) {  // Firefox
      selection.removeAllRanges();
    }
  }
  else if ((document as any).selection) {  // IE?
    (document as any).selection.empty();
  }
}


interface ITableRowWrapper {
  groupInfo: IDataTableGroup; // only available for DataTable has groups
  rowIndex?: number; // original row index, only available for normal row
  rows?: ITableRowWrapper[]; // only available for header row of a group
}

function findLastIndex(arr: any[], predicate: (x: any) => boolean): number {
  for (let i = arr.length - 1; i >= 0; --i) {
    if (predicate(arr[i]) === true) {
      return i;
    }
  }
  return -1;
}

function calculateColumnWidth(columns: IDataTableColumnState[], tableWidth: number) {
  if (isNaN(tableWidth) || !isFinite(tableWidth) || tableWidth <= 0) {
    return;
  }

  let totalFixWidth = 0;
  const dynamicWidthColumns: IDataTableColumnState[] = [];
  columns.forEach(col => {
    if (col.fixWidth != null) {
      totalFixWidth += col.fixWidth;
    }
    else {
      dynamicWidthColumns.push(col);
    }
  });

  if (dynamicWidthColumns.length > 0) {
    const SCROLLBAR_WIDTH = 16;
    const W = Math.max(tableWidth - totalFixWidth - SCROLLBAR_WIDTH, 200);
    const totalWeight = dynamicWidthColumns.reduce((total, col) => total + col.width, 0);
    dynamicWidthColumns.forEach(col => {
      col.width = Math.floor(W * (col.width / totalWeight));

      const minWidth = col.props.minWidth || COLUMN_MIN_WIDTH;
      if (col.width < minWidth) {
        col.width = minWidth;
      }
    });
  }
}

export default class DataTable extends React.PureComponent<IDataTableProps, IDataTableState> {
  // iframe for listening resize event
  private iframeRef: React.RefObject<HTMLIFrameElement>;
  private currentColumns?: IDataTableColumn[];
  private columnStates?: IDataTableColumnState[];
  private currentRows: any[] = [];
  private currentRowWrappers: ITableRowWrapper[] = [];
  private readonly autoSizeMode: boolean;
  private customResizeMade: boolean = false;

  constructor(props: IDataTableProps) {
    super(props);
    this.autoSizeMode = !props.width && !props.height;
    this.currentColumns = props.columns;
    this.state = {
      width: props.width,
      height: props.height,
    };

    this.iframeRef = React.createRef();
  }

  public render() {
    const {
      loading,
      rowHeight,
      headerHeight,
      footerHeight,
      tableWidth,
      tableHeight,
      rowCount,
      columnStates,
      handleRowClick,
      handleRowDoubleClick,
      handleRowMouseDown,
      noRowsRenderer
    } = this.prepareTable();

    return (
      <div className={classNames(this.props.className, 'x-datatable', { 'x-datatable__autoSize': this.autoSizeMode, 'x-loading': loading })}>
        {this.autoSizeMode &&
          <iframe ref={this.iframeRef} />
        }
        <Table
          showScrollbarY={true}
          overflowX='auto'
          width={tableWidth}
          height={tableHeight}
          footerHeight={footerHeight}
          rowHeight={rowHeight}
          rowsCount={rowCount}
          headerHeight={headerHeight}
          rowClassNameGetter={this.tableRowClassNameGetter}
          isColumnResizing={false}
          onColumnResizeEndCallback={this.handleColumnResizeEndCallback}
          onRowClick={handleRowClick}
          onRowDoubleClick={handleRowDoubleClick}
          onRowMouseDown={handleRowMouseDown}
        >
          {columnStates.map(x => this.renderColumn(x))}
        </Table>
        {this.currentRows != null && this.currentRows.length === 0 && noRowsRenderer != null &&
          noRowsRenderer()
        }
      </div>
    );
  }

  public componentDidMount() {
    if (this.autoSizeMode) {
      if (this.iframeRef.current && this.iframeRef.current.contentWindow) {
        this.iframeRef.current.contentWindow.addEventListener('resize', debounce(this.handleIframeResize, 10));
      }
      window.setTimeout(this.handleIframeResize, 100);
    }
  }

  private handleIframeResize = () => {
    if (this.iframeRef.current && this.iframeRef.current.contentWindow && this.columnStates != null) {
      const iframeWnd = this.iframeRef.current.contentWindow;
      const newSize = { width: Math.floor(iframeWnd.innerWidth), height: Math.floor(iframeWnd.innerHeight) };
      if (this.state.width !== newSize.width || this.state.height !== newSize.height) {
        if (!this.customResizeMade) {
          calculateColumnWidth(this.columnStates, newSize.width);
        }
        this.setState({ ...newSize });
      }
    }
  }

  private handleColumnResizeEndCallback = (newColumnWidth: number, columnKey: string) => {
    if (this.columnStates == null) {
      return;
    }
    const columnIndex = parseInt(columnKey, 10);
    const column = this.columnStates[columnIndex];
    column.width = newColumnWidth;
    this.customResizeMade = true;

    if (this.props.onColumnResize != null) {
      const columnWidths = this.columnStates.map(x => x.width);
      this.props.onColumnResize(columnWidths);
    }
    else {
      this.forceUpdate();
    }
  }

  private renderColumn = ({ dataKey, props, width, fixWidth }: IDataTableColumnState) => {
    const footerRenderer = this.props.footerRow == null ? undefined : this.renderFooterCell;
    return (
      <Column
        key={dataKey}
        columnKey={dataKey}
        width={width}
        isResizable={fixWidth == null}
        footer={footerRenderer}
        minWidth={props.minWidth || 20}
        maxWidth={props.maxWidth}
        fixed={props.attachTo === 'left'}
        fixedRight={props.attachTo === 'right'}
        header={<Cell>{props.text}</Cell>}
        cell={this.renderCell}
        align={props.headerAlign}
      />
    );
  }

  private renderCell = ({ rowIndex, columnKey }: ColumnCellProps) => {
    const columnIndex = parseInt(columnKey || '0', 10);
    const column = this.props.columns[columnIndex];
    const rowWrapper = this.currentRowWrappers[rowIndex];
    const originalRowData = rowWrapper.rowIndex == null ? undefined : this.currentRows[rowWrapper.rowIndex];
    const isGroupHeaderRow = rowWrapper.groupInfo != null && rowWrapper.rows != null;
    const isGroupHeaderCell = isGroupHeaderRow && columnKey === '0';
    if (column.text === '#' && column.getCellData == null) {
      return (
        <Cell className={isGroupHeaderCell ? 'x-datatable__groupHeaderCell' : undefined} width={isGroupHeaderCell ? 500 : undefined}>
          {column.isDesc && this.props.rows != null ? this.props.rows.length - rowIndex : rowIndex + 1}
        </Cell>
      )
    }
    return (
      <Cell
        className={`${isGroupHeaderCell ? 'x-datatable__groupHeaderCell' : 'x-wrap-cell'} ${column.cellClass}`}
        width={isGroupHeaderCell ? 500 : undefined}
      >
        {isGroupHeaderCell &&
          rowWrapper.groupInfo.groupHeader}
        {!isGroupHeaderRow && originalRowData && column.getCellData != null &&
          column.getCellData(originalRowData, columnIndex)}
      </Cell>
    );
  }

  private renderFooterCell = ({ columnKey }: ColumnHeaderProps): React.ReactElement<Cell> | string => {
    const rowHeight = this.props.rowHeight || Style.TABLE_ROW_HEIGHT;
    if (columnKey == null || this.props.footerRow == null) {
      return '';
    }
    else {
      const columnIndex = parseInt(columnKey, 10);
      return (
        <Cell
          className={`x-wrap-cell ${this.props.columns[columnIndex].cellClass}`}
          style={{height: rowHeight}}
        >
          {this.props.footerRow[columnIndex]}
        </Cell>
      );
    }
  }

  private prepareTable() {
    const { 
      rows, onRowClick, onRowSelect, onRowDoubleClick, noRowsMessage } = this.props;
    const rowHeight = this.props.rowHeight || Style.TABLE_ROW_HEIGHT;
    const headerHeight = this.props.headerHeight || Style.TABLE_ROW_HEIGHT;
    const footerHeight = !this.props.footerRow ? undefined : this.props.footerHeight || Style.TABLE_ROW_HEIGHT;
    let { width, height } = this.state;

    const handleRowClick = (onRowClick != null) ? this.handleTableRowClick : undefined;
    const handleRowMouseDown = onRowSelect != null ? this.handleTableRowMouseDown : undefined;
    const handleRowDoubleClick = (onRowClick == null && onRowDoubleClick != null) ? this.handleTableRowDoubleClick : undefined;

    if (onRowClick != null && onRowDoubleClick != null) {
      logger.error('DataTable.onRowClick and DataTable.onRowDoubleClick are not allowed the same time.');
    }

    if (!this.autoSizeMode) {
      width = this.props.width || 400;
      height = this.props.height || 400;
    }

    const loading = rows == null;

    // noDataMessage
    let noRowsRenderer: (() => void) | undefined;
    if (!loading && noRowsMessage != null && noRowsMessage !== '') {
      noRowsRenderer = this.tableNoRowsRenderer;
    }

    // row grouping
    if (loading) {
      this.currentRowWrappers = [];
      this.currentRows = [];
    }
    else if (this.currentRows !== rows) {
      this.currentRowWrappers = this.buildRowWrappers(rows || []);
      this.currentRows = rows || [];
    }

    const rowCount = this.currentRowWrappers.length;

    // if columns changed
    if (this.currentColumns !== this.props.columns || this.columnStates == null) {
      this.currentColumns = this.props.columns;

      this.columnStates = this.currentColumns.map((x, index) => ({
        props: x,
        dataKey: `${index}`,
        ...parseColumnWidth(x.defaultWidth, x.width),
      }));

      if (width != null && width > 0) {
        this.customResizeMade = false;
        calculateColumnWidth(this.columnStates, width);
      }
    }

    return {
      loading,
      tableWidth: width || 0,
      tableHeight: height || 0,
      rowHeight,
      headerHeight,
      footerHeight,
      rowCount,
      columnStates: this.columnStates,
      handleRowClick,
      handleRowDoubleClick,
      handleRowMouseDown,
      noRowsRenderer
    };
  }

  private tableNoRowsRenderer = () => {
    const { noRowsMessage } = this.props;
    if (noRowsMessage == null) {
      return null;
    }

    const messageContent = typeof (noRowsMessage) === 'function' ? noRowsMessage() : noRowsMessage;
    return (
      <div className='x-datatable__noRowsMessage'>
        <i>{messageContent}</i>
      </div>
    );
  }

  private tableRowClassNameGetter = (index: number): string => {
    if (index < 0 || index >= this.currentRowWrappers.length) {
      return '';
    }

    const row = this.currentRowWrappers[index];
    const isHeaderRow = row.rows != null;
    if (isHeaderRow) {
      return 'x-datatable__groupHeaderRow';
    }
    else {
      if (row.rowIndex == null) {
        throw new Error();
      }

      const { selectedRowIndexes } = this.props;
      if (selectedRowIndexes != null && selectedRowIndexes.indexOf(row.rowIndex) !== -1) {
        return 'selected';
      }
    }

    return '';
  }

  private buildRowWrappers(rows: any[]): ITableRowWrapper[] {
    const { rowGroups, getRowGroup } = this.props;
    if (rowGroups == null || rowGroups.length === 0 || getRowGroup == null) {
      return rows.map((row, rowIndex) => ({ rowIndex, groupInfo: (null as any) }));
    }

    const rowWrappers: ITableRowWrapper[] = [];

    const rowGroupsDict: { [groupId: string]: ITableRowWrapper } = {};
    rowGroups.forEach(g => rowGroupsDict[g.groupId] = { groupInfo: g, rows: [] });
    const noneGroupedRow = rowGroupsDict[''] || { groupInfo: { groupId: '', groupHeader: '' } };

    const appendGroupedRow = (item: ITableRowWrapper) => {
      const existingItemIndex = findLastIndex(rowWrappers, (x: ITableRowWrapper) => x.groupInfo.groupId === item.groupInfo.groupId);
      if (existingItemIndex === -1) {
        rowWrappers.push(item);
      }
      else {
        rowWrappers.splice(existingItemIndex + 1, 0, item);
      }
    };

    rows.forEach((rowData, rowIndex) => {
      const groupId = getRowGroup(rowData, rowIndex);
      const groupRow = rowGroupsDict[groupId] || noneGroupedRow;
      if (groupRow.rows == null) {
        groupRow.rows = [];
      }
      if (groupRow.rows.length === 0) {
        rowWrappers.push(groupRow);
      }
      const row: ITableRowWrapper = { groupInfo: groupRow.groupInfo, rowIndex };
      groupRow.rows.push(row);
      appendGroupedRow(row);
    });

    return rowWrappers;
  }

  private handleTableRowDoubleClick = (event: React.SyntheticEvent<Table>, rowIndex: number) => {
    const { onRowDoubleClick } = this.props;
    const row = this.currentRowWrappers[rowIndex];
    const shiftKey = (event as any).shiftKey;
    const ctrlKey = (event as any).ctrlKey;
    if (onRowDoubleClick != null && row != null && row.rowIndex != null && !shiftKey && !ctrlKey) {
      onRowDoubleClick(row.rowIndex);
    }
  }

  private handleTableRowClick = (event: React.SyntheticEvent<Table>, rowIndex: number) => {
    const { onRowClick } = this.props;
    const row = this.currentRowWrappers[rowIndex];
    if (row != null && row.rowIndex != null && onRowClick != null) {
      onRowClick(row.rowIndex);
    }
  }

  private handleTableRowMouseDown = (event: React.SyntheticEvent<Table>, rowIndex: number) => {
    const { onRowSelect, selectedRowIndexes, multiSelect } = this.props;
    const row = this.currentRowWrappers[rowIndex];
    if (row == null || row.rowIndex == null || onRowSelect == null) { return; }

    const shiftKey = (event as any).shiftKey;
    const ctrlKey = (event as any).ctrlKey;
    const newSelectedRowIndexes = [];
    if (multiSelect !== true || (!shiftKey && !ctrlKey)) {
      newSelectedRowIndexes.push(row.rowIndex);
    }
    else if (ctrlKey) {
      if (selectedRowIndexes != null && selectedRowIndexes.length > 0) {
        newSelectedRowIndexes.push(...selectedRowIndexes);
      }

      // toggle selected row
      const i = newSelectedRowIndexes.indexOf(row.rowIndex);
      if (i !== -1) {
        // if row already selected, unselect it
        newSelectedRowIndexes.splice(i, 1);
      }
      else {
        // if row unserlected, select it
        newSelectedRowIndexes.push(row.rowIndex);
        newSelectedRowIndexes.sort();
      }
    }
    else if (shiftKey) {
      if (selectedRowIndexes == null || selectedRowIndexes.length === 0) {
        newSelectedRowIndexes.push(row.rowIndex);
      }
      else {
        const minRowIndex = Math.min(...selectedRowIndexes);
        let fromIndex: number;
        let toIndex: number;
        if (row.rowIndex >= minRowIndex) {
          fromIndex = minRowIndex;
          toIndex = row.rowIndex;
        }
        else {
          fromIndex = row.rowIndex;
          toIndex = Math.max(...selectedRowIndexes);
        }

        for (let i = fromIndex; i <= toIndex; i++) {
          newSelectedRowIndexes.push(i);
        }
      }
    }

    if (!isEqual(newSelectedRowIndexes, selectedRowIndexes)) {
      clearTextSelection();
      onRowSelect(newSelectedRowIndexes);
    }
  }
}