import * as React from 'react';
import classNames from 'classnames';
import { IWfChartSetting } from './common';
import { FormikProps, withFormik } from 'formik';
import { Icon } from '../icon';
import { wfChartManager } from './wf-chart-manager';
import { IChartDataProvider } from './data-provider/chart-data-provider';
import { Select } from '../select';
import { injectIntl, InjectedIntlProps, defineMessages, FormattedMessage } from 'react-intl';
import { DropdownItemProps } from 'semantic-ui-react';

const messages = defineMessages({
  showSettingTooltip: {
    id: 'chartToolBar.showSettingTooltip',
    defaultMessage: 'Show Chart Settings'
  },
  shortByLabel: {
    id: 'chartToolBar.sortByLabel',
    defaultMessage: 'Sort by'
  },
  showBarChartTooltip: {
    id: 'chartToolBar.showBarChartTooltip',
    defaultMessage: 'Show a Bar Graph'
  },
  showPieChartTooltip: {
    id: 'chartToolBar.showPieChartTooltip',
    defaultMessage: 'Show as Pie'
  },
  showGridChartTooltip: {
    id: 'chartToolBar.showGridChartTooltip',
    defaultMessage: 'Show as Grid'
  },
  showLabelTooltip: {
    id: 'chartToolBar.showLabelTooltip',
    defaultMessage: 'Show Labels'
  },
  sortDirection_Order: {
    id: 'chartToolBar.sortDirection.Order',
    defaultMessage: 'Order'
  },
  sortDirection_Asc: {
    id: 'chartToolBar.sortDirection.Asc',
    defaultMessage: 'Asc'
  },
  sortDirection_Desc: {
    id: 'chartToolBar.sortDirection.Desc',
    defaultMessage: 'Desc'
  }
})

function isSupportChartType(dataProvider: IChartDataProvider, chartType: ChartType) {
  const supportedChartTypes = dataProvider.metadata.supportedChartTypes;
  return supportedChartTypes == null || supportedChartTypes.indexOf(chartType) > -1;
}

interface IChartToolBarProps {
  settings: IWfChartSetting,
  chartWidth?: number;
  onOpenSetting: () => void;
  onUpdateSetting: (settings: IWfChartSetting) => void;
}

interface IChartToolBarValues {
  sortBy: number;
  sortDirection?: SortDirection;
}

type ToolBarFormProps = IChartToolBarProps & FormikProps<IChartToolBarValues> & InjectedIntlProps;
class ToolBarForm extends React.PureComponent<ToolBarFormProps>{
  private sortDirectionOptions: DropdownItemProps[];

  constructor(props: ToolBarFormProps) {
    super(props);
    this.handleButtonClick = this.handleButtonClick.bind(this);

    const { intl } = props;
    this.sortDirectionOptions = [
      { key: 'order', value: 'order', text: intl.formatMessage(messages.sortDirection_Order) },
      { key: 'asc', value: 'asc', text: intl.formatMessage(messages.sortDirection_Asc) },
      { key: 'desc', value: 'desc', text: intl.formatMessage(messages.sortDirection_Desc) },
    ]
  }

  public render() {
    const { settings, values, intl, chartWidth } = this.props;
    const { chartType, showLabel, dataProviderId } = settings;
    const dataProvider = wfChartManager.getDataProvider(dataProviderId);
    if (dataProvider == null) {
      return null;
    }
    let fieldNames = dataProvider!.metadata.fieldNames;
    let sortByList: DropdownItemProps[] = [];
    if (fieldNames && typeof fieldNames === 'function') {
      fieldNames = fieldNames();
    }
    if (Array.isArray(fieldNames)){
      sortByList = fieldNames.map((x, i) => ({ key: x, value: i, text: x }));
    }

    return (
      <div className='x-chart-panel__toolbar'>
        <div className='x-chart-panel__toolbar-inner' style={{ display: `${chartWidth! < 195 ? 'block' : 'flex'}` }}>
          <button type='button'
            title={intl.formatMessage(messages.showSettingTooltip)}
            value='toggle-setting'
            onClick={this.handleButtonClick}>
            <Icon name='setting' />
          </button>

          <FormattedMessage {...messages.shortByLabel} tagName='label' />
          <Select
            formikProps={this.props}
            name='sortBy'
            value={values.sortBy}
            options={sortByList}
            compact={true}
            style={{ width: 130 }}
          />

          <Select
            formikProps={this.props}
            name='sortDirection'
            value={values.sortDirection}
            options={this.sortDirectionOptions}
            compact={true}
            style={{ width: 70 }}
          />

          <button type='button' className={classNames({ active: chartType === 'horizontalBar' })}
            title={intl.formatMessage(messages.showBarChartTooltip)}
            value='horizontalBar'
            disabled={!isSupportChartType(dataProvider, 'horizontalBar')}
            onClick={this.handleButtonClick}>
            <Icon name='chart-bar' />
          </button>

          <button type='button' className={classNames({ active: chartType === 'pie' })}
            title={intl.formatMessage(messages.showPieChartTooltip)}
            value='pie'
            disabled={!isSupportChartType(dataProvider, 'pie')}
            onClick={this.handleButtonClick}>
            <Icon name='chart-pie' />
          </button>

          <button type='button' className={classNames({ active: chartType === 'grid' })}
            title={intl.formatMessage(messages.showGridChartTooltip)}
            value='grid'
            disabled={!isSupportChartType(dataProvider, 'grid')}
            onClick={this.handleButtonClick}>
            <Icon name='chart-table' />
          </button>

          <button type='button' className={classNames({ active: showLabel })}
            title={intl.formatMessage(messages.showLabelTooltip)}
            value='toggle-label'
            disabled={chartType === 'grid'}
            onClick={this.handleButtonClick}>
            <Icon name='chart-show-label' />
          </button>
        </div>
      </div>
    );
  }

  private handleButtonClick(e: React.MouseEvent<HTMLButtonElement>) {
    const btn = e.target as HTMLButtonElement;
    const btnValue = btn.value;
    if (btnValue === 'toggle-setting') {
      this.props.onOpenSetting();
      return;
    }

    let newSettings: IWfChartSetting | null = null;
    if (btnValue === 'toggle-label') {
      newSettings = { ...this.props.settings, showLabel: !this.props.settings.showLabel };
    }
    else {
      const selectType = btn.value as ChartType;
      if (this.props.settings.chartType !== selectType) {
        newSettings = { ...this.props.settings, chartType: selectType };
      }
    }

    if (newSettings != null) {
      this.props.onUpdateSetting(newSettings);
      return;
    }
  }
}

export const ChartToolBar = withFormik<IChartToolBarProps, IChartToolBarValues>(
  {
    mapPropsToValues: (props) => ({
      sortBy: props.settings.sortBy || 0,
      sortDirection: props.settings.sortDirection || 'order'
    }),
    handleSubmit: () => { return; },
    validate: (values, props) => {
      const settings = {
        sortBy: values.sortBy,
        sortDirection: values.sortDirection,
      };
      props.onUpdateSetting(settings as IWfChartSetting);
    }
  }
)(injectIntl(ToolBarForm));