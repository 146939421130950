import * as React from "react";
import { AppEvent, emitEvent, userSession } from "src/app";
import { Button, Dropdown, DropdownProps, Header, Icon } from "semantic-ui-react";
import { userLogout } from "src/app/auth";
import { userProfile } from "src/app/user-profile";
import { ChangePasswordDialog } from "./user/change-password-dialog";

export const HeaderBar = (prop: { title: string }) => {
  const [openChangePassword, toggleChangePassword] = React.useState(false);

  const handleUserMenuChange = (_: any, data: DropdownProps) => {
    if (data.value === "log-out") {
      userLogout();
    } else if (data.value === 'change-password') {
      toggleChangePassword(!openChangePassword);
    } else if (data.value === 'save-chart-setting') {
      userProfile.save();
    } else if (data.value === 'log') {
      window.location.href = '/log';
    } else if (data.value === 'permission') {
      window.location.href = '/permission';
    }
  };

  const handleBtnClick = () => {
    emitEvent(AppEvent.ToggleMenu);
  }

  const onChangePwDlgClose = () => toggleChangePassword(!openChangePassword);

  const dropDownItems = [
    { value: "save-chart-setting", text: "Lưu Cài Đặt Biểu Đồ" },
    { value: "change-password", text: "Đổi Mật Khẩu" },
    { value: "log-out", text: "Đăng Xuất" },
  ];

  if (!userSession.hasPermission(/^\/dashboard/)) {
    dropDownItems.shift();
  }

  return (
    <>
      <div className="x-header-block-wrapper">
        <div className="x-header-block">
          <Button
            basic={true}
            icon="sidebar"
            compact={true}
            onClick={handleBtnClick}
          />

          <Dropdown
            trigger={
              <span>
                <Icon name="user" />
                {userSession.displayName}
              </span>
            }
            options={dropDownItems}
            onChange={handleUserMenuChange}
            pointing='top right'
            value='*'
          />
          <span className='x-role'>
            Chức Vụ: {userSession.roleName}
          </span>

          <Header as="h3">{prop.title}</Header>
        </div>
        <div style={{ clear: "both" }} />
      </div>
      {openChangePassword &&
        <ChangePasswordDialog
          onClose={onChangePwDlgClose}
        />
      }
    </>
  );
};
