import * as React from 'react';
import { Dropdown, DropdownProps, SelectProps } from 'semantic-ui-react';
import { FormikProps } from 'formik';

interface ISelectProps extends SelectProps {
  /** Form field name. Required if use for connect with Formik. */
  name?: string,

  placeholder?: string;

  /** Pass Formik props into this attribute to connect the component with formik events. */
  formikProps?: FormikProps<any>;
}

export class Select extends React.PureComponent<ISelectProps>{
  public render() {
    const selection = this.props.selection != null ? this.props.selection : true;
    const { formikProps, name, ...newProps } = this.props;
    newProps.onChange = this.handleOnChange;

    return <Dropdown name={name} selection={selection} {...newProps} />;
  }

  private handleOnChange = (event: any, data: DropdownProps) => {
    const { name, onChange, formikProps } = this.props;
    if (onChange != null) {
      onChange(event, data);
    }
    if (name != null && formikProps != null) {
      formikProps.setFieldValue(name, data.value);
    }
  }
}