import { fetchHelper } from 'src/app/fetch-helper';
import { apiEndPoint } from './api-endpoint';

export enum UserStatus {
  Undefined = -999,
  Active = 0,
  Inactive = 1,
  Deleted = 2,
  Spent = 3
}

let cacheUsers: UserModel[] | undefined;

class UserProvider {
  public async getById(id: number | string) {
    return await fetchHelper<UserModel>(`${apiEndPoint.user}/${id}`, 'GET');
  }

  public async searchUsers(params?: UsersCriteria) {
    const { items } = await fetchHelper<GetUsersResp>(apiEndPoint.user, 'GET', params);
    const users = items.filter(x => x.username !== 'admin');
    return users;
  }

  public async getActiveUsers() {
    if (cacheUsers == null) {
      const { items } = await fetchHelper<GetUsersResp>(apiEndPoint.user, 'GET', { status: UserStatus.Active });
      cacheUsers = items.filter(x => x.username !== 'admin');
      setTimeout(() => {
        cacheUsers = undefined;
      }, 30 * 60 * 1000);
    }
    return cacheUsers;
  }

  public async editUser(body: UserModel): Promise<UserModel> {
    let response;
    if (!body.id || body.id === 0) {
      response = await fetchHelper<UserModel>(apiEndPoint.user, 'POST', body);
    } else {
      response = await fetchHelper<UserModel>(`${apiEndPoint.user}/${body.id}`, 'PUT', body);
    }

    return response;
  }

  public async register(id: number, account: {}) {
    await fetchHelper(`${apiEndPoint.user}/${id}/register`, 'PUT', account);
  }

  public async updateStatus(id: number, body: { status: UserStatus }) {
    await fetchHelper(`${apiEndPoint.user}/${id}/update-status`, 'PUT', body);
  }

  public async resetPassword(id: number, account: {}) {
    await fetchHelper(`${apiEndPoint.user}/${id}/reset-password`, 'PUT', account);
  }

  public async changePassword(id: number, body: {}) {
    await fetchHelper(`${apiEndPoint.user}/${id}/change-password`, 'PUT', body);
  }

  public async deleteUser(id: number | string) {
    await fetchHelper(`${apiEndPoint.user}/${id}`, 'DELETE');
  }
}
export const userProvider = new UserProvider();