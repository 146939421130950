import { addLocaleData } from 'react-intl';
import * as en from 'react-intl/locale-data/en';
import * as vi from 'react-intl/locale-data/vi';
import en_locale from './locales/en.json';
import vi_locale from './locales/vi.json';
import { logger } from 'src/app';

addLocaleData([...en, ...vi]);

const allMessages = {
  "en": en_locale,
  "vi": vi_locale
};

class I18nManager {
  private currentLocale: 'en' | 'vi' = 'vi';
  private currentMessages: any;

  public get locale() {
    return this.currentLocale;
  }

  public get messages() {
    return this.currentMessages;
  }

  public load(locale: string) {
    let lang = locale || 'en';
    if (!(lang in allMessages)) {
      logger.warn(`[i18n] Language '${lang}' not found. Default language 'en' will be loaded.`);
      lang = 'en';
    }
    this.currentLocale = lang as any;
    this.currentMessages = allMessages[this.currentLocale];
    if (window.document.documentElement) {
      window.document.documentElement.lang = lang;
    }
  }

  public formatMessage(messageDesc: { id: string, defaultMessage?: string }): string {
    //const msg = this.messages as {[key: string]: string};
    if (!(messageDesc.id in this.currentMessages)) {
      return messageDesc.defaultMessage || messageDesc.id;
    }
    return this.currentMessages[messageDesc.id] as string;
  }
}

export const i18n = new I18nManager();