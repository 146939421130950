import { i18n } from "../i18n";
import { defineMessages } from 'react-intl';
import { logger } from './logger';
import { userSession } from './user-session';
import { setAppStatus } from './app-status';
import { domHelper } from 'src/utils/dom-helper';
import { securityClient } from 'src/api';
import { userProfile } from "./user-profile";

const messages = defineMessages({
  loginFailMessage: {
    id: 'login.fail',
    defaultMessage: 'Tên đăng nhập hoặc mật khẩu không đúng.'
  },
  loginFailInternalError: {
    id: 'login.failIntErr',
    defaultMessage: 'Đăng nhập thất bại. Lỗi kết nối.'
  }
});


let initialized = false; // turn this flag to true if security client initialized.
let wasLoggedin = false; // turn this flag to true after login with securityClient successfully.

function init() {
  if (!initialized) {
    initialized = true;
  }
}

export async function userLogin(username: string, password: string): Promise<{ isSucceeded: boolean, errorMessage?: string }> {
  let errorMessage: string | undefined;

  try {
    init();

    setAppStatus('Đang đăng nhập...');

    const resp = await securityClient.login({ username, password });
    userSession.userIdentityToken = resp.accessToken;
    userSession.username = resp.username;
    userSession.permissions = resp.permissions;
    userSession.displayName = resp.displayName;
    userSession.roleName = resp.roleName;
    userSession.userId = resp.id;
    userSession.stores = resp.stores;

    await userSession.init();
    userSession.save();
    userProfile.loadProfile(resp.profile);
  }
  catch (ex) {
    logger.error(`User login error. username: ${username}`, ex);
    errorMessage = i18n.formatMessage(messages.loginFailMessage);
  }

  setAppStatus(null);
  if (!errorMessage) {
    wasLoggedin = true;
    return { isSucceeded: true };
  }
  else {
    return { isSucceeded: false, errorMessage };
  }
};

export function userLogout() {
  setAppStatus('Đang đăng xuất...');
  domHelper.rootElement.style.opacity = '0';

  window.setTimeout(async () => {
    try {
      sessionStorage.clear();
      localStorage.clear();
    } catch (ex) {
      // skip
    }
    try {
      await securityClient.logout({ token: userSession.userIdentityToken || ''});
    } catch(ex) {
      // skipp error
    }

    init();
    if (wasLoggedin) {
      wasLoggedin = false;
    }

    userSession.clear();
    logger.clear();
    window.location.href = '/';
  }, 0);
};