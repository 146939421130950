import { EventEmitter } from "eventemitter3";

export enum AppEvent {
  /**
   * Emit by Login component once use login successfully.
   * Args: none
   */
  UserLogin = "UserLogin",

  /**
   * Emit before and after async operation.
   * Args: `{statusMessage : string | null, disableInteraction: boolean}`
   */
  StatusChange = "StatusChange",

  /**
   * Emit when an alert message sent.
   * Args: `{alertMessage: string, color: 'red' | 'green' | 'blue'}`
   */
  AlertSent = "AlertSent",

  /** Emit each time Refresh button clicked.
   * Args: {timestamp : Date}
   */
  Refresh = "Refresh",

  /**
   * Emit by Timer every intervel.
   * Args: `{timestamp : Date}`
   */
  RefreshTimerTick = "RefreshTimerTick",

  /**
   * Emit every time Auto Refresh option turn on or off
   * Args:
   * ```
   * {autoRefresh : boolean}
   * ```
   */
  AutoRefreshToggle = "AutoRefreshToggle",

  /**
   * Emit every time 'Create Chart Panel' click by user.
   * Args: `{id: string, component: string}`
   */
  AddPanel = "AddPanel",

  /**
   * Emint every time new layout loaded (from Load Profile dialog).
   * Args: none
   */
  ReloadLayout = "ReloadLayout",

  /**
   * Emit every time a page loaded.
   * Args: `{layoutKey: string}`
   */
  PageLoaded = "PageLoaded",

  /**
   * Emit every time a page prepare to unload.
   * Args: `{layoutKey: string}`
   */
  PageWillUnload = "PageWillUnload",

  /**
   * Emit every time ChartDataChange.
   * Args: none
   */
  ChartDataChange = "ChartDataChange",

  /**
   *
   */
  ToggleMenu = "ToggleMenu",
}

type EventListener = (...args: any[]) => void;

const eventEmitter = new EventEmitter();

export class EventSubscription {
  private readonly subscribedEvents: Array<{
    event: AppEvent;
    listener: EventListener;
  }> = [];

  /**
   * Start listening for an event.
   * @param event The event to listen for.
   * @param listener A callback listener function.
   */
  public subscribe(event: AppEvent, listener: EventListener): this {
    eventEmitter.on(event.toString(), listener);
    this.subscribedEvents.push({ event, listener });
    return this;
  }

  /**
   * Listen an event once.
   * @param event The event to listen for.
   * @param listener A callback listener function.
   */
  public subscribeOnce(event: AppEvent, listener: EventListener) {
    eventEmitter.once(event.toString(), listener);
    return this;
  }

  /** Unsubcribe all subscribed events. */
  public unsubcribeAll() {
    for (const { event, listener } of this.subscribedEvents) {
      eventEmitter.off(event.toString(), listener);
    }
  }
}

/**
 * Emit event to registered listerners.
 * @param event The event to be emitted.
 * @param args Arguments data come with the event.
 */
export function emitEvent(event: AppEvent, ...args: any[]) {
  eventEmitter.emit(event.toString(), args);
}
