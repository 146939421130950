import * as React from 'react';
import { Form, Button } from 'semantic-ui-react';
import { Formik, FormikProps, FormikHelpers } from 'formik';
import { DateTimePicker } from '../datetime-picker';
import { Layout } from './layout';

export class HomeComponent extends React.Component<{}, IHomeState> {
  constructor(props: {}) {
    super(props);
    this.state = {};
  }

  public componentDidMount() {
    this.handleReload();
  }

  public render() {
    const { fromDate, toDate } = this.state;
    return (
      <>
        <div className='x-flex'>
          <Formik
            initialValues={this.state}
            enableReinitialize={true}
            render={this.renderForm}
            onSubmit={this.handleSearchFormSubmit}
          />
          <div className='x-dock-layout-container'>
            <Layout
              fromDate={fromDate}
              toDate={toDate}
            />
          </div>
        </div>
      </>
    );
  }

  private renderForm = (formikProps: FormikProps<IHomeState>) => {
    const { fromDate, toDate } = formikProps.values;
    const isSubmitting = formikProps.isSubmitting;
    const onSubmit = formikProps.submitForm;

    const validateStartTime = (value: Date) => {
      return toDate == null || value.getTime() <= toDate.getTime();
    };

    const validateEndTime = (value: Date) => {
      return fromDate == null || value.getTime() >= fromDate.getTime();
    };

    return (
      <Form onSubmit={onSubmit}>
        <Form.Group widths='three' fields={true}>
          <Form.Field>
            <DateTimePicker
              formikProps={formikProps}
              placeholder='Từ Ngày'
              name='fromDate'
              value={fromDate}
              validateDate={validateStartTime}
              display='date'
              disabled={isSubmitting}
            />
          </Form.Field>

          <Form.Field>
            <DateTimePicker
              formikProps={formikProps}
              placeholder='Đến Ngày'
              name='toDate'
              value={toDate}
              validateDate={validateEndTime}
              display='date'
              disabled={isSubmitting}
            />
          </Form.Field>

          <Form.Field>
            <Button
              type='submit'
              content='Tìm'
              basic={true}
              primary={true}
              disabled={isSubmitting}
              loading={isSubmitting}
            />
          </Form.Field>
        </Form.Group>
      </Form>
    );
  };

  private handleSearchFormSubmit = async (
    values: any,
    { setSubmitting }: FormikHelpers<IHomeState>
  ) => {
    await this.fetchData(values);
    setSubmitting(false);
  };

  private handleReload = async () => {
    return;
  };

  private async fetchData(criteria: IHomeState) {
    this.setState({
      fromDate: criteria.fromDate,
      toDate: criteria.toDate,
    });
  }
}
