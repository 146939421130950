import { fetchHelper } from 'src/app/fetch-helper';
import { apiEndPoint } from './api-endpoint';

export enum LoanStatus {
  IN_PROGRESS = 0,
  DONE = 1,
}

export const loanProvider = {
  async searchLoans(params: LoanCriteria) {
    const cloneParams = { ...params };
    if (cloneParams.toDate) {
      cloneParams.toDate.setHours(23, 59, 59);
    }
    const response = await fetchHelper<GetLoansResp>(apiEndPoint.loan, 'GET', params);
    return response.items;
  },

  getById(loanId: number) {
    return fetchHelper<LoanModel>(`${apiEndPoint.loan}/${loanId}`, 'GET');
  },

  delete(loanId: number) {
    return fetchHelper(`${apiEndPoint.loan}/${loanId}`, 'DELETE');
  },

  complete(loanId: number, body: {}) {
    return fetchHelper<LoanModel>(`${apiEndPoint.loan}/${loanId}/complete`, 'POST', body);
  },

  paid(loanId: number, body: {}) {
    return fetchHelper<LoanModel>(`${apiEndPoint.loan}/${loanId}/paid`, 'POST', body);
  },

  createUpdateLoan(model: LoanModel) {
    if (model.id) {
      return fetchHelper<LoanModel>(`${apiEndPoint.loan}/${model.id}`, 'PUT', model);
    } else {
      return fetchHelper<LoanModel>(`${apiEndPoint.loan}`, 'POST', model);
    }
  },
}
