import 'src/style/select-box.scss';
import * as React from 'react';
import { Popup, Menu, Checkbox, MenuItemProps, Button, PopupProps } from 'semantic-ui-react';
import { Icon } from './icon';
import classNames from 'classnames';
import { FormikProps } from 'formik';

interface ISelectBoxProps {
  id?: string;
  /** Form field name. Required if use for connect with Formik. */
  name?: string,

  width?: number,
  placeholder?: string;
  /** Set a non-null text to display in placeholder position.
   * If text not set (or null), the text will be auto-controlled. It
   * will be information of selected items.
   */
  text?: string;

  options: Array<{
    value: string | number;
    text?: string;
  }>;

  /** Set true or false to show or hide first item 'Select All'
   * Or set a string value for label of the item.
   * Default is true.
   */
  selectAll?: boolean | string;

  /** Set null to select all items, set undefined or empty string array to select none. */
  selected?: Array<string | number> | null;

  /** Set null to select all items, set undefined or empty string array to select none. */
  onSelected?: (selected: Array<string | number> | null, formikValues?: any) => void;

  /** Pass Formik props into this attribute to connect the component with formik events. */
  formikProps?: FormikProps<any>;

  /** Set to true to disable select box. */
  disabled?: boolean;
}

interface SelectBoxState {
  closeOnTriggerBlur?: boolean
}

export default class SelectBox extends React.PureComponent<ISelectBoxProps, SelectBoxState> {
  private triggerButtonRef: React.RefObject<Button>;
  private isPopupOpen: boolean;

  constructor(props: ISelectBoxProps) {
    super(props);
    this.state = {
      closeOnTriggerBlur: true
    };
    this.triggerButtonRef = React.createRef();
    this.isPopupOpen = false;
  }
  public render() {
    const popupWidth = this.props.width == null ? undefined : (this.props.width - 18);
    return (
      <Popup className='x-selectbox__popup' on='click' position='bottom left'
        closeOnTriggerBlur={this.state.closeOnTriggerBlur}
        trigger={this.renderPlaceholderInput(this.props.placeholder, this.props.disabled)}
        onClose={this.handlePopupClose}
        onOpen={this.handlePopupOpen}
        content={
          <Menu vertical={true} secondary={true} style={{ width: popupWidth }}
            onMouseDown={this.handleMenuMouseDown}
            onMouseUp={this.handleMenuMouseUp}>
            {this.renderSelectAllMenuItem()}
            {this.props.options.map((x, index) => this.renderMenuItem(x, index))}
          </Menu>
        }
      />
    );
  }

  public componentDidUpdate() {
    if (this.state.closeOnTriggerBlur === true && this.isPopupOpen && this.triggerButtonRef.current != null) {
      this.triggerButtonRef.current.focus();
    }
  }

  private handlePopupOpen = (event: React.MouseEvent<HTMLElement>, data: PopupProps) => {
    this.isPopupOpen = true;
    if (this.state.closeOnTriggerBlur !== true) {
      this.setState({ closeOnTriggerBlur: true });
    }
  }

  private handlePopupClose = (event: React.MouseEvent<HTMLElement>, data: PopupProps) => {
    const { name, formikProps } = this.props;
    if (name && formikProps) {
      formikProps.setFieldTouched(name);
    }
    this.isPopupOpen = false;
  }

  private handleMenuMouseDown = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ closeOnTriggerBlur: false });
  }

  private handleMenuMouseUp = (event: React.MouseEvent<HTMLElement>) => {
    if (this.state.closeOnTriggerBlur !== true) {
      this.setState({ closeOnTriggerBlur: true });
    }
  }

  private renderSelectAllMenuItem = (): React.ReactNode | null => {
    const selectAll = this.props.selectAll;
    if (selectAll === false) {
      return null;
    }
    const text = (selectAll === true || selectAll == null) ? '(Select All)' : (selectAll as string);
    return this.renderMenuItem({ value: '*', text }, -1);
  };

  private renderMenuItem = (item: { value: string | number, text?: string }, itemIndex: number) => {
    let isSelected = this.props.selected === null || (this.props.selected !== undefined && this.props.selected.indexOf(item.value) !== -1);
    if (itemIndex === -1 && this.props.selected?.length === this.props.options.length) {
      isSelected = true;
    }
    return (
      <Menu.Item key={`i${item.value}`} active={isSelected} index={itemIndex} onClick={this.handleMenuItemClick}>
        <Checkbox checked={isSelected} value={item.value} label={item.text || item.value} />
      </Menu.Item>
    );
  };

  private renderPlaceholderInput = (placeholder?: string, disabled?: boolean) => {
    const { selected, width } = this.props;
    let text: string | null = null;
    if (selected === null) {
      text = 'All';
    }
    else if (selected !== undefined && selected.length > 0) {
      const firstValue = selected[0];
      const firstItem = this.props.options.find(x => x.value === firstValue);
      if (firstItem == null) {
        text = `${firstValue}`;
      }
      else {
        text = firstItem.text || `${firstItem.value}`;
      }
      if (selected.length > 1) {
        text += ` and ${selected.length - 1} more`;
      }
    }

    return (
      <div className={classNames('x-selectbox', { 'disabled': disabled })} style={{ width }}>
        <Button ref={this.triggerButtonRef} type='button' id={this.props.id} name={this.props.name} disabled={disabled} basic={true} fluid={true}>
          <span className={classNames({ 'selected': text != null })}>{text || placeholder}</span>
          <Icon name='expand-more' />
        </Button>
      </div>
    );
  }

  private handleMenuItemClick = (event: React.MouseEvent<HTMLAnchorElement>, data: MenuItemProps) => {
    const itemIndex = data.index == null ? -1 : data.index;
    const isCurrentSelectAll = this.props.selected === null || this.props.selected?.length === this.props.options.length;
    const currentSelected =
      isCurrentSelectAll
        ? this.props.options.map(x => x.value) // if select all
        : (this.props.selected || []); // if select none of some

    let newSelected: Array<string | number> | null;
    if (itemIndex >= 0) {
      const item = this.props.options[itemIndex];
      const isItemCurrentlySelected = isCurrentSelectAll || currentSelected.indexOf(item.value) !== -1;
      if (isItemCurrentlySelected) {
        newSelected = currentSelected.filter(x => x !== item.value);
      }
      else {
        newSelected = [item.value, ...currentSelected];
        // if (newSelected.length === this.props.options.length) {
        //     newSelected = this.props.options.map(x => x.value); // selected all
        // }
      }
    }
    else {
      newSelected = isCurrentSelectAll ? [] : this.props.options.map(x => x.value); // selected all or none
    }

    const { formikProps, name } = this.props;
    if (this.props.onSelected != null) {
      this.props.onSelected(newSelected, formikProps?.values);
    }

    if (formikProps != null && name) {
      formikProps.setFieldValue(name, newSelected);
    }
  }
}