import { userSession } from 'src/app';
import { downloadExcelFile, fetchHelper } from 'src/app/fetch-helper';
import { apiEndPoint } from './api-endpoint';

export enum DeliveryStatus {
  NEW = 0,
  SHIPPING = 1,
  DONE = 2,
}

class DeliveryProvider {
  public getById(id: number) {
    return fetchHelper<DeliveryModel>(`${apiEndPoint.delivery}/${id}`, 'GET');
  }

  public async searchDeliverys(params: any) {
    const response = await fetchHelper<GetDeliveriesResp>(
      apiEndPoint.delivery,
      'GET',
      params
    );
    return response.items;
  }

  public async getDetails(params: any) {
    const { items } = await fetchHelper<GetDetailsResp>(`${apiEndPoint.delivery}/details`, 'GET', params);
    return items;
  }

  public async downloadDetails(params: any) {
    await downloadExcelFile(`${apiEndPoint.delivery}/details`, { ...params, isExport: true });
  }

  public async editDelivery(body: DeliveryModel): Promise<DeliveryModel> {
    let response;
    if (!body.id) {
      throw new Error('Endpoint not found.');
    } else {
      response = await fetchHelper<DeliveryModel>(
        `${apiEndPoint.delivery}/${body.id}`,
        'PUT',
        body
      );
    }
    return response;
  }

  public updateStatus(id: number, status: DeliveryStatus) {
    return fetchHelper(`${apiEndPoint.delivery}/${id}/update-status`, 'PUT', { status });
  }

  public delete(id: number) {
    return fetchHelper(`${apiEndPoint.delivery}/${id}`, 'DELETE');
  }

  public getDeliveryPdfLink(id: number) {
    return `${apiEndPoint.exportPdf}/delivery/${id}?p=${userSession.userIdentityToken}`;
  }

  public updateShippingInfo(id: number, body: { shipperId?: number, shipAddress?: string }) {
    return fetchHelper(`${apiEndPoint.delivery}/${id}/assign`, "PUT", body);
  }
}

export const deliveryProvider = new DeliveryProvider();
