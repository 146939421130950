import { fetchHelper } from 'src/app/fetch-helper';
import { apiEndPoint } from './api-endpoint';

class RoleProvider {
  private cacheRoles?: RoleModel[];

  public async getById(id: number | string) {
    return await fetchHelper<RoleModel>(`${apiEndPoint.role}/${id}`, 'GET');
  }

  public async getAllRoles(): Promise<RoleModel[]> {
    if (this.cacheRoles != null) {
      return this.cacheRoles;
    }
    
    const { items: roles } = await fetchHelper<GetRolesResp>(apiEndPoint.role, 'GET', { size: 10_000 });

    this.cacheRoles = roles;
    setTimeout(() => {
      this.cacheRoles = undefined;
    }, 60 * 1000)
    return roles;
  }

  public async getRoles(params?: RolesCriteria) {
    let cloneParams: RolesCriteria | undefined;
    if (params != null) {
      cloneParams = { ...params };
    }
    const response = await fetchHelper<GetRolesResp>(apiEndPoint.role, 'GET', cloneParams);
    return response.items;
  }

  public async editRole(body: RoleModel): Promise<RoleModel> {
    let response: RoleModel | undefined;
    if (!body.id || body.id === 0) {
      response = await fetchHelper<RoleModel>(apiEndPoint.role, 'POST', body);
    } else {
      response = await fetchHelper<RoleModel>(`${apiEndPoint.role}/${body.id}`, 'PUT', body)
    }
    this.cacheRoles = undefined;
    return response;
  }
}
export const roleProvider = new RoleProvider();