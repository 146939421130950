import * as React from 'react';

export class AutoFocus extends React.Component<{elementId: string}> {
    public render() {
        return null;
    }

    public componentDidMount() {
        const elem = document.getElementById(this.props.elementId) as HTMLElement;
        if (elem != null && typeof elem.focus === 'function') {
            elem.focus();
        }
    }
}