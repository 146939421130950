import { userSession } from 'src/app';
import { fetchHelper } from 'src/app/fetch-helper';
import { apiEndPoint } from './api-endpoint';

export enum ExportModelStatus {
  CREATED = 0,
  APPROVED = 1,
  SHIPPING = 2,
  ENOUGH = 3,
  DONE = 4,
}

class ExportProvider {
  public getById(id: number) {
    return fetchHelper<ExportModel>(`${apiEndPoint.export}/${id}`, 'GET');
  }

  public async searchExports(params: ExportCriteria) {
    const cloneParams = { ...params };

    const response = await fetchHelper<GetExportsResp>(
      apiEndPoint.export,
      'GET',
      cloneParams
    );
    return response.items;
  }

  public async editExport(body: ExportModel): Promise<ExportModel> {
    let response;
    if (!body.id) {
      response = await fetchHelper<ExportModel>(
        apiEndPoint.export,
        'POST',
        body
      );
    } else {
      response = await fetchHelper<ExportModel>(
        `${apiEndPoint.export}/${body.id}`,
        'PUT',
        body
      );
    }
    return response;
  }

  public approve(id: number) {
    return fetchHelper(`${apiEndPoint.export}/${id}/approve`, 'PUT');
  }
  
  public deny(id: number) {
    return fetchHelper(`${apiEndPoint.export}/${id}/deny`, 'PUT');
  }

  public getDeliver(id: number) {
    return fetchHelper(`${apiEndPoint.export}/${id}/delivery`, 'GET');
  }

  public createDelivery(id: number, body: {}) {
    return fetchHelper(`${apiEndPoint.export}/${id}/delivery`, 'POST', body);
  }

  public async getAllProductExport() {
    const response = await fetchHelper<GetExportsResp>(
      apiEndPoint.export,
      'GET'
    );
    return response.items;
  }

  public deleteExport(id: number) {
    return fetchHelper(`${apiEndPoint.export}/${id}`, 'DELETE');
  }

  public getExportPdfLink(id: number, notShowPrice?: boolean) {
    return `${apiEndPoint.exportPdf}/export/${id}?notShowPrice=${notShowPrice || false}&p=${userSession.userIdentityToken}`;
  }

  public createPayment(id: number, body: {}) {
    return fetchHelper(`${apiEndPoint.export}/${id}/payment`, 'POST', body);
  }

  public createPseudoExport(body: any) {
    return fetchHelper(`${apiEndPoint.export}/create-pseudo`, 'POST', body);
  }

  public deletePseudo(id: number) {
    return fetchHelper(`${apiEndPoint.export}/delete-pseudo/${id}`, 'DELETE');
  }

  public syncPayment(id: number) {
    return fetchHelper(`${apiEndPoint.export}/${id}/sync-payment`, 'POST');
  }
}

export const exportProvider = new ExportProvider();
