import { fetchHelper } from 'src/app/fetch-helper';
import { apiEndPoint } from './api-endpoint';

export enum ProductStatus {
  Undefined = -999,
  Active = 0,
  Inactive = 1,
  Deleted = 2
}

interface GetProductssResp extends PageAble {
  items: ProductModel[];
}

class ProductProvider {
  private cacheProducts?: ProductModel[];

  public getById(id: number) {
    return fetchHelper<ProductModel>(`${apiEndPoint.product}/${id}`, 'GET');
  }

  public async getAllProducts(force: boolean = false): Promise<ProductModel[]> {
    if (!force && this.cacheProducts != null) {
      return this.cacheProducts;
    }

    const { items: products } = await fetchHelper<GetProductssResp>(apiEndPoint.product, 'GET', { size: 10_000 });

    this.cacheProducts = products;
    setTimeout(() => {
      this.cacheProducts = undefined;
    }, 60 * 1000);

    return products;
  }

  public async searchProducts(params: ProductsCriteria) {
    const resp = await fetchHelper<GetProductssResp>(apiEndPoint.product, 'GET', params);
    return resp.items;
  }

  public async editProducts(body: ProductModel): Promise<ProductModel> {
    let response: ProductModel | undefined;
    if (!body.id || body.id === 0) {
      response = await fetchHelper<ProductModel>(apiEndPoint.product, 'POST', body);
    } else {
      response = await fetchHelper<ProductModel>(`${apiEndPoint.product}/${body.id}`, 'PUT', body)
    }
    this.cacheProducts = undefined;
    return response;
  }

  public async deleteProducts(productId: number) {
    this.cacheProducts = undefined;
    return fetchHelper(`${apiEndPoint.product}/${productId}`, 'DELETE');
  }

  public updateQuantity(productId: number, body: { quantity: number, storeQuantities: Array<{ storeId: number, quantity: number }> }): Promise<any> {
    this.cacheProducts = undefined;
    return fetchHelper<ProductModel>(`${apiEndPoint.product}/${productId}/update-quantity`, 'PUT', body);
  }

  public tranferQuantity(productId: number, body: { quantity: number, fromStoreId: number, toStoreId: number }): Promise<any> {
    this.cacheProducts = undefined;
    return fetchHelper<ProductModel>(`${apiEndPoint.product}/${productId}/tranfer`, 'PUT', body);
  }
}
export const productProvider = new ProductProvider();