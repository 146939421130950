import { logger } from './logger';

export interface UIStates {
  /** Language code such as 'en', 'en-US', 'vi' */
  locale: string,

  /** Toggle state of Auto Refresh button (in taskbar) */
  autoRefresh: boolean,

  /** Auto Refresh timer interval (default is 5 secs) */
  autoRefreshInterval: number,
}


class ApplicationConfig {
  public logLevel: string = 'Info';
  public traceFilter: string = 'Debug';

  /** Time (in milliseconds) between two consecutive clicks to be interpreted as a double-click  */
  public readonly doubleClickSpeed = 500;

  public readonly searchDayOffset = 1;

  public defaultUiStates: UIStates = {
    locale: 'vi',
    autoRefresh: true,
    autoRefreshInterval: 5,
  };

  public apiUrl: string = '';

  public mqttUrl: string = '';

  public isMobile: boolean = false;

  public guestCustomerId: number = 1405;

  /** time in seconds */
  public allowEditTime: number = 0; // 10 * 24 * 60 * 60; // 3d 24h 60p 60s

  private isLoadedFlag = false;

  public get isLoaded() {
    return this.isLoadedFlag;
  }

  public async load(reload: boolean = false): Promise<void> {
    if (this.isLoadedFlag && !reload) {
      return;
    }

    const cfg = await fetchJsonFile('/config/app.json');
    const t = Object.assign(this, cfg) as ApplicationConfig;
    this.isLoadedFlag = t != null;
    this.isMobile = window.innerWidth < 1024; // 768;
    if (!this.allowEditTime) {
      this.allowEditTime = 0; // 10 * 24 * 60 * 60;
    }
    this.apiUrl = this.apiUrl.replace(/^(http:)|(https:)/, window.location.protocol);
  }
}

async function fetchJsonFile(filePath: string): Promise<any> {
  const configFileUrl = filePath[0] === '/' ? filePath : `/${filePath}`;
  const result = await fetch(`${configFileUrl}?t=${Date.now()}`);
  if (!result.ok) {
    throw new Error(`Cannot load configuration file at ${configFileUrl}`);
  }

  try {
    return result.json();
  } catch (error) {
    logger.error(`Fail to parse json`, result);
    throw error;
  }
}

export const appConfig = new ApplicationConfig();
