import * as React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { Button, Menu, Sidebar, Icon } from 'semantic-ui-react';
import { appConfig, AppEvent, EventSubscription, routes, userSession } from 'src/app';

const MenuBar = () => {
  const [toggleMenu, setHidden] = React.useState(false);

  const events = new EventSubscription();
  events.subscribe(AppEvent.ToggleMenu, () => setHidden(true));

  const handleIsHide = () => {
    setHidden(false);
  };
  const urlPath = window.location.pathname;

  const renderMenus = routes.filter(route => {
    const { hidden, pattern } = route;
    if (hidden) {
      return false;
    }

    if (pattern != null) {
      const hasPermission =
        userSession.isAdmin ||
        userSession.permissions.some((x) => pattern.test(x.path));
      if (!hasPermission) {
        return false;
      }
    }

    return true;
  });

  React.useEffect(() => {
    if (appConfig.isMobile) {
      return;
    }
    const indexActive = renderMenus.findIndex(x => x.pattern?.test(urlPath));
    if (indexActive * 38 <= window.innerHeight) {
      return;
    }

    const collection = document.getElementsByClassName('x-manu-bar');
    if (collection && collection.length === 1) {
      const menuBar = collection.item(0);
      menuBar?.scroll({
        top: (indexActive + 1) * 38 - window.innerHeight,
        behavior: 'smooth',
      });
    }
  }, []);

  return (
    <Sidebar
      as={Menu}
      className={classNames('x-manu-bar', { 'x-menu-toggle': toggleMenu && appConfig.isMobile })}
      animation='push'
      direction='left'
      vertical={true}
      visible={!appConfig.isMobile || toggleMenu}
    >
      <Button
        basic={true}
        className='x-close-menu'
        icon='angle double left'
        onClick={handleIsHide}
      />

      {renderMenus.map((route) => {
        const { pattern, onClick, path, title, mobileSupported } = route;
        if (onClick) {
          return (
            <Menu.Item key={path} onClick={onClick}>
              {title}
            </Menu.Item>
          );
        }

        return (
          <Menu.Item
            as={Link}
            to={path}
            className={classNames({
              header: path === '/',
              active: pattern?.test(urlPath),
            })}
            key={path}
          >
            {title}
            {appConfig.isMobile && mobileSupported && <Icon name='mobile alternate' className='x-mobile-supported' />}
          </Menu.Item>
        );
      })}
    </Sidebar>
  );
};

export default MenuBar;
