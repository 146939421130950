import { fetchHelper } from 'src/app/fetch-helper';
import { apiEndPoint } from './api-endpoint';

export enum StoreStatus {
  Undefined = -999,
  Active = 0,
  Inactive = 1,
  Deleted = 2
}

class StoreProvider {
  private cacheProducts?: StoreModel[];

  public async getById(id: number | string) {
    return await fetchHelper<StoreModel>(`${apiEndPoint.store}/${id}`, 'GET');
  }

  public async getStores() {
    if (this.cacheProducts != null) {
      return this.cacheProducts;
    }

    const { items: stores } = await fetchHelper<GetStoresResp>(apiEndPoint.store, 'GET');
    this.cacheProducts = stores;
    return stores;
  }

  public async editStore(body: StoreModel): Promise<StoreModel> {
    let response;
    if (!body.id || body.id === 0) {
      response = await fetchHelper<StoreModel>(apiEndPoint.store, 'POST', body);
    } else {
      response = await fetchHelper<StoreModel>(`${apiEndPoint.store}/${body.id}`, 'PUT', body);
    }

    return response;
  }

  public async deleteStore(id: number | string) {
    await fetchHelper(`${apiEndPoint.store}/${id}`, 'DELETE');
  }
}
export const storeProvider = new StoreProvider();