import { fetchHelper } from "src/app/fetch-helper";
import { apiEndPoint } from "./api-endpoint";

export const statisticProvider = {
  getProductRemaining: async () => {
    const res = await fetchHelper<StatisticResponse<ProductRemain>>(`${apiEndPoint.statistic}/product-remain`, 'GET');
    return res.items;
  },

  getAgriProductRemaining: async () => {
    const res = await fetchHelper<StatisticResponse<AgriProductRemain>>(`${apiEndPoint.statistic}/agri-product-remain`, 'GET');
    return res.items;
  },

  getChangingProducts: async (criteria: any) => {
    const res = await fetchHelper<StatisticResponse<ChangingProduct>>(`${apiEndPoint.statistic}/changing-product`, 'GET', criteria);
    return res.items;
  },
}
