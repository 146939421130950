import './style';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { Route, BrowserRouter as Router, Switch, Redirect } from 'react-router-dom';
import LoginPage from './pages/login';
import { IntlProvider } from "react-intl";
import { PrivateRoute } from './components/private-route';
import { i18n } from "./i18n"
import { route, routes } from './app/route';
import { appInit, logger } from './app/index';
import { domHelper } from './utils/dom-helper';
import LayoutUtils from './pages/_layout';

const App = () => (
  <IntlProvider locale={i18n.locale} messages={i18n.messages}>
    <>
    <Router>
      <Switch>
        {routes.map(x => (
          <PrivateRoute key={x.path} exact={true} path={x.path} component={x.component} />
        ))
        }
        <Route path={route.pathPatterns.login} component={LoginPage} />
        <Redirect to={route.pathPatterns.myDelivery} />
      </Switch>
    </Router>
    <LayoutUtils />
    </>
  </IntlProvider>
);

appInit().then(() => {
  ReactDOM.render(<App />, domHelper.rootElement);
}).catch(ex => {
  logger.error(`Init app fail. ${ex}`, ex);
  // document.location.href= '/';
});


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
