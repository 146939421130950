import { logger } from './logger';
import { appConfig } from './config';
import { userSession } from './user-session';
import { appInfo } from './app-info';
import { i18n } from 'src/i18n';
import { taskbarManager } from './taskbar-manager';
import { showAlert } from './app-status';
import { emitEvent, AppEvent, EventSubscription } from './events';

async function appInit() {
    const events = new EventSubscription();
    events.subscribeOnce(AppEvent.UserLogin, afterUserLogin);

    await appConfig.load();
    i18n.load(appConfig.defaultUiStates.locale);

    await userSession.load();
};

function handleAutoRefreshToggle() {
    const autoRefresh = !userSession.uiStates.autoRefresh;
    userSession.uiStates.autoRefresh = autoRefresh;
    userSession.save();
    showAlert(`Auto Refresh is ${autoRefresh ? 'On' : 'Off'}.`, '*' , autoRefresh ? 'blue' : 'default');
}

function handleRefresh() {
    emitEvent(AppEvent.Refresh, {timestamp: new Date()});
}

function afterUserLogin() {
    taskbarManager.load(handleAutoRefreshToggle, handleRefresh);
}

export { appInit, appConfig, logger, userSession, appInfo };

export { i18n } from '../i18n';
export * from './events';
export * from './app-status';
export * from './route';