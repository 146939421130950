import * as React from 'react';
import { Container, Button, Icon } from 'semantic-ui-react';
import { showAlert } from 'src/app';
import DataTable, { IDataTableColumn } from 'src/components/data-table';

interface TestDataTable2State {
  selectedRows?: number[],
  rows: any[],
  columns: IDataTableColumn[],
}
class TestDataTable2 extends React.Component<{}, TestDataTable2State> {
  public static readonly rows: any[] = [];
  constructor(props: {}) {
    super(props);

    const rows = TestDataTable2.rows;
    if (rows.length === 0) {
      rows.push(
        ['A', 'BBB', 'CCC', Date.now(), new Date().toLocaleDateString()],
        ['A', 'sdfsdf', 'CCC', Math.random() * 1122334455, Math.random()],
        ['B', 'sdfsdfsd', 'DDD sdfsdfsdfsd', Date.now(), Math.random() * Date.now()],
      )
      while (rows.length < 10000) {
        rows.push(rows[0], rows[1], rows[2]);
      }
    }

    const getCellData = this.getCellData;
    const columns: IDataTableColumn[] = [
      { text: 'Column 1', width: '80px', getCellData },
      { text: 'column 2', width: 80, getCellData },
      { text: 'Column 3', width: 50, getCellData },
      { text: 'Column 4', width: 80, getCellData },
      { text: 'Column 5', width: '75px', getCellData, attachTo: 'right' },
    ];
    this.state = { rows, columns };
  }
  public render() {
    const { rows, columns } = this.state;
    const props: any = {
      width: 600,
      height: 300,
      rows,
      columns,
      footerRow: rows[0],
      selectedRowIndexes: this.state.selectedRows,
      onRowSelect: this.handleRowSelect,
      className: 'testclassname',
      rowHeight: 30,

      rowGroups: [
        { groupId: '0', groupHeader: 'Group 0' },
        { groupId: 'A', groupHeader: 'Group A grou A long name' },
        { groupId: 'B', groupHeader: <><Icon name='play' />Group B</> },
      ],
      getRowGroup: this.getRowGroup,

      noRowsMessage: 'There is no rows'
    };

    return (
      <>
        <DataTable {...props} onRowDoubleClick={this.handleRowClick} />
      </>
    );
  }

  private getCellData = (rowData: any, columnIndex: number) => {
    return rowData[columnIndex];
  };

  private getRowGroup = (rowData: any[], rowIndex: number): string => {
    return rowData[0];
  }

  private handleRowSelect = (rowIndexes: number[]) => {
    this.setState({ selectedRows: rowIndexes });
  }

  private handleRowClick = (rowIndex: number) => {
    alert('Click row ' + rowIndex);
  }
}


// tslint:disable-next-line:max-classes-per-file
export default class DevPage extends React.Component<{}, {}> {
  constructor(props: {}) {
    super(props);
    this.state = { }
  }
  public render() {
    return (
      <Container>
        <Button onClick={this.sendAlert}>Send Alert</Button>
        <hr />
        <TestDataTable2 />
      </Container>
    );
  }

  private sendAlert = () => {
    showAlert('Test alert message ' + new Date());
  }
}