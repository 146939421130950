
import 'chartjs-plugin-datalabels';
import * as React from 'react';
import * as Chart from 'chart.js';
import { IWfChartSetting, IWfChartData } from './common';
import { ChartjsWrapper } from './chartjs-wrapper';
import { withRouter, RouteComponentProps } from 'react-router';
import GridChart from './gird-chart';

Chart.defaults.global.defaultFontColor = '#000';
Chart.defaults.global.defaultFontSize = 12;
Chart.defaults.global.devicePixelRatio = window.devicePixelRatio;
Chart.defaults.global.maintainAspectRatio = true;
Chart.defaults.global.showLines = false;

// fill background color is white
Chart.plugins.register({
  beforeDraw: (chartInstance: Chart) => {
    const ctx = chartInstance.ctx;
    if (ctx) {
      ctx.fillStyle = 'white';
      const { width, height } = (chartInstance as any) || { width: 0, height: 0 };
      ctx.fillRect(0, 0, width, height);
    }
  }
});

const defaultOptions = (showLabel?: boolean): Chart.ChartOptions => ({
  responsive: true,
  maintainAspectRatio: false,
  devicePixelRatio: window.devicePixelRatio || 1,
  legend: {
    display: true,
    position: 'bottom',
    onClick: () => null,
    labels: { boxWidth: 15, padding: 15 }
  },
  plugins: {
    datalabels: {
      display: showLabel === true,
      color: 'black',
      anchor: 'end',
      align: 'end',
      borderRadius: 2,
      padding: { top: 2, bottom: 2, left: 3, right: 3 },
      opacity: 0.8
    }
  },
  layout: { padding: { top: 20, bottom: 0, left: 0, right: 0 } }
});

interface IChartViewProps extends RouteComponentProps {
  settings: IWfChartSetting,
  chartData?: IWfChartData,
  chartHeight?: number;

  chartTitle?: string;
  isLoading?: boolean;

  onGridColumnResize: (columnWidths: number[]) => void;
}

class ChartViewComponent extends React.Component<IChartViewProps> {
  constructor(props: IChartViewProps) {
    super(props);
    this.handleChartElementDoubleClick = this.handleChartElementDoubleClick.bind(this);
    this.handleChartElementClick = this.handleChartElementClick.bind(this);
  }

  public render() {
    return (
      <div className='x-chart-panel__view'>
        {this.renderChart()}
      </div>
    );
  }

  private renderChart() {
    const { settings, chartData, chartHeight, chartTitle, isLoading } = this.props;

    if (settings.chartType === 'grid') {
      return <GridChart
        chartData={chartData}
        settings={settings}
        gridTitle={chartTitle}
        isLoading={isLoading}
        onDataTableColumnResize={this.props.onGridColumnResize} />;
    }

    return <ChartjsWrapper
      chartType={settings.chartType}
      chartData={chartData}
      defaultOptions={defaultOptions(settings.showLabel)}
      settings={settings}
      chartHeight={chartHeight}
      chartTitle={chartTitle}
      isLoading={isLoading}
      onChartElementDoubleClick={this.handleChartElementDoubleClick}
      onChartElementClick={this.handleChartElementClick} />;
  }

  private handleChartElementClick(elementIndex: number) {
    if (this.props.chartData == null) {
      return;
    }

    const chartJsData = this.props.chartData.chartJsData;
    if (chartJsData == null || chartJsData.labels == null || chartJsData.labels.length === 0 || chartJsData.labels.length <= elementIndex) {
      return;
    }
  }

  private async handleChartElementDoubleClick(elementIndex: number | undefined) {
    return;
  }
}

export const ChartView = withRouter<IChartViewProps>(ChartViewComponent);
