import { fetchHelper } from 'src/app/fetch-helper';
import { apiEndPoint } from './api-endpoint';

export const paymentProvider = {
  async getList(params: PaymentCriteria) {
    const cloneParams = { ...params };
    if (cloneParams.toDate) {
      cloneParams.toDate.setHours(23, 59, 59);
    }
    const response = await fetchHelper<GetPaymentsResp>(apiEndPoint.payment, 'GET', params);
    return response.items;
  },

  async create(body: CreatePaymentModel) {
    let response: CreatePaymentModel | undefined;
    response = await fetchHelper<CreatePaymentModel>(`${apiEndPoint.payment}`, 'POST', body);
    return response;
  },

  async batchCreate(body: { payments: PaymentModel[] }) {
    return fetchHelper(`${apiEndPoint.payment}/batch`, 'POST', body);
  },

  async delete(paymentId: number) {
    return fetchHelper(`${apiEndPoint.payment}/${paymentId}`, 'DELETE');
  }
}
