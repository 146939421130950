import { fetchHelper } from 'src/app/fetch-helper';
import { apiEndPoint } from './api-endpoint';

export const agriTransactionProvider = {
  create(body: AgriProductTransactionModel) {
    return fetchHelper<AgriProductTransactionModel>(`${apiEndPoint.agriTransaction}`, 'POST', body);
  },

  delete(id: number) {
    return fetchHelper(`${apiEndPoint.agriTransaction}/${id}`, 'DELETE');
  },
}
