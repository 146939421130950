import * as React from 'react'
import { Button, Dimmer, Loader, Modal } from 'semantic-ui-react'

interface PdfModalProps {
  pdfUrl: string,
  title: string,
  onClose: () => void,
}

interface PdfModalState {
  isLoading: boolean;
}

export default class PdfModal extends React.Component<PdfModalProps, PdfModalState> {
  constructor(props: PdfModalProps) {
    super(props);
    this.state = { isLoading: true };
  }

  public componentDidMount() {
    setTimeout(() => {
      const { isLoading } = this.state;
      if (isLoading) {
        this.setState({ isLoading: false });
      }
    }, 30 * 1000);
  }

  public render() {
    const { pdfUrl, title, onClose } = this.props;
    const { isLoading } = this.state;
    return (
      <Modal
        onClose={onClose}
        open={true}
        closeOnDimmerClick={false}
      >
        <Modal.Header>
          <Button
            content="Đóng"
            onClick={onClose}
            style={{ float: 'right' }}
          />
          {title}
        </Modal.Header>
        <Modal.Content>
          {isLoading &&
            <Dimmer active={true}>
              <Loader>Loading</Loader>
            </Dimmer>
          }
          <iframe
            src={pdfUrl}
            onLoad={this.onIframeLoad}
            style={{
              width: '100%',
              height: '80vh',
              border: 'none',
            }} />
        </Modal.Content>
      </Modal>
    )
  }

  private onIframeLoad = () => {
    const { isLoading } = this.state;
    if (isLoading) {
      this.setState({ isLoading: false });
    }
  }
}