export const defaultLayoutMobile = {
  "global": {
    "tabEnableClose": false
  },
  "layout": {
    "type": "row",
    "id": "#1",
    "children": [
      {
        "type": "row",
        "id": "#22",
        "children": [
          {
            "type": "tabset",
            "id": "#16",
            "weight": 60,
            "children": [
              {
                "type": "tab",
                "id": "#10",
                "name": "Khách hàng công nợ",
                "component": "grid"
              }
            ]
          },
          {
            "type": "tabset",
            "id": "#5",
            "weight":60,
            "children": [
              {
                "type": "tab",
                "id": "#9",
                "name": "Số lượng phân bón tồn kho",
                "component": "grid"
              }
            ]
          },
          {
            "type": "tabset",
            "id": "#7",
            "weight": 60,
            "children": [
              {
                "type": "tab",
                "id": "#11",
                "name": "Nhập xuất phân bón trong tháng",
                "component": "grid"
              }
            ]
          },
          {
            "type": "tabset",
            "id": "#21",
            "weight": 60,
            "children": [
              {
                "type": "tab",
                "id": "#12",
                "name": "Số lượng nông sản trong kho",
                "component": "grid"
              }
            ],
            "active": true
          }
        ]
      }
    ]
  }
}