import { fetchHelper } from 'src/app/fetch-helper';
import { apiEndPoint } from './api-endpoint';

export const logProvider = {
  async searchLogs(params: LogCriteria) {
    const cloneParams = {...params};
    if (cloneParams.toDate) {
      cloneParams.toDate.setHours(23, 59, 59);
    }
    const response = await fetchHelper<GetLogsResp>(apiEndPoint.log, 'GET', params);
    return response.items;
  },

  getById(logId: number) {
    return fetchHelper<LogModel>(`${apiEndPoint.log}/${logId}`, 'GET');
  },

  delete(statusCode: 500 | 400 | 403) {
    return fetchHelper(`${apiEndPoint.log}/${statusCode}`, 'DELETE');
  },
};
