import * as React from 'react';
import { Modal, Button, Icon } from 'semantic-ui-react';

export const MessageBox = (props: {
  message: string,
  title?: string,
  icon?: 'error' | 'info',
  onClose: () => void,
  color?: any,
}) => {
  let icon;
  if (props.icon === 'error') {
    icon = <Icon size='big' color={props.color || 'red'} name='exclamation circle' />;
  }
  else if (props.icon === 'info') {
    icon = <Icon size='big' color={props.color || 'green'} name='info circle' />;
  }
  return (
    <Modal open={true} onClose={props.onClose} size='tiny' >
      <Modal.Header >{props.title}</Modal.Header>
      <Modal.Content>
        {icon}
        {props.message}
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={props.onClose} content='OK' />
      </Modal.Actions>
    </Modal>
  );
}