import { appConfig } from 'src/app'

class ApiEndPoint {
  private get domain(): string {
    return appConfig.apiUrl;
  };

  public get login(): string { return `${this.domain}/api/auth/signin`; }

  public get profile(): string { return `${this.domain}/api/auth/profile`; }

  public get category(): string { return `${this.domain}/api/category`; }

  public get import(): string { return `${this.domain}/api/import`; }

  public get export(): string { return `${this.domain}/api/export`; }

  public get delivery(): string { return `${this.domain}/api/delivery`; }

  public get product(): string { return `${this.domain}/api/product`; }

  public get supplier(): string { return `${this.domain}/api/supplier`; }

  public get customer(): string { return `${this.domain}/api/customer`; }

  public get store(): string { return `${this.domain}/api/store`; }

  public get user(): string { return `${this.domain}/api/user`; }

  public get dayOff(): string { return `${this.domain}/api/dayOff`; }

  public get role(): string { return `${this.domain}/api/role`; }

  public get agriProduct(): string { return `${this.domain}/api/agri-product`; }

  public get permission(): string { return `${this.domain}/api/permission`; }

  public get payment(): string { return `${this.domain}/api/payment`; }

  public get loan(): string { return `${this.domain}/api/loan`; }

  public get log(): string { return `${this.domain}/api/log`; }

  public get exportPdf(): string { return `${this.domain}/api/pdf`; }

  public get buyAgriProduct(): string { return `${this.domain}/api/buy-agri-product`; }

  public get saleAgriProduct(): string { return `${this.domain}/api/sale-agri-product`; }

  public get saleFinalProduct(): string { return `${this.domain}/api/sale-final-product`; }

  public get statistic(): string { return `${this.domain}/api/statistic`; }

  public get finalProduct(): string { return `${this.domain}/api/final-product`; }

  public get finalProductConvert(): string { return `${this.domain}/api/final-product-convert`; }

  public get summary(): string { return `${this.domain}/api/summary`; }

  public get agriTransaction(): string { return `${this.domain}/api/agri-transaction`; }

  public get template(): string { return `${this.domain}/api/template`; }

  public get depositContact(): string { return `${this.domain}/api/deposit-contact`; }
};

export const apiEndPoint = new ApiEndPoint();