import {
  IWfChartData,
  IWfChartInstance,
  IWfChartSetting,
} from "../common";
import { TimeSpan } from "src/utils/timespan";
import { i18n } from 'src/app';

export interface IChartDataProvider {
  id: string;
  metadata: {
    name: string;
    category: string;
    fieldNames: (() => string[]) | string[];
    unitList: string[];
    supportedChartTypes?: ChartType[];
    isStack?: boolean;
  };
  defaultSettings?: IWfChartSetting;
  loadData(instance: IWfChartInstance, criteria?: any): Promise<IWfChartData>;
}
const CHART_COLORS = [
  '#93BFEB',
  '#e1b0ff',
  '#66C2A3',
  '#F490AE',
  '#BE9DE2',
  '#5DC2CD',
  '#828AF7',
  '#D876C0',
  '#ADBCC3',
  '#F8A6A6',
  '#698CAF'
];

function darken(color: string) {
  const amount = 0.75;
  const r = amount * parseInt(color.substr(1, 2), 16);
  const g = amount * parseInt(color.substr(3, 2), 16);
  const b = amount * parseInt(color.substr(5, 2), 16);
  return `rgb(${r}, ${g}, ${b})`;
}

const CHART_BORDER_COLORS = CHART_COLORS.map(darken);

function getColors(count: number): { backgroundColor: string[], borderColor: string[] } {
  const backgroundColor: string[] = [];
  const borderColor: string[] = [];
  for (let i = 0; i < count; ++i) {
    let colorIndex = i % CHART_COLORS.length;
    if (i === count - 1 && i > 1 && colorIndex === 0) {
      colorIndex = 1;
    }
    backgroundColor.push(CHART_COLORS[colorIndex]);
    borderColor.push(CHART_BORDER_COLORS[colorIndex]);
  }
  return { backgroundColor, borderColor };
}

export class ChartDataBuilder {
  // Decoration bar chart data
  public static decorBarChartData(params: {
    chartData: IWfChartData;
    colors?: { backgroundColor: string[]; borderColor: string[] };
  }) {
    const { chartData, colors } = params;
    const datasets: IChartDatasetEx[] = [];
    const numberFormat = new Intl.NumberFormat(i18n.locale);
    const { backgroundColor, borderColor } =
      colors || getColors(chartData.fields.length - 1);

    for (let i = 1; i < chartData.fields.length; i++) {
      const datasetIndex = i - 1;
      const field = chartData.fields[i];
      let data: number[];
      if (field.dataType === "tick") {
        data = chartData.records.map(record => TimeSpan.fromTicks(record.values[i] as number).totalHours);
      } else {
        data = chartData.records.map(record => record.values[i] as number);
      }

      const bgColors: string[] = [];
      const borderColors: string[] = [];
      let originalBarColor: string | undefined;
      const alertValues: number[] = [];
      if (data.length === 0) {
        bgColors.push(backgroundColor[datasetIndex]);
        borderColors.push(borderColor[datasetIndex]);
        originalBarColor = backgroundColor[datasetIndex];
      } else {
        data.forEach((value: number, recordIndex: number) => {
          originalBarColor = backgroundColor[datasetIndex];

          bgColors.push(backgroundColor[datasetIndex]);
          borderColors.push(borderColor[datasetIndex]);
        });
      }

      let datasetDisplayName = field.name;
      // custom dataset name
      if (field.summary === "sum") {
        const sum = data.length === 0 ? 0 : numberFormat.format(data.reduce((a, b) => a + b));
        datasetDisplayName += ` (total=${sum})`;
      }

      datasets.push({
        data,
        backgroundColor: bgColors,
        label: datasetDisplayName,
        borderColor: borderColors,
        borderWidth: 1,
        hoverBorderColor: borderColors,
        hoverBorderWidth: 1,
        alertValues,
        originalBarColor,
        compareDatasetName: field.isCompareField ? field.name : undefined
      });
    }

    const chartJsData: Chart.ChartData = {
      labels: chartData.records.map(x => x.values[0]),
      datasets
    };

    return chartJsData;
  }

  // Decoration pie chart data
  public static decorPieChartData(chartData: IWfChartData) {
    const labels = chartData.records.map(x => x.values[0]);

    const { backgroundColor } = getColors(labels.length);
    const datasetIndex = 1; // hard code Pie chart only draw first data set.
    const field = chartData.fields[datasetIndex];
    let data: number[];
    if (field.dataType === "tick") {
      data = chartData.records.map(record => TimeSpan.fromTicks(record.values[datasetIndex]).totalHours);
    } else {
      data = chartData.records.map(record => record.values[datasetIndex]);
    }

    const dataset: Chart.ChartDataSets = {
      data,
      backgroundColor
    };

    return { labels, datasets: [dataset] };
  }

  // Sort Data
  public static sortIWfChartData(
    refData: IWfChartData,
    sortBy: number,
    sortDirection?: SortDirection
  ) {
    if (sortDirection && sortDirection !== "order") {
      const v = sortDirection === "asc" ? 1 : -1;
      refData.records.sort(
        (record1, record2) => {
          let field1: string | number = record1.values[sortBy || 0];
          let field2: string | number = record2.values[sortBy || 0];
          if (typeof field1 === "string" && typeof field2 === "string") {
            field1 = field1.toLowerCase();
            field2 = field2.toLowerCase();
          }
          return field1 === field2 ? 0 : field1 > field2 ? v : -v;
        }
      );
    }

    // sort by group value if chart have group
    if (refData.groups != null) {
      refData.records.sort(
        (record1, record2) => {
          let field1 = record1.groupValue;
          let field2 = record2.groupValue;
          if (field1 == null || field2 == null) {
            return 0;
          }
          field1 = field1.toLowerCase();
          field2 = field2.toLowerCase();
          return field1 === field2 ? 0 : field1 > field2 ? 1 : -1;
        }
      );
    }
  }
}