import * as React from 'react';
import { AppEvent, EventSubscription } from 'src/app';
import { MessageBox } from './message-box';

const MAX_MESSAGE_COUNT = 5;
const MESSAGE_DISPLAY_DURATION = 2000;

interface AlertMessage {
  timestamp: number;
  text: string;
  color: string;
}
interface AppAlertState {
  alertMessages: AlertMessage[];
  messageInBox?: string;
  title?: string;
  icon?: 'error' | 'info';
  color?: string;
}

export default class AppAlert extends React.Component<{}, AppAlertState> {
  private readonly events = new EventSubscription();
  private timeoutHandle = 0;

  constructor(props: {}) {
    super(props);
    this.state = {
      alertMessages: []
    };
    this.onAlertSent = this.onAlertSent.bind(this);
  }

  public render() {
    const now = Date.now();
    const { messageInBox, alertMessages, title, icon, color } = this.state;
    return (
      <>
        <div className='x-app-alert'>
          {alertMessages.map(msg => (
            <div key={msg.timestamp} className={`x-app-alert-message ${msg.color}`}>
              <span className={now - msg.timestamp > MESSAGE_DISPLAY_DURATION ? 'hiding' : ''} >{msg.text}</span>
            </div>
          ))}
        </div>
        {messageInBox &&
          <MessageBox
            message={messageInBox}
            title={title}
            icon={icon || 'info'}
            color={color}
            onClose={this.handleMessageBoxClose}
          />
        }
      </>
    )
  }

  public componentDidMount() {
    this.events.subscribe(AppEvent.AlertSent, this.onAlertSent);
  }

  public componentWillUnmount() {
    this.events.unsubcribeAll();
    if (this.timeoutHandle > 0) {
      window.clearTimeout(this.timeoutHandle);
    }
  }

  private handleMessageBoxClose = () => {
    this.setState({ messageInBox: undefined, title: undefined });
  }

  private onAlertSent(args: any[]) {
    const now = Date.now();
    const { alertMessage, color, type, title = 'Thông Báo', icon = 'info' } = args[0];

    if (type === 'MessageBox') {
      this.setState({ messageInBox: alertMessage, title, icon, color });
      return;
    }

    const alertMessages = this.state.alertMessages.filter(x => now - x.timestamp < MESSAGE_DISPLAY_DURATION * 2);
    if (alertMessages.length >= MAX_MESSAGE_COUNT) {
      alertMessages.splice(0, alertMessages.length - MAX_MESSAGE_COUNT + 1);
    }

    let pushNewMessage = true;
    if (alertMessages.length > 0) {
      const lastAlert = alertMessages[alertMessages.length - 1];
      if (lastAlert.text === alertMessage) {
        lastAlert.timestamp = now;
        lastAlert.color = color || '';
        pushNewMessage = false;
      }
    }
    if (pushNewMessage) {
      alertMessages.push({ timestamp: now, text: alertMessage, color: color || '' });
    }

    if (this.timeoutHandle > 0) {
      window.clearTimeout(this.timeoutHandle);
    }
    this.timeoutHandle = window.setTimeout(this.removeAlertMessages, 500);
    this.setState({ alertMessages });
  }

  private removeAlertMessages = () => {
    const now = Date.now();
    const alertMessages = this.state.alertMessages.filter(x => now - x.timestamp < MESSAGE_DISPLAY_DURATION * 2);
    if (alertMessages.length !== this.state.alertMessages.length
      || alertMessages.findIndex(msg => now - msg.timestamp >= MESSAGE_DISPLAY_DURATION) !== -1) {
      this.setState({ alertMessages });
    }
    if (alertMessages.length > 0) {
      this.timeoutHandle = window.setTimeout(this.removeAlertMessages, 500);
    }
  }
}