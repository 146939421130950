import { customerProvider } from 'src/api';
import { IWfChartInstance, IWfChartData, IField, IRecord } from '../common';
import { IChartDataProvider, ChartDataBuilder } from './chart-data-provider';

const chartName = 'Khách hàng công nợ';

const fields: IField[] = [
  { name: 'Tên', dataType: 'string', summary: 'none' },
  { name: 'Công Nợ Thực Giao', dataType: 'numeric', summary: 'sum' },
  { name: 'K/H Nợ Phân Bón', dataType: 'numeric', summary: 'sum' },
  { name: 'K/H Vay', dataType: 'numeric', summary: 'sum' },
];

async function loadData(instance: IWfChartInstance) {
  const customerDebts = await customerProvider.getDebtList({});

  const records: IRecord[] = customerDebts.map(x => {
    return {
      values: [x.name, x.debtAgriReal, x.debt, x.debtLoan]
    }
  });

  const wfChartData: IWfChartData = { fields, records };
  ChartDataBuilder.sortIWfChartData(wfChartData, instance.settings.sortBy, instance.settings.sortDirection);

  if (instance.settings.chartType === 'horizontalBar') {
    wfChartData.chartJsData = ChartDataBuilder.decorBarChartData({
      chartData: wfChartData,
    });
  }
  else if (instance.settings.chartType === 'pie') {
    wfChartData.chartJsData = ChartDataBuilder.decorPieChartData(wfChartData);
  }
  return wfChartData;
}

export const CustomerDebtDataProvider: IChartDataProvider = {
  id: chartName,
  metadata: {
    name: chartName,
    category: 'Khách Hàng',
    fieldNames: () => fields.map(x => x.name),
    unitList: ['VND'],
  },
  defaultSettings: {
    chartType: 'grid',
    showLabel: true,
    sortBy: 2,
    sortDirection: 'desc',
    dataProviderId: chartName,
    unit: 'VND',
    gridColumnWidths: [3, 2, 2, 2],
  },
  loadData,
};