import React, { Suspense, lazy } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Sidebar, Segment } from 'semantic-ui-react';
import { appConfig } from 'src/app';
import { route } from 'src/app/route';
import { HeaderBar } from 'src/components/header-bar';
import MenuBar from './menu-bar';
const DeliveryManager = lazy(() => import('src/components/delivery/delivery-manager'));
const DeliveryManagerMobile = lazy(() => import('src/components/delivery/delivery-manager-mobile'));

interface IDeliveryParams {
  customerId?: string;
}

export class MyDeliveryPage extends React.PureComponent<RouteComponentProps<IDeliveryParams>> {
  public componentDidMount() {
    document.title = route.getPageTitle(this.props.location.pathname);
  }

  public render() {
    const title = route.getPageTitle(this.props.location.pathname);
    return (
      <Sidebar.Pushable as={Segment} className='x-page'>
        <MenuBar />

        <Sidebar.Pusher>
          <Segment basic={true}>
            <HeaderBar title={title} />
            <Suspense fallback={<div className='x-loading' />}>
              {appConfig.isMobile ?
               <DeliveryManagerMobile myShipping={true} />
               :
               <DeliveryManager myShipping={true} />
              }
            </Suspense>
          </Segment>
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    );
  }
}
