import { fetchHelper } from 'src/app/fetch-helper';
import { apiEndPoint } from './api-endpoint';

export enum CustomerStatus {
  Undefined = -999,
  Active = 0,
  Inactive = 1,
  Deleted = 2
}

export enum CustomerGender {
  Male = 1,
  Female = 2,
  More = 3,
  Undefined = 4,
}

export enum CustomerLevel {
  Personal = 1, // Khách lẻ
  Agency = 2, // Đại lý
}

const parseDate = ((customer: CustomerModel) => {
  if (customer.dateOfBirth) {
    customer.dateOfBirth = new Date(customer.dateOfBirth);
  }
})

class CustomerProvider {
  public async getById(id: number | string) {
    const customer = await fetchHelper<CustomerModel>(`${apiEndPoint.customer}/${id}`, 'GET');
    parseDate(customer);
    return customer;
  }

  public async getList(params: CustomerCriteria) {
    const cloneParams = { ...params };

    const response = await fetchHelper<GetCustomerResp>(apiEndPoint.customer, 'GET', cloneParams);
    const customers = response.items;
    customers.forEach(parseDate);
    return customers;
  }

  public async getDebtList(params: CustomerCriteria) {
    const cloneParams = { ...params };

    const response = await fetchHelper<GetCustomerResp>(`${apiEndPoint.customer}/debt`, 'GET', cloneParams);
    const customers = response.items;
    customers.forEach(parseDate);
    return customers;
  }

  public async getNegativeDebtList(params: CustomerCriteria) {
    const cloneParams = { ...params };

    const response = await fetchHelper<GetCustomerResp>(`${apiEndPoint.customer}/negative-debt`, 'GET', cloneParams);
    const customers = response.items;
    customers.forEach(parseDate);
    return customers;
  }

  public async searchTextCustomers(params: CustomerCriteria) {
    const cloneParams = { ...params };

    const response = await fetchHelper<GetCustomerResp>(`${apiEndPoint.customer}/search`, 'GET', cloneParams);
    const customers = response.items;
    customers.forEach(parseDate);
    return customers;
  }

  public async editCustomer(body: CustomerModel): Promise<CustomerModel> {
    let response: CustomerModel | undefined;
    if (!body.id || body.id === 0) {
      response = await fetchHelper<CustomerModel>(apiEndPoint.customer, 'POST', body);
    } else {
      response = await fetchHelper<CustomerModel>(`${apiEndPoint.customer}/${body.id}`, 'PUT', body)
    }
    return response;
  }

  public syncDebt(customerId: number) {
    return fetchHelper(`${apiEndPoint.customer}/sync-debt`, 'POST', { customerId })
  }

  public async delete(customerId: number) {
    await fetchHelper(`${apiEndPoint.customer}/${customerId}`, 'DELETE');
  }

  public async getArchives(customerId: number) {
    const { items } = await fetchHelper<GetCustomerArchiveResp>(`${apiEndPoint.customer}/${customerId}/archive`, 'GET');
    return items;
  }

  public async archive(customerId: number) {
    await fetchHelper(`${apiEndPoint.customer}/${customerId}/archive`, 'PUT');
  }
}
export const customerProvider = new CustomerProvider();