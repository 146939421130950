export const defaultLayout = {
  "global": {
    "tabEnableClose": false
  },
  "layout": {
    "type": "row",
    "id": "#1",
    "children": [
      {
        "type": "row",
        "id": "#2",
        "children": [
          {
            "type": "row",
            "id": "#3",
            "weight": 50,
            "children": [
              {
                "type": "tabset",
                "id": "#5",
                "weight": 50,
                "children": [
                  {
                    "type": "tab",
                    "id": "#9",
                    "name": "One",
                    "component": "grid"
                  }
                ],
                "active": true
              },
              {
                "type": "tabset",
                "id": "#6",
                "weight": 50,
                "children": [
                  {
                    "type": "tab",
                    "id": "#10",
                    "name": "Two",
                    "component": "grid"
                  }
                ]
              }
            ]
          },
          {
            "type": "row",
            "id": "#4",
            "weight": 50,
            "children": [
              {
                "type": "tabset",
                "id": "#7",
                "weight": 60,
                "children": [
                  {
                    "type": "tab",
                    "id": "#11",
                    "name": "Thee",
                    "component": "grid"
                  }
                ]
              },
              {
                "type": "tabset",
                "id": "#8",
                "weight": 40,
                "children": [
                  {
                    "type": "tab",
                    "id": "#12",
                    "name": "Four",
                    "component": "grid"
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
}