import { logger, userSession } from '.';
import * as queryString from 'query-string';
import { userLogout } from './auth';

interface ResponseError {
  dateTime: string; // DateISO
  errors: any[];
  message: string;
  code: number;
}

export async function fetchHelper<T>(urlStr: string, formMethod: 'GET' | 'POST' | 'PUT' | 'DELETE', params?: any): Promise<T> {
  let response: Response | undefined;

  try {
    let body: string | undefined;
    const url = new URL(urlStr);
    if (formMethod === 'POST' || formMethod === 'PUT') {
      body = JSON.stringify({ ...params });
    }
    else {
      if (params != null) {
        const cloneParams = JSON.parse((JSON.stringify(params)));
        url.search = queryString.stringify(cloneParams, { skipEmptyString: true, skipNull: true });
      }
    }

    response = await fetch(url.href, {
      method: formMethod,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        // 'Authorization': `Bearer ${userSession.userIdentityToken}`,
        'x-access-token': userSession.userIdentityToken || '',
      },
      body
    });
    const json = await response.json();
    if (!response.ok) {
      const responseError = json as ResponseError;
      if (responseError.code === 401) {
        userLogout();
      }
      throw new Error(responseError.message || JSON.stringify(json));
    }
    return json.data as T;
  }
  catch (error) {
    logger.error(`Fetch data from URL '${urlStr}' failed. ${error}.`);
    throw error;
  }
}

export async function uploadFile<T>(urlStr: string, file: File): Promise<T> {
  let response: Response | undefined;

  try {
    const url = new URL(urlStr);
    const formData = new FormData();
    formData.append('file', file);
    response = await fetch(url.href, {
      method: 'POST',
      headers: {
        // 'Authorization': `Bearer ${userSession.userIdentityToken}`
        'x-access-token': userSession.userIdentityToken || '',
      },
      body: formData
    });
    const json = await response.json();
    if (!response.ok) {
      const responseError = json as ResponseError;
      throw new Error(responseError.message || JSON.stringify(json));
    }
    return json as T;
  }
  catch (error) {
    logger.error(`Upload File to URL '${urlStr}' failed. ${error}.`);
    throw error;
  }
}

export async function downloadPdfFile(url: string) {
  const res = await fetch(url, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${userSession.userIdentityToken}`
    }
  });
  const blob = await res.blob();
  const fileURL = window.URL.createObjectURL(new Blob([blob], { type: 'application/pdf' }));
  window.open(fileURL);
}

export async function downloadExcelFile(urlStr: string, query: any) {
  const url = new URL(urlStr);
  if (query != null) {
    const cloneParams = JSON.parse((JSON.stringify(query)));
    url.search = queryString.stringify(cloneParams, { skipEmptyString: true, skipNull: true });
  }
  const res = await fetch(url.href, {
    method: 'GET',
    headers: {
      'x-access-token': userSession.userIdentityToken
    } as any
  });
  const blob = await res.blob();

  const a = document.createElement('a');
  a.href = window.URL.createObjectURL(blob);
  a.download = 'Nhat-Ky-Ban-Phan-Bon.xlsx';
  a.click();
  a.remove();
}