import { userSession } from 'src/app';
import { fetchHelper } from 'src/app/fetch-helper';
import { apiEndPoint } from './api-endpoint';

class DepositContactProvider {
  public async getList(params: {}) {
    const cloneParams = { ...params };

    const response = await fetchHelper<GetDepositContactResp>(apiEndPoint.depositContact, 'GET', cloneParams);
    return response.items;
  }

  public view(id: number) {
    return `${apiEndPoint.depositContact}/${id}?p=${userSession.userIdentityToken}`;
  }

  public create(body: { customerId: number; agriProductId: number }) {
    return fetchHelper<DepositContactModel>(apiEndPoint.depositContact, 'POST', body);
  }
}
export const depositContactProvider = new DepositContactProvider();