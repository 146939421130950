
import { generatePath } from 'react-router';
import { HomePage } from 'src/pages/home';
import { ImportPage } from 'src/pages/import';
import { EditImportPage } from 'src/pages/edit-import';
import { SupplierPage } from 'src/pages/supplier';
import { ProductPage } from 'src/pages/product';
import { CustomerPage } from 'src/pages/customer';
import { ExportPage } from 'src/pages/export';
import { DeliveryPage } from 'src/pages/delivery';
import { EditExportPage } from 'src/pages/edit-export';
import { UserPage } from 'src/pages/user';
import { RolePage } from 'src/pages/role';
import { LoanPage } from 'src/pages/loan';
import { AgriProductPage } from 'src/pages/agri-product';
import { BuyAgriProductPage } from 'src/pages/buy-agri-product';
import { PaymentPage } from 'src/pages/payment';
import { LogPage } from 'src/pages/log';
import { CategoryPage } from 'src/pages/category';
import { EditBuyAgriProductGuestPage } from 'src/pages/edit-buy-agri-guest';
import { EditBuyAgriProductAgencyPage } from 'src/pages/edit-buy-agri-agency';
import { SummaryPage } from 'src/pages/summary';
import { FinalProductPage } from 'src/pages/final-product';
import { DayOffPage } from 'src/pages/day-off';
import { BuyAgriProductAgencyPage } from 'src/pages/buy-agri-product-agency';
import { SaleAgriProductPage } from 'src/pages/sale-agri-product';
import { EditSaleAgriProductPage } from 'src/pages/edit-sale-agri';
import { SaleFinalProductPage } from 'src/pages/sale-final-product';
import { EditSaleFinalProductPage } from 'src/pages/edit-sale-final-product';
import { MyDeliveryPage } from 'src/pages/delivery-mine';
import { UserSession } from './user-session';
import { DeliveryHistoryPage } from 'src/pages/delivery-history';
import { PermissionPage } from 'src/pages/permission';
import { EditExportGuestPage } from 'src/pages/edit-export-guest';
import { CustomerDebtPage } from 'src/pages/customer-debt';
import DevPage from 'src/pages/dev';
import { BuyAgriProductHistoryPage } from 'src/pages/buy-agri-product-history';
import { TemplatePage } from 'src/pages/template';
import { CustomerLevel } from 'src/api';
import { CustomerNegativeDebtPage } from 'src/pages/customer-negative-debt';

class Route {
  public readonly pathPatterns = {
    home: '/dashboard',
    login: '/login',
    import: '/import',
    editImport: '/import/:importId',
    export: '/export',
    exportCustomer: '/export/:customerId',
    delivery: '/delivery',
    myDelivery: '/my-delivery',
    deliveryHistory: '/history-delivery',
    deliveryHistoryCustomer: '/history-delivery/:customerId',
    // exportMyShip: '/my-export',
    editExportGuest: '/edit-export/guest/:exportId',
    editExport: '/edit-export/:exportId',
    supplier: '/supplier',
    category: '/category',
    product: '/product',
    customer: '/customer',
    customerDebt: '/customer-debt',
    customerNegativeDebt: '/customer-negative-debt',
    agriProduct: '/agri-product',
    finalProduct: '/final-product',
    buyAgriProductGuest: '/buy-agri-product-guest',
    buyAgriProductGuestCustomer: '/buy-agri-product-guest/:customerId',
    buyAgriProductAgency: '/buy-agri-product-agency',
    buyAgriProductAgencyCustomer: '/buy-agri-product-agency/:customerId',
    editBuyAgriProductGuest: '/buy-agri-product-guest/:buyAgriProductId/:customerId',
    editBuyAgriProductAgency: '/buy-agri-product-agency/:buyAgriProductId/:customerId',
    buyAgriProductHistory: '/buy-agri-product-history',
    saleAgriProduct: '/sale-agri-product',
    editSaleAgriProduct: '/sale-agri-product/:saleAgriProductId',
    saleFinalProduct: '/sale-final-product',
    editSaleFinalProduct: '/sale-final-product/:saleFinalProductId',
    payment: '/payment',
    paymentCustomer: '/payment/:customerId',
    loan: '/loan',
    loanCustomer: '/loan/:customerId',
    summary: '/summary/:customerId',
    summarySearch: '/summary',
    store: '/store',
    user: '/user',
    role: '/role',
    log: '/log',
    permission: '/permission',
    dayOff: '/day-off',
    dayOffUser: '/day-off/:userId',
    template: '/template',
  }

  constructor() {
    Object.freeze(this.pathPatterns);
  }

  public login() {
    return this.pathPatterns.login;
  }

  public home() {
    return this.pathPatterns.home;
  }

  public get import() {
    return this.pathPatterns.import;
  }

  public editImport(importId?: number) {
    return generatePath(this.pathPatterns.editImport, { importId: importId || '*' });
  }

  public dayOffUser(userId?: number) {
    return generatePath(this.pathPatterns.dayOffUser, { userId: userId || '*' });
  }

  public summary(customerId?: number) {
    return generatePath(this.pathPatterns.summary, { customerId: customerId || '*' });
  }

  public get saleAgriProduct() {
    return this.pathPatterns.saleAgriProduct;
  }

  public editSaleAgriProduct(saleAgriProductId?: number) {
    return generatePath(this.pathPatterns.editSaleAgriProduct, { saleAgriProductId: saleAgriProductId || '*' });
  }

  public get saleFinalProduct() {
    return this.pathPatterns.saleFinalProduct;
  }

  public editSaleFinalProduct(saleFinalProductId?: number) {
    return generatePath(this.pathPatterns.editSaleFinalProduct, { saleFinalProductId: saleFinalProductId || '*' });
  }

  public export(customerId?: number) {
    return generatePath(this.pathPatterns.exportCustomer, { customerId: customerId || '*' });
  }

  public deliveryHistory(customerId?: number) {
    return generatePath(this.pathPatterns.deliveryHistoryCustomer, { customerId: customerId || '*' });
  }

  public editExport(exportId?: number) {
    return generatePath(this.pathPatterns.editExport, { exportId: exportId || '*' });
  }

  public editExportGuest(exportId?: number) {
    return generatePath(this.pathPatterns.editExportGuest, { exportId: exportId || '*' });
  }

  public loan(customerId?: number) {
    return generatePath(this.pathPatterns.loanCustomer, { customerId: customerId || '*' });
  }

  public buyAgriProductGuest(customerId?: number) {
    return generatePath(this.pathPatterns.buyAgriProductGuestCustomer, { customerId: customerId || '*' });
  }

  public buyAgriProductAgency(customerId?: number) {
    return generatePath(this.pathPatterns.buyAgriProductAgencyCustomer, { customerId: customerId || '*' });
  }

  public payment(customerId?: number) {
    return generatePath(this.pathPatterns.paymentCustomer, { customerId: customerId || '*' });
  }

  public editBuyAgriProduct(params: { customerLevel: CustomerLevel, buyAgriProductId?: number | null, customerId?: number }) {
    const { customerLevel, buyAgriProductId, customerId } = params;
    return generatePath(
      customerLevel === CustomerLevel.Agency ?
        this.pathPatterns.editBuyAgriProductAgency :
        this.pathPatterns.editBuyAgriProductGuest,
      {
        buyAgriProductId: buyAgriProductId || '*',
        customerId: customerId || '*',
      });
  }

  public get product() {
    return this.pathPatterns.product;
  }

  public getPageTitle(path: string) {
    let r = routes.find(x => x.path === path);
    if (r != null) { return `ERP Toàn Hằng - ${r.title}`; }

    r = routes.find(x => x.pattern?.test(path));
    if (r != null) { return `ERP Toàn Hằng - ${r.title}`; }

    return 'ERP Toàn Hằng';
  }

  public getFirstPage(userSession: UserSession) {
    for (const routeItem of routes) {
      const { hidden, pattern } = routeItem;
      if (hidden) {
        continue;
      }

      if (pattern != null) {
        const hasPermission =
          userSession.isAdmin ||
          userSession.permissions.some((x) => pattern.test(x.path));
        if (!hasPermission) {
          continue;
        }
      }

      return routeItem.path;
    }
    return '/';
  }
}

export const route = new Route();

interface IRoute {
  path: string;
  title: string;
  hidden?: boolean;
  component: any; // React.ComponentType,
  pattern?: RegExp;
  mobileSupported?: boolean;
  onClick?: () => void;
}

export const routes: IRoute[] = [
  { path: route.pathPatterns.home, title: 'Dashboard', component: HomePage, pattern: /^\/dashboard/ },
  { path: route.pathPatterns.export, title: 'Bán Phân Bón', component: ExportPage, pattern: /^\/export/, mobileSupported: true },
  { path: route.pathPatterns.exportCustomer, title: 'Bán Phân Bón', component: ExportPage, pattern: /^\/export/, hidden: true },
  { path: route.pathPatterns.myDelivery, title: 'Phiếu Giao Của Tôi', component: MyDeliveryPage, pattern: /^\/my-delivery/, mobileSupported: true },
  { path: route.pathPatterns.delivery, title: 'Phiếu Giao Hàng', component: DeliveryPage, pattern: /^\/delivery/, mobileSupported: true },
  { path: route.pathPatterns.deliveryHistory, title: 'Nhật Ký Bán Phân Bón', component: DeliveryHistoryPage, pattern: /^\/history-delivery/ },
  { path: route.pathPatterns.editExport, title: 'Hóa Đơn Bán Phân Bón', component: EditExportPage, pattern: /^\/edit-export/, hidden: true },
  { path: route.pathPatterns.editExportGuest, title: 'Bán Lẻ - Hóa Đơn Bán Phân Bón', component: EditExportGuestPage, pattern: /^\/edit-export/, hidden: true },
  { path: route.pathPatterns.import, title: 'Nhập Phân Bón', component: ImportPage, pattern: /^\/import/, mobileSupported: true },
  { path: route.pathPatterns.editImport, title: 'Phiếu Nhập Phân Bón', component: EditImportPage, pattern: /^\/import/, hidden: true },
  { path: route.pathPatterns.product, title: 'Phân Bón', component: ProductPage, pattern: /^\/product/ },
  { path: route.pathPatterns.supplier, title: 'Đại Lý Nhập Phân Bón', component: SupplierPage, pattern: /^\/supplier/ },
  { path: route.pathPatterns.category, title: 'Phân Loại Phân Bón', component: CategoryPage, pattern: /^\/category/ },
  { path: route.pathPatterns.customer, title: 'Khách Hàng', component: CustomerPage, pattern: /^\/customer\/?$/ },
  { path: route.pathPatterns.customerDebt, title: 'Khách Hàng Công Nợ', component: CustomerDebtPage, pattern: /^\/customer-debt\/?$/ },
  { path: route.pathPatterns.customerNegativeDebt, title: 'Khách Hàng Giao Thiếu', component: CustomerNegativeDebtPage, pattern: /^\/customer-negative-debt\/?$/ },
  { path: route.pathPatterns.summarySearch, title: 'Đối Chiếu Công Nợ', component: SummaryPage, pattern: /^\/summary/ },
  { path: route.pathPatterns.summary, title: 'Đối Chiếu Công Nợ', component: SummaryPage, pattern: /^\/summary/, hidden: true },
  { path: route.pathPatterns.buyAgriProductGuest, title: 'Thu Mua Nông Sản - Khách Lẻ', component: BuyAgriProductPage, pattern: /^\/buy-agri-product-guest/, mobileSupported: true },
  { path: route.pathPatterns.buyAgriProductGuestCustomer, title: 'Thu Mua Nông Sản - Khách Lẻ', component: BuyAgriProductPage, pattern: /^\/buy-agri-product-guest/, hidden: true },
  { path: route.pathPatterns.buyAgriProductAgency, title: 'Thu Mua Nông Sản - Đại Lý', component: BuyAgriProductAgencyPage, pattern: /^\/buy-agri-product-agency/, mobileSupported: true },
  { path: route.pathPatterns.buyAgriProductAgencyCustomer, title: 'Thu Mua Nông Sản - Đại Lý', component: BuyAgriProductAgencyPage, pattern: /^\/buy-agri-product-agency/, hidden: true },
  { path: route.pathPatterns.buyAgriProductHistory, title: 'Lịch Sử Mua Hàng', component: BuyAgriProductHistoryPage, pattern: /^\/buy-agri-product-history/ },
  { path: route.pathPatterns.editBuyAgriProductGuest, title: 'Mua Khách Lẻ', component: EditBuyAgriProductGuestPage, pattern: /^\/buy-agri-product-guest/, hidden: true },
  { path: route.pathPatterns.editBuyAgriProductAgency, title: 'Mua Đại Lý', component: EditBuyAgriProductAgencyPage, pattern: /^\/buy-agri-product-agence/, hidden: true },
  { path: route.pathPatterns.agriProduct, title: 'Hàng Nông Sản', component: AgriProductPage, pattern: /^\/agri-product/ },
  { path: route.pathPatterns.saleAgriProduct, title: 'Xuất Kho Nông Sản', component: SaleAgriProductPage, pattern: /^\/sale-agri-product/ },
  { path: route.pathPatterns.finalProduct, title: 'Thành Phẩm', component: FinalProductPage, pattern: /^\/final-product/ },
  { path: route.pathPatterns.editSaleAgriProduct, title: 'Xuất Kho Nông Sản', component: EditSaleAgriProductPage, pattern: /^\/sale-agri-product/, hidden: true },
  { path: route.pathPatterns.saleFinalProduct, title: 'Xuất Kho Thành Phẩm', component: SaleFinalProductPage, pattern: /^\/sale-final-product/ },
  { path: route.pathPatterns.editSaleFinalProduct, title: 'Xuất Kho Thành Phẩm', component: EditSaleFinalProductPage, pattern: /^\/sale-final-product/, hidden: true },
  { path: route.pathPatterns.loan, title: 'Khoản Vay', component: LoanPage, pattern: /^\/loan/ },
  { path: route.pathPatterns.loanCustomer, title: 'Khoản Vay', component: LoanPage, pattern: /^\/loan/, hidden: true },
  { path: route.pathPatterns.payment, title: 'Lịch Sử Thanh Toán', component: PaymentPage, pattern: /^\/payment/ },
  { path: route.pathPatterns.paymentCustomer, title: 'Lịch Sử Thanh Toán', component: PaymentPage, pattern: /^\/payment/, hidden: true },
  { path: route.pathPatterns.user, title: 'Nhân Viên', component: UserPage, pattern: /^\/user/ },
  { path: route.pathPatterns.dayOff, title: 'Nghỉ Phép', component: DayOffPage, pattern: /^\/day-off/ },
  { path: route.pathPatterns.dayOffUser, title: 'Nghỉ Phép', component: DayOffPage, pattern: /^\/day-off/, hidden: true },
  { path: route.pathPatterns.role, title: 'Chức Vụ - Phân Quyền', component: RolePage, pattern: /^\/role/ },
  { path: route.pathPatterns.log, title: 'Log', component: LogPage, pattern: /^\/log/ },
  { path: route.pathPatterns.permission, title: 'Permission', component: PermissionPage, pattern: /^\/permission/ },
  { path: route.pathPatterns.template, title: 'Biểu Mẫu', component: TemplatePage, pattern: /^\/template/ },
];

routes.push({
  path: '/dev',
  component: DevPage,
  title: 'Dev',
  hidden: true,
});
