const MILLISECONDS_IN_A_SECOND: number = 1000;
const SECONDS_IN_A_MINUTE: number = 60;
const MINUTES_IN_AN_HOUR: number = 60;
const HOURS_IN_A_DAY: number = 24;

const MILLISECONDS_IN_A_MINUTE = MILLISECONDS_IN_A_SECOND * SECONDS_IN_A_MINUTE;
const MILLISECONDS_IN_AN_HOUR = MILLISECONDS_IN_A_MINUTE * MINUTES_IN_AN_HOUR;
const MILLISECONDS_IN_A_DAY = MILLISECONDS_IN_AN_HOUR * HOURS_IN_A_DAY;

export class TimeSpan {
    /** create TimeSpan from milliseconds: number. Input value can be negative */
    public static fromTicks(ticks: number) {
        if (ticks == null) {
            throw new Error('Ticks is null');
        }
        const timeSpan = new TimeSpan();
        if (ticks < 0) {
            timeSpan._isPositive = false;
        }
        timeSpan._milliseconds = Math.abs(ticks);
        timeSpan.calcMilliSeconds();
        return timeSpan;
    }

    /** create TimeSpan from 'input' string: 101.06:39:58.6218110 || 00:00:00 || -05:49:06.1940000 */
    public static fromString(input: string): TimeSpan {
        if (input == null) {
            throw new Error('Input is null');
        }

        const timeSpan = new TimeSpan();
        if (input.indexOf('-') === 0) {
            timeSpan._isPositive = false;
            input = input.substring(1);
        }

        const [dayHourStr, minuteStr, secondStr] = input.split(':');
        if (!dayHourStr || !minuteStr || !secondStr) {
            throw new Error('Input string wrong format.');
        }

        if (dayHourStr.indexOf('.') !== -1) {
            const [day, hour]: string[] = dayHourStr.split('.');
            timeSpan._days = Number(day);
            timeSpan._hours = Number(hour);
        }
        else {
            timeSpan._hours = Number(dayHourStr);
        }

        timeSpan._minutes = Number(minuteStr);

        // skip set second, and set milisecond = secondStr * 1000
        timeSpan._milliseconds = Math.round(Number(secondStr) * 1000);

        timeSpan.calcMilliSeconds();
        return timeSpan;
    }

    // tslint:disable:variable-name
    private _isPositive = true;
    private _totalMilliSeconds = 0;
    private _milliseconds = 0;
    private _seconds = 0;
    private _minutes = 0;
    private _hours = 0;
    private _days = 0;
    // tslint:enable:variable-name
    
    /** Return day property of instance. Value can be Positive or Negative */
    public get days(): number { return (this._isPositive ? 1 : -1) * this._days; }

    /** Return hours property of instance. Value can be Positive or Negative */
    public get hours(): number { return (this._isPositive ? 1 : -1) * this._hours; }

    /** Return minutes property of instance. Value can be Positive or Negative */
    public get minutes(): number { return (this._isPositive ? 1 : -1) * this._minutes; }

    /** Return seconds property of instance. Value can be Positive or Negative */
    public get seconds(): number { return (this._isPositive ? 1 : -1) * this._seconds; }

    /** Return milliseconds property of instance. Value can be Positive or Negative */
    public get milliseconds(): number { return (this._isPositive ? 1 : -1) * this._milliseconds; }

    /** Return total milliseconds of instance. Value can be Positive or Negative. 
     * Type: decimal
     */
    public get ticks() { return (this._isPositive ? 1 : -1) * this._totalMilliSeconds; }

    /** Return total seconds of instance. Value can be Positive or Negative. 
     * Type: decimal
     */
    public get totalSeconds() {
        return (this._isPositive ? 1 : -1) * this._totalMilliSeconds / MILLISECONDS_IN_A_SECOND;
    }

    /** Return total minutes of instance. Value can be Positive or Negative. 
     * Type: decimal
     */
    public get totalMinutes() {
        return (this._isPositive ? 1 : -1) * this._totalMilliSeconds / MILLISECONDS_IN_A_MINUTE;
    }

    /** Return total hours of instance. Value can be Positive or Negative. 
     * Type: decimal
     */
    public get totalHours() {
        return (this._isPositive ? 1 : -1) * this._totalMilliSeconds / MILLISECONDS_IN_AN_HOUR;
    }

    /** Return total days of instance. Value can be Positive or Negative. 
     * Type: decimal
     */
    public get totalDays() {
        return (this._isPositive ? 1 : -1) * this._totalMilliSeconds / MILLISECONDS_IN_A_DAY;
    }

    private roundValue(origValue: number, maxValue: number) {
        return { modulu: origValue % maxValue, addition: Math.floor(origValue / maxValue) };
    }

    private calcMilliSeconds() {
        const newMilliSecond = this.roundValue(this._milliseconds, MILLISECONDS_IN_A_SECOND);
        this._milliseconds = newMilliSecond.modulu;
        this._seconds += newMilliSecond.addition;

        const newSecond = this.roundValue(this._seconds, SECONDS_IN_A_MINUTE);
        this._seconds = newSecond.modulu;
        this._minutes += newSecond.addition;

        const newminutes = this.roundValue(this._minutes, MINUTES_IN_AN_HOUR);
        this._minutes = newminutes.modulu;
        this._hours += newminutes.addition;

        const newDays = this.roundValue(this._hours, HOURS_IN_A_DAY);
        this._hours = newDays.modulu;
        this._days += newDays.addition;

        this._totalMilliSeconds = this._days * MILLISECONDS_IN_A_DAY
            + this._hours * MILLISECONDS_IN_AN_HOUR
            + this._minutes * MILLISECONDS_IN_A_MINUTE
            + this._seconds * MILLISECONDS_IN_A_SECOND
            + this._milliseconds;
    }
}