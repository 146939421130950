import * as log from 'loglevel';

export function getSessionStorageItem(key: string): object | null {
  if (typeof (Storage) === 'undefined') {
    return null;
  }

  const json = window.sessionStorage.getItem(`nlx.${key}`);
  if (json) {
    try {
      return JSON.parse(json);
    }
    catch (error) {
      log.warn(`Can't deserialize session storage object [${key}]. ${error}`);
    }
  }
  return null;
}

export function setSessionStorageItem(key: string, value: object | null): boolean {
  if (typeof (Storage) === 'undefined') { return false; }
  let json: string | undefined;

  if (value != null) {
    try {
      json = JSON.stringify(value);
    }
    catch (error) {
      log.warn(`Can't serialize session storage object [${key}]. ${error}`);
    }
  }

  try {
    if (json == null) {
      window.sessionStorage.removeItem(`nlx.${key}`);
    }
    else {
      window.sessionStorage.setItem(`nlx.${key}`, json);
    }
  }
  catch (error) {
    return false;
  }
  return true;
}

export function getLocalStorageItem(key: string): object | null {
  if (typeof (Storage) === 'undefined') {
    return null;
  }

  const json = window.localStorage.getItem(`nlx.${key}`);
  if (json) {
    try {
      return JSON.parse(json);
    }
    catch (error) {
      log.warn(`Can't deserialize session storage object [${key}]. ${error}`);
    }
  }
  return null;
}

export function setLocalStorageItem(key: string, value: object | null): boolean {
  if (typeof (Storage) === 'undefined') { return false; }
  let json: string | undefined;

  if (value != null) {
    try {
      json = JSON.stringify(value);
    }
    catch (error) {
      log.warn(`Can't serialize session storage object [${key}]. ${error}`);
    }
  }

  try {
    if (json == null) {
      window.localStorage.removeItem(`nlx.${key}`);
    }
    else {
      window.localStorage.setItem(`nlx.${key}`, json);
    }
  }
  catch (error) {
    return false;
  }
  return true;
}