import { fetchHelper } from 'src/app/fetch-helper';
import { logger } from 'src/app';
import { apiEndPoint } from './api-endpoint';

interface LoginReq {
  username: string,
  password: string,
}

interface LoginResp {
  id: number;
  accessToken: string;
  roleName: string;
  roleDescription?: string; 
  username: string;
  email: string;
  phone: string;
  displayName: string;
  permissions: Array<{ name: string, path: string }>;
  profile?: string;
  stores: StoreModel[]; 
}

class SecurityClient {
  public async login(params: LoginReq): Promise<LoginResp> {
    const url = apiEndPoint.login;
    const formData = new URLSearchParams();
    formData.append('grant_type', 'password');
    formData.append('username', params.username);
    formData.append('password', params.password);

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Authorization': `Basic S2h1ZUNsaWVudDpmMmExZWQ1MjcxMGQ0NTMzYmRlMjViZTZkYTAzYjZlMw==`
        },
        body: formData
      });

      const json = await response.json();
      return json.data as LoginResp;
    }
    catch (ex) {
      const msg = `Login failed. ${ex}.`
      logger.error(msg);
      throw new Error(msg);
    }
  }

  public async logout(params: { token: string }) {
    await fetchHelper('./logout', 'POST', params);
  }

  public async updateProfile(profile: string) {
    await fetchHelper(apiEndPoint.profile, 'POST', { profile });
  }
}
export const securityClient = new SecurityClient();