import * as React from 'react';
import { List } from 'semantic-ui-react';

export const ContactInfo = (props: { phone?: string, address?: string, email?: string }) => (
  <List>
    <List.Item>
      <List.Icon name='phone' />
      <List.Content>{props.phone}</List.Content>
    </List.Item>
    <List.Item>
      <List.Icon name='marker' />
      <List.Content>{props.address}</List.Content>
    </List.Item>
    <List.Item>
      <List.Icon name='mail' />
      <List.Content>{props.email}</List.Content>
    </List.Item>
  </List>
)