import { fetchHelper } from 'src/app/fetch-helper';
import { apiEndPoint } from './api-endpoint';

class CategoryProvider {
  private cacheActiveCategories: CategoryModel[] | undefined;

  public async findCategory(id: number) {
    return await fetchHelper<CategoryModel>(`${apiEndPoint.category}/${id}`, 'GET');
  }

  public async searchCategories(params: { name?: string } & Paging) {
    const response = await fetchHelper<GetCategoriesResp>(apiEndPoint.category, 'GET', params);
    return response.items;
  }

  public async editCategory(body: CategoryModel): Promise<CategoryModel> {
    let response: CategoryModel;
    if (body.id == null || body.id === 0) {
      response = await fetchHelper<CategoryModel>(apiEndPoint.category, 'POST', body);
    } else {
      response = await fetchHelper<CategoryModel>(`${apiEndPoint.category}/${body.id}`, 'PUT', body);
    }
    this.cacheActiveCategories = undefined;
    return response;
  }

  public async getActiveCategories() {
    if (this.cacheActiveCategories == null) {
      this.cacheActiveCategories = await this.searchCategories({});
    }

    return this.cacheActiveCategories;
  }

}
export const categoryProvider = new CategoryProvider();