import * as React from 'react';
import { AppEvent, EventSubscription } from '../app';
import AppStatus from 'src/components/app-status';
import AppAlert from 'src/components/app-alert';
import InlineSVG from 'react-inlinesvg';

export default class LayoutUtils extends React.Component<{}, {}> {
  private events = new EventSubscription();
  constructor(props: {}) {
    super(props);
  }

  public render() {
    return (
      <>
        <InlineSVG uniquifyIDs={false} style={{ display: 'none' }} src={require('src/media/icons-sprite/icons.svg')} />
        <AppStatus />
        <AppAlert />
      </>
    );
  }

  public componentDidMount() {
    this.events.subscribeOnce(AppEvent.UserLogin, () => this.forceUpdate());
  }

  public componentWillUnmount() {
    this.events.unsubcribeAll();
  }
}