import * as React from 'react';
import { AppEvent, EventSubscription } from 'src/app';
import classNames from 'classnames';

interface AppStatusState {
  active: boolean;
  statusMessage?: string;
  disableInteraction?: boolean;
}

export default class AppStatus extends React.Component<{}, AppStatusState> {
  private readonly events = new EventSubscription();

  constructor(props: {}) {
    super(props);
    this.state = {
      active: false
    };
    this.onStatusChange = this.onStatusChange.bind(this);
  }

  public render() {
    const { active, statusMessage, disableInteraction } = this.state;
    return (
      <div className={classNames('x-app-status', { 'active': active, 'x-app-status__center': disableInteraction })}>
        <span>
          {disableInteraction &&
            <i className='x-loading-icon' />
          }
          {statusMessage}
        </span>
      </div>
    );
  }

  public componentDidMount() {
    this.events.subscribe(AppEvent.StatusChange, this.onStatusChange);
  }

  public componentWillUnmount() {
    this.events.unsubcribeAll();
  }

  private onStatusChange(args: any[]) {
    const statusMessage: string = args[0].statusMessage;
    const disableInteraction: boolean = args[0].disableInteraction;
    if (!statusMessage) {
      this.setState({ active: false, disableInteraction: false });
    }
    else {
      this.setState({ active: true, statusMessage, disableInteraction });
    }
  }
}