import { fetchHelper } from 'src/app/fetch-helper';
import { apiEndPoint } from './api-endpoint';

class ImportProvider {
  public getById(id: number) {
    return fetchHelper<ImportModel>(`${apiEndPoint.import}/${id}`, 'GET');
  }

  public async searchImports(params: ImportCriteria) {
    const cloneParams = { ...params };

    const response = await fetchHelper<GetImportsResp>(apiEndPoint.import, 'GET', cloneParams);
    return response.items;
  }

  public async editImport(params: ImportModel): Promise<ImportModel> {
    const body = { ...params };
    if (!params.id || params.id === 0) {
      return fetchHelper<ImportModel>(apiEndPoint.import, 'POST', body);
    } else {
      return fetchHelper<ImportModel>(`${apiEndPoint.import}/${body.id}`, 'PUT', body);
    }
  }

  public async deleteImport(id: number) {
    return await fetchHelper(`${apiEndPoint.import}/${id}`, 'DELETE');
  }
}
export const importProvider = new ImportProvider();