import * as React from 'react';
import * as Rx from 'rxjs';
import { debounce, isEqual } from 'lodash';
import { Formik, FormikProps, Field } from 'formik';
import { Modal, Button, Form, DropdownItemProps } from 'semantic-ui-react';
import { Select } from '../select';
import SelectBox from '../select-box';
import { Checkbox } from '../checkbox';
import { IWfChartSetting } from './common';
import { wfChartManager } from './wf-chart-manager';
import { IChartDataProvider } from './data-provider/chart-data-provider';

function getDataProvidersByCategory(category: string): IChartDataProvider[] {
  return wfChartManager.getDataProviders()
    .filter(x => x.metadata.category === category);
}

function getCategories() {
  const categories = wfChartManager.getDataProviders()
    .map(x => x.metadata.category)
    .filter((x, i, self) => self.indexOf(x) === i); // get distinct item

  return categories;
}

export interface ChartSettingDialogSubmitParams {
  dataProviderId: string;
  unit: string;
  title?: string;
  showAdvanceSetting?: boolean;
  compare?: CompareItems;
  compareMode?: CompareMode;
  compareDateIso?: string;
  groupsFilter?: string[] | null;
  queuesFilter?: string[] | null;
  isAllGroupSelected?: boolean;
  isAllQueuesSelected?: boolean;
}

interface IChartSettingProps {
  settings: IWfChartSetting;
  onClose: () => void;
  onSubmit: (params: ChartSettingDialogSubmitParams) => Promise<void>;
}

interface IChartSettingState {
  showAdvanceSetting: boolean;
  category: string;
  dataProviderId: string;
  unit: string;
  title: string;
  groupsFilter?: string[] | null;
  queuesFilter?: string[] | null;
  compare: CompareItems;
  compareMode: CompareMode;
  compareDate: Date;
  compareTime: Date;
  groupOptions?: Array<{ value: string }>;
  queueOptions?: Array<{ value: string }>;
}

class ChartSettingComponent extends React.Component<IChartSettingProps, IChartSettingState>{
  private lastCompareMode: CompareMode;
  private lastCompare: CompareItems;
  private lastCategory: string;
  private lastDataProviderId?: string;
  private fetchSubscription?: Rx.Subscription;
  private categoryOptions: DropdownItemProps[];

  constructor(props: IChartSettingProps) {
    super(props);

    const { dataProviderId, groupsFilter, title, compare,
      compareMode, compareDateIso, queuesFilter, showAdvanceSetting } = this.props.settings;
    let { unit } = this.props.settings;
    const dataProvider = wfChartManager.getDataProvider(dataProviderId);
    const units = dataProvider == null ? [] : dataProvider.metadata.unitList;
    if (units.length > 0 && (unit == null || units.indexOf(unit) === -1)) {
      unit = units[0];
    }
    let compareDateTime;
    if (compareDateIso) {
      compareDateTime = new Date(compareDateIso);
    }
    else {
      compareDateTime = new Date();
      compareDateTime.setHours(23, 59, 59);
    }
    this.categoryOptions = getCategories().map(x => ({ value: x, text: x }));

    this.state = {
      showAdvanceSetting: showAdvanceSetting || false,
      dataProviderId,
      unit: unit || '',
      groupsFilter,
      title: title || '',
      compare: compare || 'None',
      compareMode: compareMode || 'current-hour',
      compareDate: compareDateTime,
      compareTime: compareDateTime,
      queuesFilter,
      category: dataProvider == null ? '' : dataProvider.metadata.category
    };

    this.lastCompareMode = compareMode || 'current-hour';
    this.lastCompare = compare || 'None';
    this.lastCategory = dataProvider == null ? '' : dataProvider.metadata.category;
    this.lastDataProviderId = dataProviderId;
  }

  public componentDidMount() {
    window.addEventListener("beforeunload", this.handleWindowUnload);
  }

  public componentWillUnmount() {
    if (this.fetchSubscription != null) {
      this.fetchSubscription.unsubscribe();
    }
    window.removeEventListener("beforeunload", this.handleWindowUnload);
  }

  public render() {
    return (
      <Formik
        initialValues={this.state}
        enableReinitialize={true}
        render={this.renderForm}
        validate={this.handleValidate}
        onSubmit={this.handleSubmit}
      />
    );
  }

  private handleWindowUnload = (e: BeforeUnloadEvent) => {
    e.preventDefault();
    return e.returnValue = 'Changes you made may not be saved.';
  }

  private renderForm = (formikProps: FormikProps<IChartSettingState>) => {
    const { showAdvanceSetting, dataProviderId, unit, groupsFilter, title,
      category, groupOptions, } = formikProps.values;
    const dataProvider = wfChartManager.getDataProvider(dataProviderId);
    if (dataProvider == null) {
      return null;
    }
    const unitOptions = dataProvider.metadata.unitList
      .map(x => ({ text: x, value: x }));
    const dataProviderItems = getDataProvidersByCategory(category)
      .map(x => ({ text: x.metadata.name, value: x.id }));

    const displayChartName = dataProvider.metadata.name;
    const displayChartUnit = unit;

    const okButtonClick = () => formikProps.submitForm();
    const { onClose } = this.props;
    const isSubmitting = formikProps.isSubmitting;

    return (
      <Modal open={true} centered={false} closeIcon={true} onClose={onClose} closeOnDimmerClick={false}>
        <Modal.Header content={displayChartName} />
        <Modal.Content>
          <Form onSubmit={debounce(() => okButtonClick())}>
            <Form.Group widths="equal">
              <Form.Field>
                <label>Category</label>
                <Select formikProps={formikProps} name='category' value={category} options={this.categoryOptions} disabled={isSubmitting} openOnFocus={false} />
              </Form.Field>

              <Form.Field>
                <label>Chart</label>
                <Select formikProps={formikProps} name='dataProviderId' value={dataProviderId} options={dataProviderItems} disabled={isSubmitting} />
              </Form.Field>

              <Form.Field>
                <label>Unit</label>
                <Select formikProps={formikProps} name='unit' value={unit} options={unitOptions} disabled={isSubmitting} />
              </Form.Field>
            </Form.Group>

            <Form.Group>
              <Form.Field width={10}>
                <label>Title</label>
                <Field name='title' placeholder={`${displayChartName} (${displayChartUnit})`} value={title} disabled={isSubmitting} />
              </Form.Field>
            </Form.Group>

            <Form.Group>
              <Form.Field>
                <label>Group</label>
                <SelectBox formikProps={formikProps} name='groupsFilter'
                  options={groupOptions || []} selected={groupsFilter}
                  selectAll={true} disabled={groupOptions == null || isSubmitting} />
              </Form.Field>

              <Form.Field>
                <label>&nbsp;</label>
                <Checkbox formikProps={formikProps} name='showAdvanceSetting'
                  checked={showAdvanceSetting}
                  disabled={isSubmitting}
                  label='Show Advance Setting'
                />
              </Form.Field>
            </Form.Group>

            <button className='hidden-submit-button' type='submit' />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button primary={true} onClick={okButtonClick} disabled={isSubmitting} loading={isSubmitting} content='OK' />
          <Button onClick={onClose} disabled={isSubmitting} content='Cancel' />
        </Modal.Actions>
      </Modal>
    );
  }

  private handleSubmit = async (values: IChartSettingState) => {
    const compareDateTime = values.compareDate;
    compareDateTime.setHours(values.compareTime.getHours(), values.compareTime.getMinutes(), values.compareTime.getSeconds());
    const params: ChartSettingDialogSubmitParams = {
      dataProviderId: values.dataProviderId,
      unit: values.unit,
      title: values.title,
      compare: values.compare,
      groupsFilter: values.groupsFilter,
      queuesFilter: values.queuesFilter,
      compareMode: values.compareMode,
      compareDateIso: compareDateTime.toISOString(),
      showAdvanceSetting: values.showAdvanceSetting,
    };
    this.props.onSubmit(params);
  }

  private handleValidate = async (values: IChartSettingState) => {
    const currentState = { ...values };
    if (this.lastCategory !== values.category) {
      this.lastCategory = values.category;
      const dataProviderItems = getDataProvidersByCategory(values.category);
      if (dataProviderItems.length > 0) {
        currentState.dataProviderId = dataProviderItems[0].id;
      }
    }

    if (this.lastDataProviderId !== values.dataProviderId) {
      this.lastDataProviderId = values.dataProviderId;
      const dataProvider = wfChartManager.getDataProvider(values.dataProviderId)!;
      const units = dataProvider.metadata.unitList;
      if (units.length > 0 && (values.unit == null || units.indexOf(values.unit) === -1)) {
        currentState.unit = units[0];
      }
      currentState.title = '';
    }

    const now = new Date();
    if (this.lastCompareMode !== values.compareMode) {
      this.lastCompareMode = values.compareMode;
      const hour = values.compareMode === 'all-day' ? 23 : now.getHours();
      currentState.compareTime = new Date(now.getFullYear(), now.getMonth(), now.getDate(), hour, 59, 59);
    }

    if (this.lastCompare !== values.compare) {
      this.lastCompare = values.compare;
      switch (values.compare) {
        case 'Day':
          const oneDayBefore = new Date(now.getTime());
          oneDayBefore.setHours(-24);
          currentState.compareDate = oneDayBefore;
          break;
        case 'Week':
          const sevenDayBefore = new Date(now.getTime());
          sevenDayBefore.setHours(-24 * 7);
          currentState.compareDate = sevenDayBefore;
          break;
        case 'Month':
          const oneMonthBefore = new Date(now.getFullYear(), now.getMonth() - 1, now.getDate());
          currentState.compareDate = oneMonthBefore;
          break;
        case 'Year':
          const oneYearBefore = new Date(now.getFullYear() - 1, now.getMonth(), now.getDate());
          currentState.compareDate = oneYearBefore;
          break;
      }
    }

    if (!isEqual(currentState, values)) {
      this.setState(currentState);
    }
  }
}

const ChartSettingDialog = ChartSettingComponent;
export { ChartSettingDialog };