import { fetchHelper } from 'src/app/fetch-helper';
import { apiEndPoint } from './api-endpoint';

export const finalProductConvertProvider = {
  getById: (id: number) => {
    return fetchHelper<ConvertAgriProduct>(`${apiEndPoint.finalProductConvert}/${id}`, 'GET');
  },

  getByParams: async (params: {}) => {
    const res = await fetchHelper<GetConvertAgriProductsResp>(apiEndPoint.finalProductConvert, 'GET', { size: 10_000, ...params });
    return res.items;
  },

  createOrUpdate: async (body: ConvertAgriProduct) => {
    let response: ConvertAgriProduct | undefined;
    if (!body.id) {
      response = await fetchHelper<ConvertAgriProduct>(apiEndPoint.finalProductConvert, 'POST', body);
    } else {
      response = await fetchHelper<ConvertAgriProduct>(`${apiEndPoint.finalProductConvert}/${body.id}`, 'PUT', body)
    }
    return response;
  },

  delete: (finalProductId: number) => {
    return fetchHelper(`${apiEndPoint.finalProductConvert}/${finalProductId}`, 'DELETE');
  }
};
