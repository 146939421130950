import * as log from 'loglevel';
import { debounce } from 'lodash';
import { getSessionStorageItem, setSessionStorageItem } from './session-storage';
import { appInfo } from './app-info';

const MAX_LOG_ENTRIES = 2000;
const LOG_ENTRIES_CLEANUP_POINT = 2500;

const debugLogEnabled = process && process.env != null && process.env.NODE_ENV !== 'production';
if (debugLogEnabled) {
    log.enableAll();
    log.info(`START LOGGER at ${new Date()}\nenv: ${JSON.stringify(process.env)}`);
}
else {
    log.disableAll();
}

type LogLevel = 'debug' | 'trace' | 'info' | 'warn' | 'error';
export interface LogEntry {
    level: LogLevel;
    index: number;
    timestamp: number;
    message: string;
}

class Logger {
    public readonly name: string;
    public readonly logEntries: LogEntry[] = [];

    /** Callback will be called by logger when new log entries appendded */
    public newLogEventCallback?: () => void;

    private counter: number = 0;

    private invokeNewLogEventCallback = debounce(() => {
        if (this.newLogEventCallback != null) {
            this.newLogEventCallback();
        }
    }, 200, { maxWait: 200 });

    private persistLogEntries = debounce(() => {
        // const startTime = Date.now();
        if (setSessionStorageItem(this.name, this.logEntries)) {
            // log.debug(`[logger] Persisted ${this.logEntries.length} log entries in ${Date.now() - startTime}ms.`);
        }
    }, 1000, { maxWait: 5000 });

    constructor() {
        this.name = appInfo.ename + '-logs';

        // Restore persisted log entries
        this.logEntries = getSessionStorageItem(this.name) as LogEntry[];
        if (!Array.isArray(this.logEntries)) {
            this.logEntries = [];
        }
        this.counter = this.logEntries.length;
        if (this.counter > 0) {
            this.writeLog('info', `***RESTORED ${this.counter} LOG ENTRIES FROM SESSION STORAGE***`);
        }
        this.writeLog('info', `Start ${appInfo.name} v${appInfo.version} logger session at ${new Date()}.`);
    }

    /** Clear entries stored in sessionStorage */
    public clear() {
        if (typeof (Storage) !== 'undefined') {
            try {
                this.logEntries.splice(0, this.logEntries.length);
                setSessionStorageItem(this.name, null);
            }
            catch (error) {
                log.error(`Cannot clear logs. ${error}`);
            }
        }
    }

    public debug(...msg: any[]) {
        this.writeLog('debug', ...msg);
    }
    public trace(...msg: any[]) {
        this.writeLog('trace', ...msg);
    }
    public info(...msg: any[]) {
        this.writeLog('info', ...msg);
    }
    public warn(...msg:any[]) {
        this.writeLog('warn', ...msg);
    }
    public error(...msg: any[]) {
        this.writeLog('error', ...msg);
    }

    public dumpObject(obj: any) {
        if (obj == null) {
            return 'NULL';
        }
        try {
            if (typeof(obj) === 'string') {
                return `'${obj}'`;
            }
            else if (typeof(obj) === 'number') {
                return `${obj}`;
            }
            return JSON.stringify(obj);
        }
        catch {
            return `[object ${typeof obj}]`;
        }
    }


    private writeLog(level: LogLevel, ...msg: any[]) {
        const entry: LogEntry = {
            level,
            message: this.formatLogMessage(msg),
            index: ++this.counter,
            timestamp: Date.now(),
        };

        this.logEntries.push(entry);

        if (this.logEntries.length > LOG_ENTRIES_CLEANUP_POINT) {
            this.logEntries.splice(0, this.logEntries.length - MAX_LOG_ENTRIES);
        }

        this.persistLogEntries();

        log[level](`${entry.index}`, ...msg);
        if (this.newLogEventCallback != null) {
            this.invokeNewLogEventCallback();
        }
    }

    private formatLogMessage(msg: any[]) {
        let str = '';
        for (const item of msg) {
            str += item + ' ';
        }
        return str;
    }
};

export const logger = new Logger();