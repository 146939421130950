import React, { Suspense, lazy } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Sidebar, Segment } from 'semantic-ui-react';
import { CustomerLevel } from 'src/api';
import { appConfig } from 'src/app';
import { route } from 'src/app/route';
import { HeaderBar } from 'src/components/header-bar';
import { parseNumber } from 'src/utils';
import MenuBar from './menu-bar';
const EditEditBuyAgriProduct = lazy(() => import('src/components/buy-agri-product/edit-buy-agri-product'));
const EditEditBuyAgriProductMobile = lazy(() => import('src/components/buy-agri-product/edit-buy-agri-product-mobile'));

interface IEditExportParams {
  buyAgriProductId?: string;
  customerId?: string;
}

export class EditBuyAgriProductAgencyPage extends React.PureComponent<
  RouteComponentProps<IEditExportParams, {}>
> {
  public componentDidMount() {
    document.title = route.getPageTitle(this.props.location.pathname);
  }

  public render() {
    const { buyAgriProductId, customerId } = this.props.match.params;
    const title = route.getPageTitle(this.props.location.pathname);

    return (
      <Sidebar.Pushable as={Segment} className='x-page'>
        <MenuBar />

        <Sidebar.Pusher>
          <Segment basic={true}>
            <HeaderBar title={title} />

            <Suspense fallback={<div className='x-loading' />}>
              {appConfig.isMobile ?
                <EditEditBuyAgriProductMobile
                  customerLevel={CustomerLevel.Agency}
                  buyAgriProductId={parseNumber(buyAgriProductId)}
                  customerId={parseNumber(customerId)}
                />
                :
                <EditEditBuyAgriProduct
                  customerLevel={CustomerLevel.Agency}
                  buyAgriProductId={parseNumber(buyAgriProductId)}
                  customerId={parseNumber(customerId)}
                />
              }
            </Suspense>
          </Segment>
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    );
  }
}
