import React from "react";
import { DropdownItemProps, Dropdown, Icon, Label, Popup } from "semantic-ui-react";
import { ExportModelStatus } from "src/api";
import { dateToString } from "src/utils";

const { CREATED, APPROVED, DONE, SHIPPING, ENOUGH } = ExportModelStatus;

const shipDetails = (model: ExportModel, allProducts: ProductModel[] | undefined) => {
  return (
    <>
      {model.exportDetails.map(detail => {
        const product = allProducts?.find(x => x.id === detail.productId);
        const deliveredQuantity = model.deliveryDetails?.filter(x => x.productId === product?.id)
          .reduce((t, e) => t + e.quantity, 0);

        return (
          <React.Fragment key={detail.id}>
            {product?.name}: <b>{deliveredQuantity || 0}/{detail.quantity}</b> {product?.unit}<br />
          </React.Fragment>
        )
      })}
      <hr />
      <span>Đ/c: <i>{model.customer?.address}</i></span>
    </>
  );
}

export const shipInfo = (model: ExportModel, allProducts: ProductModel[] | undefined) => {
  const deliveredQuantity = model.deliveryDetails?.reduce((t, e) => t + e.quantity, 0);
  const totalQuantity = model.exportDetails.reduce((t, e) => t + (e.quantity || 0), 0);
  const isShipping = model.status !== CREATED && deliveredQuantity !== 0 && deliveredQuantity !== totalQuantity;

  return (
    <Popup
      content={shipDetails(model, allProducts)}
      trigger={
        <span>
          {deliveredQuantity} / {totalQuantity} {' '}
          {isShipping && <Icon name='truck' color='blue' />}
        </span>
      }
    />
  );
}

export const getExportStatus = (model: ExportModel) => {
  const { status } = model;
  switch (status) {
    case CREATED:
      return <Label basic={true} color='pink' content='Mới Tạo' />;
    case APPROVED:
      return <Label basic={true} color='blue' content='Đã Duyệt' />;
    case SHIPPING:
      return <Label basic={true} color='yellow' content='Đang Giao' />;
    case ENOUGH:
      return <Label basic={true} color='olive' content='Giao Đủ' />;
    case DONE:
      return <Label basic={true} color='green' content='Xong' />;
  }
  return '';
};

export const exportStatusOptions: DropdownItemProps[] = [
  { value: ' ', text: 'Tất Cả' },
  { value: CREATED.toString(), text: 'Mới Tạo' },
  { value: APPROVED.toString(), text: 'Đã Duyệt' },
  { value: SHIPPING.toString(), text: 'Đang Giao' },
  { value: ENOUGH.toString(), text: 'Giao Đủ' },
  { value: DONE.toString(), text: 'Xong' },
  <Dropdown.Divider key='divider' />,
  { value: '-1', text: 'Đến Hạn' },
];

export const displayDueDate = (exportModel: ExportModel) => {
  const { dueDate, dueAmount } = exportModel;
  if (dueDate == null) {
    return '';
  }
  const dueDateD = new Date(dueDate);
  const now = new Date();
  if (dueDateD <= now) {
    return (
      <>
        {dateToString(dueDate)}
        {dueAmount !== 0 &&
          <Popup content='Quá hạn thanh toán' trigger={<Icon name='warning circle' color='red' />} />
        }
      </>
    );
  }

  now.setDate(now.getDate() + 3);
  now.setHours(23, 59, 59);
  if (dueDateD <= now) {
    return (
      <>
        {dateToString(dueDate)}
        {dueAmount !== 0 &&
          <Popup content='Gần đến hạn thanh toán' trigger={<Icon name='warning sign' color='yellow' />} />
        }
      </>
    );
  }

  return dateToString(dueDate);
};

export const detailViewDefault: DetailView = {
  id: 0,
  unitAmount: undefined,
  productId: undefined,
  productName: '',
  productUnit: '',
  productSellAmount: undefined,
  quantity: undefined,
};