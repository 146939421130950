import { fetchHelper } from 'src/app/fetch-helper';
import { apiEndPoint } from './api-endpoint';

export enum SupplierStatus {
  Undefined = -999,
  Active = 0,
  Inactive = 1,
  Deleted = 2
}

class SupplierProvider {
  public async getById(id: number | string) {
    return await fetchHelper<SupplierModel>(`${apiEndPoint.supplier}/${id}`, 'GET');
  }

  public async searchSuppliers(params?: SupplierCriteria) {
    let cloneParams: SupplierCriteria | undefined;
    if (params != null) {
      cloneParams = { ...params };
    }
    const response = await fetchHelper<GetSuppliersResp>(apiEndPoint.supplier, 'GET', cloneParams);
    return response.items;
  }

  public async editSupplier(body: SupplierModel): Promise<SupplierModel> {
    let response;
    if (!body.id || body.id === 0) {
      delete body.id;
      response = await fetchHelper<SupplierModel>(apiEndPoint.supplier, 'POST', body);
    } else {
      response = await fetchHelper<SupplierModel>(`${apiEndPoint.supplier}/${body.id}`, 'PUT', body);
    }

    return response;
  }

  public async deleteSupplier(id: number | string) {
    await fetchHelper(`${apiEndPoint.supplier}/${id}`, 'DELETE');
  }
}
export const supplierProvider = new SupplierProvider();