import * as React from 'react';
import * as FlexLayout from 'flexlayout-react';
import { defaultLayout } from './default-layout';
import { defaultLayoutMobile } from './default-layout-mobile';
import { userProfile } from 'src/app/user-profile';
import { wfChartManager } from '../chart';
import { ChartPanel } from '../chart/chart-panel';
import { appConfig } from 'src/app';

interface LayoutState {
  model: FlexLayout.Model;
}

export class Layout extends React.Component<IHomeState, LayoutState> {
  constructor(props: IHomeState) {
    super(props);
    if (userProfile.layoutModel == null) {
      userProfile.layoutModel = appConfig.isMobile ? defaultLayoutMobile : defaultLayout;
    }
    this.state = { model: FlexLayout.Model.fromJson(userProfile.layoutModel) };
  }

  public render() {
    return (
      <FlexLayout.Layout
        model={this.state.model}
        factory={this.factory}
        onModelChange={this.handleModelChange}
      />
    );
  }

  private factory = (node: FlexLayout.TabNode) => {
    const { fromDate, toDate } = this.props;
    // const model = node.getModel();

    // const component = node.getComponent();
    // return (<div className="panel">Panel {node.getName()} - {node.getId()} - {node.getComponent()}</div>);
    // if (component === "text") {
    // }
    // return null;
    return (
      <ChartPanel
        chartId={node.getId()}
        fromDate={fromDate}
        toDate={toDate}
        updateTitle={this.handelUpdateTitle}
      />
    );
  }

  private handleModelChange = (model: FlexLayout.Model) => {
    wfChartManager.saveInstances(model.toJson());
  }

  private handelUpdateTitle = (id: string, title: string) => {
    const { model } = this.state;
    model.doAction(FlexLayout.Actions.updateNodeAttributes(id, { name: title }))
  }
}
