import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Segment, Sidebar } from 'semantic-ui-react';
import { route } from 'src/app/route';
import { HeaderBar } from 'src/components/header-bar';
import { HomeComponent } from 'src/components/home/home-component';
import MenuBar from './menu-bar';

export class HomePage extends React.PureComponent<RouteComponentProps<{}>> {
  public componentDidMount() {
    document.title = route.getPageTitle(this.props.location.pathname);
  }

  public render() {
    const title = route.getPageTitle(this.props.location.pathname);
    return (
      <Sidebar.Pushable as={Segment} className='x-page'>
        <MenuBar />

        <Sidebar.Pusher >
          <Segment basic={true}>
            <HeaderBar title={title} />
            <HomeComponent />
          </Segment>

        </Sidebar.Pusher>
      </Sidebar.Pushable>
    );
  }
}