import { statisticProvider } from 'src/api';
import { IWfChartInstance, IWfChartData, IField, IRecord } from '../common';
import { IChartDataProvider, ChartDataBuilder } from './chart-data-provider';

const chartName = 'Nhập xuất phân bón trong tháng';

const fields: IField[] = [
  { name: 'Tên', dataType: 'string', summary: 'none' },
  { name: 'SL Bán (Bao)', dataType: 'numeric', summary: 'sum' },
  { name: 'KL Bán (KG)', dataType: 'numeric', summary: 'sum' },
  { name: 'Tiền Bán (VND)', dataType: 'numeric', summary: 'sum' },
  { name: 'SL Nhập (Bao)', dataType: 'numeric', summary: 'sum' },
  { name: 'KL Nhập (KG)', dataType: 'numeric', summary: 'sum' },
  { name: 'Tiền Nhập (VND)', dataType: 'numeric', summary: 'sum' },
];

async function loadData(instance: IWfChartInstance, criteria: { fromDate?: Date }) {
  const changingProducts = await statisticProvider.getChangingProducts(criteria);

  const records: IRecord[] = changingProducts.map(x => {
    return {
      values: [x.productName, x.exportQty, x.exportWeight, x.exportAmt, x.importQty, x.importWeight, x.importAmt],
    };
  });

  const wfChartData: IWfChartData = { fields, records };
  ChartDataBuilder.sortIWfChartData(wfChartData, instance.settings.sortBy, instance.settings.sortDirection);

  if (instance.settings.chartType === 'horizontalBar') {
    wfChartData.chartJsData = ChartDataBuilder.decorBarChartData({
      chartData: wfChartData,
    });
  }
  else if (instance.settings.chartType === 'pie') {
    wfChartData.chartJsData = ChartDataBuilder.decorPieChartData(wfChartData);
  }
  return wfChartData;
}

export const ProductChangingDataProvider: IChartDataProvider = {
  id: chartName,
  metadata: {
    name: chartName,
    category: 'Nhập Xuất Phân Bón',
    fieldNames: () => fields.map(x => x.name),
    unitList: ['Bao'],
    supportedChartTypes: ['grid']
  },
  defaultSettings: {
    chartType: 'grid',
    showLabel: true,
    sortBy: 0,
    sortDirection: 'asc',
    dataProviderId: chartName,
    unit: 'Bao',
    gridColumnWidths: [8, 6, 6, 7, 6, 6, 7],
  },
  loadData,
};