import { DropdownItemProps } from "semantic-ui-react";
import { appConfig, i18n } from "src/app";

const dateTimeFormatOp: Intl.DateTimeFormatOptions = {
  month: '2-digit',
  day: '2-digit',
  year: 'numeric',
  hour12: false,
  hour: '2-digit',
  minute: '2-digit',
}

const dateFormatOp: Intl.DateTimeFormatOptions = {
  month: '2-digit',
  day: '2-digit',
  year: 'numeric'
}

/** input is datetime format HH:mm, DD/MM */
export function sortDateTime(dateString?: string | Date) {
  if (!dateString) { return ''; }

  const date = new Date(dateString);
  const d = new Intl.DateTimeFormat(i18n.locale, {
    month: '2-digit',
    day: '2-digit',
    hour12: false,
    hour: '2-digit',
    minute: '2-digit',
  });
  return d.format(date);
}

/** input is string of datetime */
export function dateToString(dateString?: string | Date, isShowTime?: boolean) {
  if (!dateString) { return ''; }

  const date = new Date(dateString);
  const d = new Intl.DateTimeFormat(i18n.locale, isShowTime ? dateTimeFormatOp : dateFormatOp);
  return d.format(date);
}

/** input is Date of datetime */
export function dateFormat(date?: Date, isShowTime?: boolean) {
  if (!date) { return ''; }
  const cv = new Date(date);
  const d = new Intl.DateTimeFormat(i18n.locale, isShowTime ? dateTimeFormatOp : dateFormatOp);
  return d.format(cv);
}

export function dateFullLocalTime(date?: Date | string) {
  if (!date) { return ''; }
  const cv = new Date(date);
  const d = new Intl.DateTimeFormat(i18n.locale, {
    month: '2-digit',
    day: '2-digit',
    year: '2-digit',
    hour12: false,
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  });
  return d.format(cv);
}

export function formatNumber(num: number | null | undefined) {
  if (num == null) { return ''; }

  return Intl.NumberFormat(i18n.locale).format(num);
}

export function toVND(value: number | number | undefined) {
  if (value == null) { return ''; }
  const options = {
    style: 'currency',
    currency: 'VND',
  }
  return Intl.NumberFormat(i18n.locale, options).format(value);
}

export function parseNumber(strValue: string | undefined): number | undefined {
  const n = parseInt(strValue || '', 10);
  return isNaN(n) || n === 0 ? undefined : n;
}

export async function sleep(seconds: number) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(null);
    }, (seconds * 1000));
  });
}

export function formatPhone(phone: string | undefined) {
  if (!phone) {
    return phone;
  }

  if (phone.length === 10) {
    return phone.replace(/(\d{4})(\d{3})(\d{3})/, "$1 $2 $3");
  }

  if (phone.length === 11) {
    return phone.replace(/(\d{4})(\d{3})(\d{4})/, "$1 $2 $3");
  }

  return phone;
}

export const LOAN_DELTA = 20000;

export function normalizeName(alias: string | undefined) {
  if (!alias) {
    return '';
  }
  let str = alias;

  str = str.replace(/[àáạảãâầấậẩẫăằắặẳẵ]/gi, 'a');
  str = str.replace(/[èéẹẻẽêềếệểễ]/gi, 'e');
  str = str.replace(/[ìíịỉĩ]/gi, 'i');
  str = str.replace(/[òóọỏõôồốộổỗơờớợởỡ]/gi, 'o');
  str = str.replace(/[ùúụủũưừứựửữ]/gi, 'u');
  str = str.replace(/[ỳýỵỷỹ]/gi, 'y');
  str = str.replace(/[đ]/gi, 'd');
  str = str.replace(/ +/g, ' ');
  str = str.trim().toLocaleLowerCase();
  return str;
};

export function customSearch(options: DropdownItemProps[], value: string) {
  const searchText = normalizeName(value);
  return options.filter(x => {
    const itemText = normalizeName(x.text as string);
    return itemText.indexOf(searchText) > -1;
  });
}

export function isAllowEdit(createdAt: Date | string): boolean {
  if (appConfig.allowEditTime == null || appConfig.allowEditTime <= 0) {
    return true;
  }
  if (!createdAt) {
    return true;
  }

  const date = new Date(createdAt).getTime();
  const now = new Date().getTime();
  const isNew = (now - date) / 1000 < appConfig.allowEditTime;
  return isNew;
}