import 'src/style/chart-panel.scss';
import * as React from 'react';
import * as Rx from 'rxjs';
import classNames from 'classnames';
import { AppEvent, EventSubscription, showAlert } from 'src/app';
import { IWfChartData, IWfChartInstance, IWfChartSetting } from './common';
import { wfChartManager } from './wf-chart-manager';
import { ChartToolBar } from './chart-tool-bar';
import { ChartSettingDialog, ChartSettingDialogSubmitParams } from './chart-setting-dialog';
import { ChartView } from './chart-view';
import { debounce, isEqual } from 'lodash';

interface IChartPanelProps extends IHomeState {
  chartId: string;
  updateTitle: (chartId: string, title: string) => void;
}

interface IChartPanelState {
  isShowSetting: boolean,
  settings: IWfChartSetting,
  chartData?: IWfChartData,
  isLoading: boolean;
  chartHeight?: number;
  chartWidth?: number;
}

const FilterInfo = (props: { settings: IWfChartSetting }) => {
  const { groupsFilter, queuesFilter, isAllGroupSelected, isAllQueuesSelected } = props.settings;
  const isFilterByGroup = groupsFilter && !isAllGroupSelected && groupsFilter.length !== 0;
  const isFilterByQueue = queuesFilter && !isAllQueuesSelected && queuesFilter.length !== 0;
  return (
    <div className='x-chart-panel__filter'>
      <div className='x-chart-panel__filter-inner'>
        <div className='x-chart-panel__filter-header'>
          Filtered
        </div>
        <div className='x-chart-panel__filter-body'>
          {isFilterByGroup &&
            <ul>
              <li><b>Group: </b></li>
              {groupsFilter!.map(x => <li key={x}>{x || '(None-grouped)'}</li>)}
            </ul>
          }
          {isFilterByQueue &&
            <ul>
              <li><b>Queue: </b></li>
              {queuesFilter!.map(x => <li key={x}>{x}</li>)}
            </ul>
          }
        </div>
      </div>
    </div>
  );
}

export class ChartPanel extends React.Component<IChartPanelProps, IChartPanelState> {
  private readonly events = new EventSubscription();
  private chartInstance: IWfChartInstance;
  private fetchSubscription?: Rx.Subscription;
  private fetchBuildsSubscription?: Rx.Subscription;
  // iframe for listening resize event
  private iframeRef: React.RefObject<HTMLIFrameElement>;

  constructor(props: IChartPanelProps) {
    super(props);
    this.chartInstance = wfChartManager.getChartInstance(props.chartId);
    this.state = {
      isShowSetting: false,
      settings: this.chartInstance.settings,
      isLoading: true
    };
    this.iframeRef = React.createRef();
  }

  public render() {
    const { chartData, settings, isShowSetting, isLoading, chartHeight, chartWidth } = this.state;
    const filterMode = (settings.isAllGroupSelected !== true && settings.groupsFilter && settings.groupsFilter.length !== 0)
      || (settings.isAllQueuesSelected !== true && settings.queuesFilter && settings.queuesFilter.length !== 0);
    const chartTitle = this.getSettingTitle(settings);

    return (
      <div className={classNames('x-chart-panel', { 'x-loading': isLoading })}>
        <div className='x-chart-panel__top'>
          <ChartToolBar
            settings={settings}
            chartWidth={chartWidth}
            onOpenSetting={this.handleOpenSetting}
            onUpdateSetting={this.handleToolBarUpdateSetting}
          />
        </div>

        {isShowSetting &&
          <ChartSettingDialog settings={settings}
            onClose={this.handleSettingDialogClose}
            onSubmit={this.handleSettingDialogSubmit}
          />
        }

        {(filterMode) &&
          <FilterInfo settings={settings} />
        }

        <ChartView
          key={`${filterMode}`}
          settings={settings}
          chartData={chartData}
          chartHeight={chartHeight}
          chartTitle={chartTitle}
          isLoading={isLoading}
          onGridColumnResize={this.handleGridColumnResize} />
        {chartData && chartData.errorMessage && <div className='x-chart-panel__notify'>{chartData.errorMessage}</div>}
        <iframe className='x-chart-panel__resizeIframe' ref={this.iframeRef} />
      </div>
    );
  }

  public componentDidMount() {
    this.refreshData();
    this.events.subscribe(AppEvent.ChartDataChange, this.handleWorkflowChartDataChange);

    if (this.iframeRef && this.iframeRef.current && this.iframeRef.current.contentWindow) {
      this.iframeRef.current.contentWindow.addEventListener('resize', debounce(this.handleIframeResize, 10));
    }
    window.setTimeout(this.handleIframeResize, 100);
    this.updateSetting(this.chartInstance.settings);
  }

  public componentWillUnmount() {
    this.events.unsubcribeAll();
    if (this.fetchSubscription != null) {
      this.fetchSubscription.unsubscribe();
    }
    if (this.fetchBuildsSubscription != null) {
      this.fetchBuildsSubscription.unsubscribe();
    }
  }

  public componentDidUpdate(prevProps: IChartPanelProps, prevState: IChartPanelState) {
    if (prevProps.fromDate !== this.props.fromDate ||
      prevProps.toDate !== this.props.toDate) {
      this.refreshData();
    }
  }

  private handleIframeResize = () => {
    if (this.iframeRef.current && this.iframeRef.current.contentWindow) {
      const iframeWnd = this.iframeRef.current.contentWindow;
      this.setState({ chartHeight: iframeWnd.innerHeight, chartWidth: iframeWnd.innerWidth });
    }
  };

  private handleWorkflowChartDataChange = () => {
    this.refreshData();
  };

  private handleOpenSetting = () => {
    this.setState({ isShowSetting: true });
  }

  private handleSettingDialogClose = () => {
    this.setState({ isShowSetting: false });
  }

  private handleToolBarUpdateSetting = async (setting: IWfChartSetting) => {
    const newSettings: IWfChartSetting = { ...this.state.settings, ...setting };
    await this.updateSetting(newSettings);
  }

  private handleSettingDialogSubmit = async (params: ChartSettingDialogSubmitParams) => {
    const newSettings = { ...this.state.settings, ...params };
    this.setState({ isShowSetting: false, isLoading: true });
    if (params.dataProviderId !== this.state.settings.dataProviderId) {
      delete newSettings.gridColumnWidths;
    }

    await this.updateSetting(newSettings);
  }

  private handleGridColumnResize = (columnWidths: number[]) => {
    const widthsRounded = columnWidths.map(x => Math.round(x));
    this.chartInstance.settings.gridColumnWidths = widthsRounded;
    this.setState({ settings: { ...this.chartInstance.settings } });
    wfChartManager.saveInstances();
  }

  private async refreshData() {
    if (this.fetchSubscription != null) {
      this.fetchSubscription.unsubscribe();
    }
    const dataProvider = wfChartManager.getDataProvider(this.chartInstance.settings.dataProviderId);
    if (dataProvider == null) {
      return;
    }
    const fetchPromise = dataProvider.loadData(this.chartInstance, { ...this.props });
    this.fetchSubscription = Rx.from(fetchPromise).subscribe(
      (newChartData) => {
        const newState = { ...this.state };
        if (newChartData != null) {
          newState.chartData = newChartData
        }
        newState.settings = { ...this.chartInstance.settings };
        newState.isLoading = false;
        if (!isEqual(newState, this.state)) {
          this.setState(newState);
        }
      },
      (error) => {
        this.setState({
          isLoading: false
        });
        showAlert(`${error}`);
      }
    );
  }

  private async updateSetting(newSettings: IWfChartSetting) {
    this.chartInstance.settings = { ...newSettings };
    wfChartManager.saveInstances();
    await this.refreshData();

    this.props.updateTitle(this.props.chartId, this.getSettingTitle(newSettings));
  }

  private getSettingTitle(settings: IWfChartSetting) {
    if (settings && settings.dataProviderId) {
      const dataProvider = wfChartManager.getDataProvider(settings.dataProviderId);
      if (dataProvider) {
        const settingTitle = dataProvider.metadata.name;
        return settingTitle;
      }
    }

    return '';
  }
}